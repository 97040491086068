@import "../mixins/media";
@import "../mixins/projects";
@import "../mixins/clearfix";
/*
ヘッダー

ヘッダー用のプロジェクトコンポーネントを提供します。

ex [商品詳細ページ　ヘッダー](http://demo3.ec-cube.net/products/detail/33)

Markup:
include /assets/tmpl/elements/11.2.header.pug
+ec-headerRole

Styleguide 11.2
*/
.ec-headerRole{
  @include container;
  padding-top: 15px;
  position: relative;
  &:after {
    display: none;
  }
  @include media_desktop {
    @include clearfix;
  }
  &::before{
    display: none;
  }
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: auto;
  @include media_desktop {
    width: 100%;
    @include clearfix;
  }
  & &__title{
    width: 100%;
    @include media_desktop() {
    }
  }
  & &__cart{
    display: none;
    position: absolute;
    top:15px;
    right: 0;
    @include media_desktop(){
      display: block;
      @include reset_link;
    }
  }
  & &__navSP{
    display: block;
    position: absolute;
    top:15px;
    right:10px;
    width: 25%;
    text-align: right;
    @include media_desktop {
      display: none;
    }
  }
}


/*
ヘッダータイトル

ヘッダー内で使用されるタイトルコンポーネントです。

Markup:
include /assets/tmpl/elements/11.2.header.pug
+ec-headerTitle

sg-wrapper:
<div class="ec-headerRole">
  <div class="ec-headerRole__search">
    <sg-wrapper-content/>
  </div>
</div>

Styleguide 11.2.1
*/
.ec-headerTitle{
  & &__title {
    h1 {
      margin: 0;
      padding: 0;
    }
    a{
      display: inline-block;
      margin-bottom: 15px;
      text-decoration: none;
      font-size: 20px;
      @include media_desktop(){
        margin-bottom: 30px;
        font-size: 40px;
      }
      font-weight: bold;
      color: #000;

      &:hover {
        color: #666;
      }
    }
  }
  & &__subtitle {
    font-size: 10px;
    @include media_desktop(){
      font-size: 16px;
      margin-bottom: 10px;
    }
    a {
      display: inline-block;
      color: #0092C4;
      text-decoration: none;
      cursor: pointer;
    }
  }
}

/*
ナビゲーション

ヘッダー内で使用されるナビゲーションコンポーネントです。

Markup:
include /assets/tmpl/elements/11.2.header.pug
+ec-headerNav

sg-wrapper:
<div class="ec-headerRole">
  <div class="ec-headerRole__nav">
    <sg-wrapper-content/>
  </div>
</div>

Styleguide 11.2.3
*/
.ec-headerNavSP {
  margin-right: 10px;
  width: 100%;
  height: auto;
  vertical-align: top;
  text-align: right;
  & &__item{
    display: inline-block;
    margin-right: 10px;
    width: auto;
    text-align: center;
  }
  & &__item:nth-of-type(2){
    margin-left: 5px;
  }
  .ec-cartNaviSP{
    display: inline-block;
    font-size: 30px;
    cursor: pointer;
    position: relative;
    .ec-cartNaviSP__icon{
      display: inline-block;
      width: 30px;
      height: 30px;
      color: $clrDarkGray;
    }
    .ec-cartNaviSP__badge{
      display: inline-block;
      padding: 3px 7px;
      min-width: 10px;
      font-size: 10px;
      font-weight: 700;
      line-height: 1;
      color: #fff;
      text-align: center;
      white-space: nowrap;
      vertical-align: baseline;
      background: #DE5D50;
      border-radius: 10px;
      position: absolute;
      top: 0;
      right: -10px;
    }
  }
  & &__itemMenu {
    display: block;
    position: relative;
    width: 25px;
    height: 15px;
    cursor: pointer;
    span {
      display: inline-block;
      transition: all .4s;
      box-sizing: border-box;
      position: absolute;
      left: 0;
      width: 100%;
      height: 3px;
      background: #525263;
    }
    span:nth-of-type(1) {
      top: 0;
      transform: translateY(0) rotate(0);
      transition: all .3s;
    }
    span:nth-of-type(2) {
      top: 6px;
      opacity: 1;
      transition: all .3s;
    }
    span:nth-of-type(3) {
      bottom: 0;
      transform: translateY(0) rotate(0);
      transition: all .3s;
    }
  }
}

.have_curtain .ec-headerNavSP__itemMenu {
  span:nth-of-type(1) {
    transform: translateY(2px) rotate(-45deg);
    transition: all .3s;
  }
  span:nth-of-type(2) {
    opacity: 0;
    transition: all .3s;
  }
  span:nth-of-type(3) {
    transform: translateY(-10px) rotate(45deg);
    transition: all .3s;
  }
}
