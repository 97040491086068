@import "../mixins/media";
@import "../mixins/variables";
@import "../mixins/projects";

@mixin pager(){
}
/*
検索・一覧表示

一覧表示

sg-wrapper:
<div class="ec-role">
  <sg-wrapper-content/>
</div>

Styleguide 7.2
*/

/*
トピックパス

TODO deprecated
検索結果で表示されるトピックパスのスタイルです。

ex [商品一覧ページ　横並びリスト部分](http://demo3.ec-cube.net/products/list?category_id=&name=)

Markup:
include /assets/tmpl/elements/7.2.search.pug
+ec-topicpath

Styleguide 7.2.1
*/
.ec-topicpath{
  border-top: 1px solid #ccc;
  border-bottom: 1px dotted #ccc;
  padding: 10px;
  list-style: none;
  overflow: hidden;
  font-size: 12px;
  @include media_desktop {
    padding: 1em 0;
    border: 0;
    font-size: 16px;
  }

  & &__item {
    @include reset_link();
  }
  & &__item,
  & &__divider,
  & &__item--active{
    display: inline-block;
    min-width: 29px;
    text-align: center;
    position: relative;
  }
  & &__item--active{
    font-weight: bold;
    @include reset_link();
  }
}

/*
ページャ

検索結果で表示される商品一覧のスタイルです。

ex [商品一覧ページ　ページャ部分](http://demo3.ec-cube.net/products/list?category_id=&name=)

Markup:
include /assets/tmpl/elements/7.2.search.pug
+ec-pager

Styleguide 7.2.2
*/
.ec-pager{
  list-style: none;
  list-style-type: none;
  margin: 0 auto;
  padding: 1em 0;
  text-align: center;
  & &__item,
  & &__item--active{
    display: inline-block;
    min-width: 29px;
    padding: 0 3px 0 2px;
    text-align: center;
    position: relative;
    @include reset_link();
    a{
      color: inherit;
      display: block;
      line-height: 1.8;
      padding: 5px 1em;
      text-decoration: none;
    }
    a:hover{
      color: inherit;
    }
  }
  & &__item--active {
    background: $clrGray;
  }
  & &__item:hover{
    background: $clrGray;
  }

}

/*
検索ボックス

検索結果で表示される商品一覧のスタイルです。

ex [商品一覧ページ　検索ボックス部分](http://demo3.ec-cube.net/products/list?category_id=&name=)

Markup:
include /assets/tmpl/elements/7.2.search.pug
+ec-searchbox

Styleguide 7.2.3
*/
.ec-searchbox{
  & &__keyword {
    height: 50px;
    padding: 0 50px 0 1em;
    border-bottom: 1px solid #ccc;
    position: relative;
    margin-left: 1%;

    input[type="text"]{
      height: 50px;
      width: 100%;
      font-size: 16px;
      border: 0 none;
      padding: 0.5em 0;
      box-shadow: none;
      background: none;
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
    }
    input:focus{
      outline: none;
    }
  }
}