@import "../mixins/variables";
/*
アイコン

デフォルトテンプレートのアイコンは SVGを使用して定義されます。

sg-wrapper:
<div class="ec-role">
  <sg-wrapper-content/>

Markup:
include /assets/tmpl/elements/4.1.icon.pug
div(style="font-size:2em")
  +icon-all


Styleguide 4.1
*/

/*
SVG アイコン

SVGを使用してアイコンを表示させます。

ex [お問い合わせページ　アイコン部分](http://demo3.ec-cube.net/contact)

Markup:
span.ec-icon
  svg(viewbox='0 0 360 360')
    circle.icon-bg(cx='180', cy='179.85', r='155')
    path.icon-with-bg(d='M208.968,226.94c-6.271-1.217-11.042-6.885-11.042-13.573c0-4.763,15.612-19.351,15.504-19.555   c16.115-16.615,24.724-43.762,24.724-64.632c0-32.317-26.04-58.658-58.152-58.658c-32.114,0-58.153,26.341-58.153,58.658   c0,20.969,8.41,48.323,24.614,64.939c0,0,15.612,14.484,15.612,19.247c0,7.089-5.273,12.966-12.169,13.776   c-34.33,3.646-54.8,14.385-70.708,35.156c-4.561,5.976-7.093,18.03-7.193,24.516l-0.014,4.19   c27.915,27.13,66.008,43.845,108.011,43.845c42.044,0,80.171-16.748,108.094-43.927v-4.108c-0.197-6.485-2.729-18.54-7.292-24.516   C264.687,241.325,244.031,230.488,208.968,226.94z')
  span ユーザ
span.ec-icon
  svg(viewbox='0 0 360 360')
    circle.icon-bg(cx='180', cy='179.85', r='155')
    path.icon-with-bg(d='M281.438,160.48c0,16.617-8.61,33.533-25.824,50.747l-70.256,67.662c-1.354,1.354-3.008,2.03-4.962,2.03    c-1.956,0-3.609-0.677-4.962-2.03l-70.369-67.888c-0.752-0.601-1.786-1.579-3.101-2.932c-1.316-1.353-3.402-3.814-6.259-7.387    c-2.858-3.57-5.413-7.234-7.668-10.995c-2.256-3.759-4.269-8.306-6.034-13.645c-1.768-5.338-2.649-10.525-2.649-15.563    c0-16.538,4.772-29.47,14.321-38.793c9.547-9.321,22.74-13.983,39.582-13.983c4.661,0,9.417,0.809,14.266,2.425    c4.85,1.617,9.36,3.797,13.533,6.541c4.172,2.745,7.762,5.32,10.77,7.725c3.005,2.407,5.864,4.961,8.57,7.668    c2.706-2.707,5.563-5.262,8.57-7.668c3.006-2.405,6.597-4.98,10.77-7.725c4.172-2.743,8.684-4.923,13.532-6.541    c4.85-1.616,9.604-2.425,14.266-2.425c16.84,0,30.033,4.663,39.582,13.983C276.663,131.011,281.438,143.943,281.438,160.48z     M267.003,160.48c0-6.089-0.81-11.463-2.425-16.125c-1.618-4.661-3.685-8.363-6.202-11.108c-2.521-2.744-5.582-4.98-9.19-6.71    c-3.608-1.729-7.145-2.893-10.601-3.496c-3.459-0.601-7.144-0.902-11.052-0.902c-3.91,0-8.12,0.958-12.63,2.875    c-4.511,1.917-8.666,4.324-12.462,7.217c-3.797,2.895-7.047,5.602-9.754,8.12c-2.707,2.52-4.962,4.832-6.766,6.935    c-1.354,1.655-3.197,2.481-5.526,2.481c-2.331,0-4.173-0.826-5.525-2.481c-1.805-2.104-4.061-4.416-6.768-6.935    c-2.705-2.518-5.959-5.225-9.754-8.12c-3.798-2.894-7.95-5.3-12.461-7.217c-4.511-1.917-8.722-2.875-12.631-2.875    s-7.594,0.301-11.051,0.902c-3.46,0.603-6.992,1.767-10.601,3.496c-3.608,1.73-6.674,3.966-9.19,6.71    c-2.52,2.745-4.588,6.447-6.203,11.108c-1.617,4.663-2.424,10.036-2.424,16.125c0,12.63,7.029,25.976,21.088,40.034l65.52,63.151    l65.406-63.039C259.935,186.494,267.003,173.111,267.003,160.48z')
  span お気に入り
span.ec-icon
  svg(viewbox='0 0 360 360')
    circle.icon-bg(cx='180', cy='179.85', r='155')
    path.icon-with-bg(d='M253.204,176.911v73.204c0,3.391-1.188,6.271-3.557,8.644c-2.373,2.373-5.256,3.558-8.645,3.558H118.997    c-3.391,0-6.271-1.185-8.643-3.558c-2.373-2.372-3.559-5.253-3.559-8.644v-73.204c0-3.388,1.186-6.27,3.559-8.642    c2.371-2.371,5.252-3.559,8.643-3.559h4.066v-24.401c0-15.589,5.592-28.977,16.775-40.161    c11.186-11.184,24.57-16.776,40.162-16.776c15.588,0,28.977,5.592,40.16,16.776c11.184,11.185,16.775,24.572,16.775,40.161v24.401    h4.066c3.389,0,6.271,1.188,8.645,3.559C252.016,170.641,253.204,173.523,253.204,176.911z M147.463,164.71h65.072v-24.401    c0-8.98-3.178-16.649-9.533-23.003c-6.354-6.355-14.023-9.532-23.002-9.532c-8.982,0-16.65,3.177-23.004,9.532    c-6.355,6.354-9.533,14.023-9.533,23.003V164.71z')
  span ログイン

Styleguide 4.1.1
*/
.ec-icon{
  svg{
    overflow: hidden;
    fill: $clrRightGray;
    margin-right: 5px;
    position: relative;
    top: -1px;
    display: inline-block;
    width: 1em;
    height: 1em;
    vertical-align: middle;
    .icon-with-bg{
      fill: #FFF;
    }
  }
  span{
    font-size: .5em;
  }
}

/*
SVG アイコン(サイズ)

アイコンのサイズは 1em で設定されているため、
フォントサイズを修正したブロック内で利用することで自由にサイズ調整を行うことが可能です。

ex [お問い合わせページ　アイコン部分](http://demo3.ec-cube.net/contact)

Markup:
div(style="font-size:70px;text-align:center")
  .ec-icon
    svg(viewbox='0 0 360 360')
      circle.icon-bg(cx='180', cy='179.85', r='155')
      path.icon-with-bg(d='M208.968,226.94c-6.271-1.217-11.042-6.885-11.042-13.573c0-4.763,15.612-19.351,15.504-19.555   c16.115-16.615,24.724-43.762,24.724-64.632c0-32.317-26.04-58.658-58.152-58.658c-32.114,0-58.153,26.341-58.153,58.658   c0,20.969,8.41,48.323,24.614,64.939c0,0,15.612,14.484,15.612,19.247c0,7.089-5.273,12.966-12.169,13.776   c-34.33,3.646-54.8,14.385-70.708,35.156c-4.561,5.976-7.093,18.03-7.193,24.516l-0.014,4.19   c27.915,27.13,66.008,43.845,108.011,43.845c42.044,0,80.171-16.748,108.094-43.927v-4.108c-0.197-6.485-2.729-18.54-7.292-24.516   C264.687,241.325,244.031,230.488,208.968,226.94z')
div(style="font-size:100px;text-align:center")
  .ec-icon
    svg(viewbox='0 0 360 360')
      path(d='M201.227,34.574l145.551,267.386c4.42,7.975,4.293,15.952-0.379,23.927c-2.151,3.671-5.089,6.582-8.814,8.735   c-3.728,2.152-7.74,3.229-12.033,3.229H34.45c-4.296,0-8.309-1.076-12.035-3.229c-3.728-2.153-6.666-5.064-8.813-8.735   c-4.673-7.975-4.8-15.952-0.379-23.927l145.55-267.386c2.146-3.922,5.117-7.026,8.907-9.305c3.79-2.279,7.895-3.418,12.319-3.418   c4.421,0,8.528,1.14,12.317,3.418C196.11,27.547,199.077,30.651,201.227,34.574z M203.88,211.945l3.41-87.167   c0-1.519-0.633-2.72-1.895-3.608c-1.644-1.392-3.159-2.089-4.548-2.089h-41.695c-1.392,0-2.908,0.697-4.549,2.089   c-1.264,0.888-1.895,2.217-1.895,3.988l3.222,86.787c0,1.266,0.631,2.311,1.895,3.132c1.262,0.826,2.778,1.236,4.548,1.236h35.061   c1.769,0,3.251-0.41,4.454-1.236C203.089,214.255,203.752,213.21,203.88,211.945z M204.258,282.968v-36.082   c0-1.772-0.601-3.259-1.8-4.462c-1.203-1.203-2.623-1.806-4.265-1.806h-36.387c-1.644,0-3.065,0.603-4.265,1.806   c-1.203,1.203-1.801,2.689-1.801,4.462v36.082c0,1.775,0.598,3.261,1.801,4.463c1.199,1.205,2.621,1.804,4.265,1.804h36.387   c1.642,0,3.062-0.599,4.265-1.804C203.658,286.229,204.258,284.744,204.258,282.968z')

Styleguide 4.1.2
*/


/*
SVG アイコン(色)

ec-icon--colored クラスは

アイコンのサイズは 1em で設定されているため、
フォントサイズを修正したブロック内で利用することで自由にサイズ調整を行うことが可能です。

ex [お問い合わせページ　色付きアイコン部分](http://demo3.ec-cube.net/contact)

Markup:
div(style="color:#DE5D50;font-size:70px;text-align:center")
  .ec-icon--colored
    svg(viewbox='0 0 360 360')
      circle.icon-bg(cx='180', cy='179.85', r='155')
      path.icon-with-bg(d='M208.968,226.94c-6.271-1.217-11.042-6.885-11.042-13.573c0-4.763,15.612-19.351,15.504-19.555   c16.115-16.615,24.724-43.762,24.724-64.632c0-32.317-26.04-58.658-58.152-58.658c-32.114,0-58.153,26.341-58.153,58.658   c0,20.969,8.41,48.323,24.614,64.939c0,0,15.612,14.484,15.612,19.247c0,7.089-5.273,12.966-12.169,13.776   c-34.33,3.646-54.8,14.385-70.708,35.156c-4.561,5.976-7.093,18.03-7.193,24.516l-0.014,4.19   c27.915,27.13,66.008,43.845,108.011,43.845c42.044,0,80.171-16.748,108.094-43.927v-4.108c-0.197-6.485-2.729-18.54-7.292-24.516   C264.687,241.325,244.031,230.488,208.968,226.94z')
div(style="color:#9a947e;font-size:70px;text-align:center")
  .ec-icon--colored
    svg(viewbox='0 0 360 360')
      path(d='M201.227,34.574l145.551,267.386c4.42,7.975,4.293,15.952-0.379,23.927c-2.151,3.671-5.089,6.582-8.814,8.735   c-3.728,2.152-7.74,3.229-12.033,3.229H34.45c-4.296,0-8.309-1.076-12.035-3.229c-3.728-2.153-6.666-5.064-8.813-8.735   c-4.673-7.975-4.8-15.952-0.379-23.927l145.55-267.386c2.146-3.922,5.117-7.026,8.907-9.305c3.79-2.279,7.895-3.418,12.319-3.418   c4.421,0,8.528,1.14,12.317,3.418C196.11,27.547,199.077,30.651,201.227,34.574z M203.88,211.945l3.41-87.167   c0-1.519-0.633-2.72-1.895-3.608c-1.644-1.392-3.159-2.089-4.548-2.089h-41.695c-1.392,0-2.908,0.697-4.549,2.089   c-1.264,0.888-1.895,2.217-1.895,3.988l3.222,86.787c0,1.266,0.631,2.311,1.895,3.132c1.262,0.826,2.778,1.236,4.548,1.236h35.061   c1.769,0,3.251-0.41,4.454-1.236C203.089,214.255,203.752,213.21,203.88,211.945z M204.258,282.968v-36.082   c0-1.772-0.601-3.259-1.8-4.462c-1.203-1.203-2.623-1.806-4.265-1.806h-36.387c-1.644,0-3.065,0.603-4.265,1.806   c-1.203,1.203-1.801,2.689-1.801,4.462v36.082c0,1.775,0.598,3.261,1.801,4.463c1.199,1.205,2.621,1.804,4.265,1.804h36.387   c1.642,0,3.062-0.599,4.265-1.804C203.658,286.229,204.258,284.744,204.258,282.968z')

Styleguide 4.1.3
*/
.ec-icon--colored{
  @extend .ec-icon;
  svg{
    fill:currentColor;
  }
}

