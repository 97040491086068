@import "../mixins/media";
@import "../mixins/projects";
/*
ヘッダー

ヘッダー用のプロジェクトコンポーネントを提供します。

ex [商品詳細ページ　ヘッダー](http://demo3.ec-cube.net/products/detail/33)

Markup:
include /assets/tmpl/elements/11.2.header.pug
+ec-headerRole
p hoge

Styleguide 11.7
*/
.ec-layoutRole{
  width: 100%;
  transition: transform 0.3s;
  background: #fff;
  & &__contentTop {
    padding: 0;
  }

  & &__contents{
    margin-right: auto;
    margin-left: auto;
    width: 100%;
    max-width: 1150px;
    display: flex;
    flex-wrap: nowrap;

  }
  & &__main{
    width: 100%;
  }
  & &__mainWithColumn{
    width: 100%;
    @include media_desktop(){
      width: 75%;
    }
  }
  & &__mainBetweenColumn{
    width: 100%;
    @include media_desktop(){
      width: 50%;
    }
  }
  & &__left,
  & &__right{
    display: none;
    @include media_desktop(){
      display: block;
      width: 25%;
    }
  }
}

.ec-layoutRole.is_active{
  transform: translateX(-260px);
  transition: transform 0.3s;
  z-index: 10;
  @include media_desktop(){
    transform: translateX(0px);
  }
}

