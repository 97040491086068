/*
住所一覧

カート 注文詳細 に関する Project コンポーネントを定義します。

ex [マイページ内 お届け先編集](http://demo3.ec-cube.net/mypage/delivery)

Markup:
include /assets/tmpl/elements/17.1.address.pug
+ec-addressRole

sg-wrapper:
<div class="ec-addressRole">
  <sg-wrapper-content/>
</div>

Styleguide 17.1

*/
.ec-addressRole{
  & &__item{
    border-top: 1px dotted #ccc;
  }
  & &__actions{
    margin-top: 32px;
    padding-bottom:20px;
    border-bottom: 1px dotted #ccc;
  }

}

/*
住所一覧リスト

カート 注文詳細 に関する Project コンポーネントを定義します。

ex [マイページ内 お届け先編集](http://demo3.ec-cube.net/mypage/delivery)

Markup:
include /assets/tmpl/elements/17.1.address.pug
+ec-addressList

Styleguide 17.1.1

*/
.ec-addressList{
  & &__item{
    display: table;
    width: 100%;
    position: relative;
    border-bottom: 1px dotted #ccc;
  }
  & &__remove{
    display: table-cell;
    vertical-align: middle;
    padding: 16px;
    text-align: center;
  }
  & &__address{
    display: table-cell;
    vertical-align: middle;
    padding: 16px;
    margin-right:4em;
  }
  & &__action{
    position: absolute;
    vertical-align: middle;
    top: 27px;
    right: 0;
  }

}