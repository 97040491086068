@import "../mixins/variables";
@import "../mixins/media";
@import "../mixins/animation";
@import "../mixins/projects";
/*
カート

ショッピングカートに関するスタイルです。

sg-wrapper:
<div class="ec-role">
  <sg-wrapper-content/>


Styleguide 7.3
*/

/*
カートヘッダ

購入完了までの手順や、現在の状態を表示します。

ul 要素を用いたリスト要素としてマークアップします。

ex [カートページ　ヘッダ部分](http://demo3.ec-cube.net/cart)

Markup:
include /assets/tmpl/elements/7.3.cart.pug
+ec-progress

sg-wrapper:
<div class="ec-role">
  <sg-wrapper-content/>
</div>

Styleguide 7.3.1
*/
.ec-progress{
  margin: 0 auto;
  padding: 8px 0 16px;
  display: table;
  table-layout: fixed;
  width: 100%;
  max-width: 600px;
  list-style: none;
  @include media_desktop {
    margin-bottom: 30px;
    padding: 0;
  }

  & &__item{
    display:table-cell;
    position: relative;
    font-size: 14px;
    text-align: center;
    font-weight: bold;
    z-index: 10;

    &:after {
      content: '';
      position: absolute;
      display: block;
      background: #525263;
      width: 100%;
      height: 0.25em;
      top: 1.25em;
      left: 50%;
      margin-left: 1.5em\9;
      z-index: -1;
    }
    &:last-child:after {
      display: none;
    }
  }
  & &__number{
    line-height: 30px;
    width: 30px;
    height: 30px;
    margin-bottom: 5px;
    font-size: 12px;
    background: #525263;
    color: #fff;
    top: 0;
    left: 18px;
    display: inline-block;
    text-align: center;
    vertical-align: middle;
    border-radius: 50%;
    @include media_desktop(){
      line-height: 42px;
      width: 42px;
      height: 42px;
      font-size: 20px;
    }
  }
  & &__label {
    font-size: 12px;
  }
  .is-complete {
    .ec-progress__number {
      background: #5CB1B1;
    }
    .ec-progress__label {
      color: #5CB1B1;
    }
  }
}



/*
カートナビゲーション

ex [カートページ　ナビゲーション部分](http://demo3.ec-cube.net/cart)

Markup:
include /assets/tmpl/elements/11.2.header.pug
+ec-headerCart

sg-wrapper:
<div class="ec-role">
  <sg-wrapper-content/>
</div>


Styleguide 7.3.5
*/
.ec-cartNavi{
  border: 1px solid #D9D9D9;
  width: 256px;
  position: relative;
  display: block;
  height: 44px;
  overflow: hidden;
  white-space: nowrap;
  z-index: 3;
  cursor: pointer;
  & &__icon{
    width: 30px;
    height: 30px;
    position: relative;
    top: 8px;
    left: 8px;
    fill: #525263;
    @include fadeIn(inline-block,200ms);
  }
  & &__iconClose {
    width: 25px;
    height: 25px;
    position: absolute;
    top: 8px;
    left: 8px;
    fill: #525263;
    @include fadeOut(200ms);
  }
  & &__badge{
    position: relative;
    left: 13px;
    top: -6px;
    display: inline-block;
    min-width: 10px;
    padding: 3px 7px;
    font-size: 10px;
    font-weight: 700;
    line-height: 1;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    background-color: #DE5D50;
    border-radius: 10px;
    @include fadeIn(inline-block);
  }
  & &__label{
    font-size: 12px;
    position: absolute;
    right:0;
    top:0;
    height: 44px;
    line-height: 44px;
    vertical-align: middle;
    margin-right: 16px;
  }
  & &__price{
    display: inline-block;
    font-size: 16px;
  }
}
.is_active {
  .ec-cartNavi__icon{
    @include fadeOut;
  }
  .ec-cartNavi__badge{
    display: none;
  }
  .ec-cartNavi__iconClose {

    @include fadeIn(inline-block,200ms);
  }
}

.ec-cartNaviNull {
  display: none;
  min-width: 256px;
  text-align: center;
  background: #f3f3f3;
  box-sizing: border-box;
  padding: 16px;
  z-index: 3;
  position: absolute;
  @include fadeOut;
  & &__message {
    border: 1px solid #D9D9D9;
    padding: 16px 0;
    font-size: 16px;
    font-weight: bold;
    color: #fff;
    background-color: #F99;
    p {
      margin: 0;
    }
  }
}

.is_active {
  .ec-cartNaviNull {
    @include fadeIn;
    display: block;
  }
  .ec-cartNaviIsset {
    @include fadeIn;
    display: block;
  }
}

/*
カートナビゲーションのポップアップ(商品詳細)

ex [カートページ　ナビゲーション部分](http://demo3.ec-cube.net/cart)

Markup:
div(style="height:350px;")
  // 上記のdivはスタイルガイド都合上、高さをもたせるため設置(mocでは不要)
  .is_active
    .ec-cartNavi
      .ec-cartNavi__icon
        svg(viewbox="0 0 360 360")
          path(d="M345,78.032v101.817c0,3.183-1.06,6-3.173,8.452c-2.116,2.455-4.828,3.877-8.132,4.275l-207.043,24.261   c0.13,0.93,0.427,2.355,0.893,4.275c0.461,1.925,0.858,3.68,1.189,5.271c0.329,1.591,0.496,3.051,0.496,4.375   c0,2.121-1.586,6.362-4.759,12.727h182.452c3.437,0,6.411,1.262,8.924,3.778c2.51,2.52,3.768,5.503,3.768,8.948   c0,3.45-1.258,6.433-3.768,8.949c-2.513,2.52-5.487,3.778-8.924,3.778H103.847c-3.44,0-6.416-1.259-8.925-3.778   c-2.513-2.517-3.768-5.499-3.768-8.949c0-1.854,0.724-4.475,2.181-7.854c1.453-3.381,3.402-7.325,5.85-11.833   c2.445-4.506,3.798-7.026,4.065-7.557L68.149,65.305H27.693c-3.44,0-6.415-1.26-8.924-3.779C16.255,59.008,15,56.026,15,52.577   c0-3.445,1.254-6.428,3.768-8.949c2.51-2.516,5.484-3.777,8.924-3.777h50.77c2.113,0,3.997,0.432,5.651,1.291   c1.651,0.864,2.975,1.891,3.966,3.082c0.993,1.195,1.851,2.82,2.58,4.873c0.724,2.058,1.22,3.813,1.486,5.27   c0.264,1.463,0.626,3.416,1.091,5.867c0.461,2.455,0.759,4.146,0.893,5.072h238.179c3.437,0,6.41,1.262,8.924,3.777   C343.742,71.602,345,74.586,345,78.032z M134.487,276.397c4.957,4.972,7.437,10.972,7.437,17.998   c0,7.025-2.479,13.025-7.437,17.997c-4.958,4.972-10.942,7.458-17.949,7.458c-7.008,0-12.989-2.486-17.947-7.458   c-4.959-4.972-7.437-10.972-7.437-17.997c0-7.026,2.478-13.026,7.437-17.998c4.958-4.971,10.938-7.457,17.947-7.457   C123.544,268.94,129.529,271.426,134.487,276.397z M312.178,276.397c4.958,4.972,7.437,10.972,7.437,17.998   c0,7.025-2.479,13.025-7.437,17.997c-4.957,4.972-10.941,7.458-17.947,7.458c-7.009,0-12.99-2.486-17.947-7.458   c-4.958-4.972-7.437-10.972-7.437-17.997c0-7.026,2.479-13.026,7.437-17.998c4.957-4.971,10.938-7.457,17.947-7.457   C301.237,268.94,307.221,271.426,312.178,276.397z")
      .ec-cartNavi__iconClose
        svg(viewbox="0 0 360 360")
          path(d="M337.572,293.77L223.65,179.85L337.572,65.928c3.547-3.546,3.163-9.678-0.855-13.7l-29.095-29.093  c-4.022-4.021-10.153-4.402-13.7-0.856L180,136.199L66.08,22.279c-3.547-3.547-9.681-3.165-13.702,0.856L23.286,52.228  c-4.02,4.022-4.404,10.154-0.857,13.7L136.35,179.85L22.429,293.77c-3.547,3.545-3.163,9.678,0.857,13.701l29.093,29.092  c4.021,4.023,10.155,4.406,13.702,0.857L180,223.499L293.92,337.42c3.548,3.549,9.679,3.166,13.701-0.857l29.095-29.092  C340.735,303.448,341.121,297.315,337.572,293.77z")
      .ec-cartNavi__badge 1
      .ec-cartNavi__label
        | 合計
        .ec-cartNavi__price ¥1920
    +b.ec-cartNaviIsset
      +e.cart
        +e.cartImage
          img(src='http://demo3.ec-cube.net/upload/save_image/0701104933_5593472d8d179.jpeg')
        +e.cartContent
          +e.cartContentTitle ミニテーブル
          +e.cartContentPrice ¥ 12,960
            +e.cartContentTax 税込
          +e.cartContentNumber 数量：1
      +e.action
        a.ec-blockBtn--action(href="/moc/guest/cart1") カートへ進む
        a.ec-blockBtn.ec-cartNavi--cancel キャンセル

Styleguide 7.3.6
*/
.ec-cartNaviIsset {
  display: none;
  min-width: 256px;
  max-width:256px;
  text-align: center;
  background: #f3f3f3;
  box-sizing: border-box;
  padding: 16px;
  z-index: 3;
  position: absolute;
  @include fadeOut;
  & &__cart {
    @include clearfix;
    border-bottom: 1px solid #E8E8E8;
    margin-bottom: 16px;
    padding-bottom: 32px;
  }
  & &__cartImage {
    float: left;
    width: 50%;
    img {
      width: 100%;
    }
  }
  & &__cartContent {
    float: right;
    width: 50%;
    padding-left: 16px;
    text-align:left;
    box-sizing:border-box;
  }
  & &__action {
    .ec-blockBtn--action {
      color:#fff;
      margin-bottom: 8px;
    }
  }
  & &__cartContentTitle {
    margin-bottom: 8px;
  }
  & &__cartContentPrice {
    font-weight: bold;
  }
  & &__cartContentTax {
    display: inline-block;
    font-size: 12px;
    font-weight: normal;
    margin-left: 5px;
  }
  & &__cartContentNumber {
    font-size: 14px;
  }
}



/*
カートナビゲーションのポップアップ(商品なし)

ex [カートページ　ナビゲーション部分](http://demo3.ec-cube.net/cart)

Markup:
div(style="height:170px;")
  // 上記のdivはスタイルガイド都合上、高さをもたせるため設置(mocでは不要)
  .is_active
    .ec-cartNavi
      .ec-cartNavi__icon
        svg(viewbox="0 0 360 360")
          path(d="M345,78.032v101.817c0,3.183-1.06,6-3.173,8.452c-2.116,2.455-4.828,3.877-8.132,4.275l-207.043,24.261   c0.13,0.93,0.427,2.355,0.893,4.275c0.461,1.925,0.858,3.68,1.189,5.271c0.329,1.591,0.496,3.051,0.496,4.375   c0,2.121-1.586,6.362-4.759,12.727h182.452c3.437,0,6.411,1.262,8.924,3.778c2.51,2.52,3.768,5.503,3.768,8.948   c0,3.45-1.258,6.433-3.768,8.949c-2.513,2.52-5.487,3.778-8.924,3.778H103.847c-3.44,0-6.416-1.259-8.925-3.778   c-2.513-2.517-3.768-5.499-3.768-8.949c0-1.854,0.724-4.475,2.181-7.854c1.453-3.381,3.402-7.325,5.85-11.833   c2.445-4.506,3.798-7.026,4.065-7.557L68.149,65.305H27.693c-3.44,0-6.415-1.26-8.924-3.779C16.255,59.008,15,56.026,15,52.577   c0-3.445,1.254-6.428,3.768-8.949c2.51-2.516,5.484-3.777,8.924-3.777h50.77c2.113,0,3.997,0.432,5.651,1.291   c1.651,0.864,2.975,1.891,3.966,3.082c0.993,1.195,1.851,2.82,2.58,4.873c0.724,2.058,1.22,3.813,1.486,5.27   c0.264,1.463,0.626,3.416,1.091,5.867c0.461,2.455,0.759,4.146,0.893,5.072h238.179c3.437,0,6.41,1.262,8.924,3.777   C343.742,71.602,345,74.586,345,78.032z M134.487,276.397c4.957,4.972,7.437,10.972,7.437,17.998   c0,7.025-2.479,13.025-7.437,17.997c-4.958,4.972-10.942,7.458-17.949,7.458c-7.008,0-12.989-2.486-17.947-7.458   c-4.959-4.972-7.437-10.972-7.437-17.997c0-7.026,2.478-13.026,7.437-17.998c4.958-4.971,10.938-7.457,17.947-7.457   C123.544,268.94,129.529,271.426,134.487,276.397z M312.178,276.397c4.958,4.972,7.437,10.972,7.437,17.998   c0,7.025-2.479,13.025-7.437,17.997c-4.957,4.972-10.941,7.458-17.947,7.458c-7.009,0-12.99-2.486-17.947-7.458   c-4.958-4.972-7.437-10.972-7.437-17.997c0-7.026,2.479-13.026,7.437-17.998c4.957-4.971,10.938-7.457,17.947-7.457   C301.237,268.94,307.221,271.426,312.178,276.397z")
      .ec-cartNavi__iconClose
        svg(viewbox="0 0 360 360")
          path(d="M337.572,293.77L223.65,179.85L337.572,65.928c3.547-3.546,3.163-9.678-0.855-13.7l-29.095-29.093  c-4.022-4.021-10.153-4.402-13.7-0.856L180,136.199L66.08,22.279c-3.547-3.547-9.681-3.165-13.702,0.856L23.286,52.228  c-4.02,4.022-4.404,10.154-0.857,13.7L136.35,179.85L22.429,293.77c-3.547,3.545-3.163,9.678,0.857,13.701l29.093,29.092  c4.021,4.023,10.155,4.406,13.702,0.857L180,223.499L293.92,337.42c3.548,3.549,9.679,3.166,13.701-0.857l29.095-29.092  C340.735,303.448,341.121,297.315,337.572,293.77z")
      .ec-cartNavi__badge 1
      .ec-cartNavi__label
        | 合計
        .ec-cartNavi__price ¥1920
    .ec-cartNaviNull
      .ec-cartNaviNull__message
        p 現在カート内に
          br
          | 商品がございません。
    //+b.ec-cartNaviIsset
    //  +e.cart
    //    +e.cartImage
    //      img(src='http://demo3.ec-cube.net/upload/save_image/0701104933_5593472d8d179.jpeg')
    //    +e.cartContent
    //      +e.cartContentTitle ミニテーブル
    //      +e.cartContentPrice ¥ 12,960
    //        +e.cartContentTax 税込
    //      +e.cartContentNumber 数量：1
    //  +e.action
    //    a.ec-blockBtn--action(href="/moc/guest/cart1") カートへ進む
    //    a.ec-blockBtn キャンセル

sg-wrapper:
<div class="ec-role">
  <sg-wrapper-content/>
</div>


Styleguide 7.3.7
*/

/*
総計

ex [カートページ　統計部分](http://demo3.ec-cube.net/cart)

Markup:
include /assets/tmpl/elements/7.3.cart.pug
+ec-totalBox

Styleguide 7.3.8
*/
.ec-totalBox{
  background:#F3F3F3;
  padding: 16px;
  & &__spec{
    position: relative;
    margin-bottom:8px;
    dt{
      font-weight: normal;
      position: relative;
      top:0;
      left: 0;
      text-align: left;
    }
    dd{
      position: absolute;
      top:0;
      right: 0;
      text-align: right;

    }
    & &__specTotal {
      color: $clrRed;
    }
  }
  & &__total{
    padding: 8px 0;
    border-top: 1px dotted #ccc;
    text-align: right;
    font-size: 14px;
    font-weight:bold;
  }
  & &__price{
    margin-left: 16px;
    font-size: 16px;
    font-weight:bold;
    color: $clrRed;
    @include media_desktop {
      font-size: 24px;
    }
  }
  & &__taxLabel {
    margin-left: 8px;
    color: $clrRed;
    font-size: 12px;
    @include media_desktop {
      font-size: 14px;
    }
  }
  & &__btn {
    @include reset_link();
    color: #fff;
    .ec-blockBtn--action {
      font-size: 16px;
      font-weight: bold;
    }
  }
}