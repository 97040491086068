@import "../mixins/media";
@import "../mixins/clearfix";
/*
お知らせ

新着情報やバナーなどの掲載項目を紹介していきます。

sg-wrapper:
<div class="ec-role">
  <sg-wrapper-content/>
</div>

Styleguide 8.1
*/

/*
新着情報

新着情報の掲載をします。

ex [トップページ　新着情報部分](http://demo3.ec-cube.net/)

Markup:
include /assets/tmpl/elements/8.1.info.pug
+ec-news

Styleguide 8.1.1
*/
.ec-news {
  margin-bottom: 16px;
  background: #F8F8F8;
  @include media_desktop {
    margin-right: 3%;
  }
  @include media_desktop {
    margin-bottom: 32px;
  }
  & &__title{
    font-weight: bold;
    padding: 8px;
    font-size: 16px;
    text-align: center;
    @include media_desktop {
      padding: 16px;
      text-align: left;
      font-size: 24px;
    }
  }
  & &__items{
    padding: 0;
    list-style: none;
    border-top: 1px dotted #ccc;
  }
}
/*
折りたたみ項目

折りたたみ項目を掲載します。

ex [トップページ　折りたたみ項目部分](http://demo3.ec-cube.net/)

Markup:
include /assets/tmpl/elements/8.1.info.pug
+ec-newsline

Styleguide 8.1.2
*/
.ec-newsline {
  display: flex;
  flex-wrap:wrap;
  overflow: hidden;
  padding: 0 16px;
  & &__info{
    width: 100%;
    padding: 16px 0;
    @include clearfix;
  }
  & &__date{
    display: inline-block;
    margin-right: 10px;
    float: left;
  }
  & &__comment{
    display: inline-block;
    float: left;
  }
  & &__close{
    float: right;
    display: inline-block;
    text-align: right;
    .ec-closeBtn--circle {
      display: inline-block;
      width: 25px;
      height: 25px;
      min-width: 25px;
      min-height: 25px;
    }
    .ec-closeBtn--circle__icon {
      top: 20%;
    }
  }
  & &__description{
    width: 100%;
    height: 0;
    transition: all .2s ease-out;
  }

  &.is_active &__description{
    height: auto;
    transition: all .2s ease-out;
    padding-bottom: 16px;
  }
}


/*
バナー

バナー形式でお知ら情報を伝える際の背景つきボックスです。

ex [トップページ　バナー部分](http://demo3.ec-cube.net/)

Markup:
include /assets/tmpl/elements/8.1.info.pug
+ec-banner

Styleguide 8.1.3
*/
.ec-banner{
  padding: 16px;
  margin-bottom: 16px;
  background: #F8F8F8;
  text-align: center;
  font-weight: bold;
  border: 1px solid #ccc;
  @include media_desktop {
    margin-left: 3%;
    margin-bottom: 16px;
  }
  & &__intro {
    color: #DE5D50;
    font-size: 20px;
    font-weight: bold;
  }
  & &__title {
    color: #DE5D50;
    font-size: 32px;
    font-weight: bold;
  }
}
