@import "../mixins/media";

/*
リスト

シンプルなリストを構成するためのスタイル群です。F

sg-wrapper:
<div class="ec-role">
  <sg-wrapper-content/>
</div>

Styleguide 1.3
*/

/*
水平定義リスト

シンプルな定義リストのスタイルを定義します。

dl要素を用いてコーディングします。

ex [当サイトについて　水平定義リスト部分](http://demo3.ec-cube.net/help/about)

Markup:
dl.ec-definitions
    dt 店名
    dd EC-CUBE3 DEMO SHOP
dl.ec-definitions
    dt 会社名
    dd EC-CUBE3
dl.ec-definitions--soft
    dt 所在地
    dd 〒 550-0001

Styleguide 1.3.1
*/
.ec-definitions {
  margin: 5px 0;
  display: block;
  & dt, dd {
    display: inline-block;
    margin: 0;
  }
  & dt {
    font-weight: bold;
  }
}

.ec-definitions--soft {
  @extend .ec-definitions;
  & dt {
    font-weight: normal;
  }
}

/*
下線つき定義リスト

線が添えられた定義リストのスタイルを定義します。

dl要素を用いてコーディングします。

ex [当サイトについて　下線つき定義リスト](http://demo3.ec-cube.net/help/about)

Markup:
.ec-borderedDefs
  dl
    dt 店名
    dd EC-CUBE3 DEMO SHOP
  dl
    dt 会社名
    dd EC-CUBE3
  dl
    dt 所在地
    dd 〒550 - 0001

Styleguide 1.3.2
*/

.ec-borderedDefs {
  width: 100%;
  border-top: 1px dotted #ccc;
  margin-bottom:16px;
  dl {
    display: flex;
    border-bottom: 1px dotted #ccc;
    margin: 0;
    padding: 8px 0;
    flex-wrap: wrap;
    @include media_desktop {
      flex-wrap: nowrap;
    }
  }
  dt, dd {
    padding: 0;
    @include media_desktop {
      padding: 0 16px;
    }
  }

  dt {
    font-weight: normal;
    width: 100%;
    @include media_desktop {
      width: 30%;
    }
  }

  dd {
    padding: 0;
    width: 100%;
    line-height: 2.5;
    @include media_desktop {
      width: 70%;
      //padding: 18px 16px;
      line-height: 3;
    }
  }
  p {
    line-height: 1.4;
  }
}

.ec-list-chilled {
  display: table-row;
  border: 0 none;
  padding: 8px 0;

  dt, dd {
    display: table-cell;
    border-bottom: 1px dotted #ccc;
    padding: 0;
    @include media_desktop {
      padding: 16px 0;
    }
  }

  dt {
    width: 30%;
  }

  dd {
    padding: 0;
    @include media_desktop {
      padding: 16px;
    }
  }
}

/*
ボーダーリスト


ex [当サイトについて　ボーダーリスト](http://demo3.ec-cube.net/help/about)

Markup:
ul.ec-borderedList
  li: p lorem
  li: p lorem
  li: p lorem


Styleguide 1.3.3
*/

.ec-borderedList {
  width: 100%;
  border-top: 0;
  list-style: none;
  padding: 0;
  @include media_desktop {
    border-top: 1px dotted #ccc;
  }
  li {
    border-bottom: 1px dotted #ccc;
  }
}

.ec-list-chilled {
  display: table-row;
  border: 0 none;
  padding: 8px 0;

  dt, dd {
    display: table-cell;
    border-bottom: 1px dotted #ccc;
    padding: 16px 0;
  }

  dt {
    width: 30%;
  }

  dd {
    padding: 16px;
  }
}

/*
水平リスト

TODO content 廃止

ex [商品一覧カテゴリ絞り込み　水平リスト部分](http://demo3.ec-cube.net/products/list?category_id=7)

Markup:
ol.ec-list-flat
    li
        a(href="#").ec-link 全商品
    li
        a(href="#").ec-link キッチンツール
    li
        a(href="#").ec-link キッチン小物

Styleguide 1.3.4
*/

.ec-list-flat {
  list-style: none;
  overflow: hidden;
  padding: 0 0.7em;
  li {
    float: left;
    &:after {
      content: "｜";
    }
    &:last-child {
      &:after {
        content: '';
      }
    }
  }
  a {
    float: left;
    display: inline-block;
  }
}
