@import "../mixins/media";
@import "../mixins/projects";

/*
カート

カート 注文詳細 に関する Project コンポーネントを定義します。

ex [カートページ](http://demo3.ec-cube.net/shopping)

(カート内に商品がある状態でアクセス)

Markup:
include /assets/tmpl/elements/15.1.cart.pug
+ec-cartRole

Styleguide 15.1

*/
.ec-cartRole{
  @include container;

  &::before{
    display: none;
  }
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;

  & &__progress{
    width: 100%;
    text-align: center;
  }
  & &__totalText{
    margin-bottom: 0;
    padding: 16px 0 6px;
    width: 100%;
    text-align: center;
    font-weight: normal;
    @include media_desktop {
      margin-bottom: 30px;
      padding: 0;
    }
  }
  & &__cart{
    margin: 0;
    width: 100%;
    @include media_desktop {
      margin: 0 10%;
    }

  }
  & &__actions{
    text-align: right;
    width: 100%;
    @include media_desktop {
      width:  20%;
      margin-right: 10%;
    }
  }
  & &__total{
    padding: 15px 0 30px ;
    font-weight: bold;
    font-size: 16px;
  }
  & &__totalAmount{
    margin-left: 30px;
    color: #de5d50;
    font-size: 16px;
    @include media_desktop {
      font-size: 24px;
    }
  }

  .ec-blockBtn--action {
    margin-bottom: 10px;
  }
}


/*
カート

カート内のアイテムを表示するテーブル枠です。

ex [カートページ　テーブル部分](http://demo3.ec-cube.net/cart)

Markup:
.ec-cartTable

sg-wrapper:
<div class="ec-cartRole">
  <sg-wrapper-content/>
</div>

Styleguide 15.1.2
*/
.ec-cartTable{
  display: table;
  border-top: 1px dotted #ccc;
  width: 100%;
  @include media_desktop {
    border-top: none;
  }
}


/*
カート商品表示枠

カート内のアイテムを表示するテーブルヘッダです。
スマホでは非表示となります。

ex [カートページ　カートテーブルヘッダ部分](http://demo3.ec-cube.net/cart)

Markup:
include /assets/tmpl/elements/15.1.cart.pug
.ec-cartTable
  +ec-cartHeader

sg-wrapper:
<div class="ec-cartRole">
  <sg-wrapper-content/>
</div>


Styleguide 15.1.3
*/
.ec-cartHeader{
  display: none;
  width: 100%;
  background: #F4F3F0;
  @include media_desktop {
    display: table-row;
  }
  & &__label{
    display: table-cell;
    padding: 16px;
    text-align: center;
    background: #F4F3F0;
    overflow-x: hidden;
    font-weight: bold;
  }
}
.ec-cartCompleteRole {
  @include container;
}
/*
カート内商品

カート内のアイテムを表示するテーブル行です。
スマホでは非表示となります。

ex [カートページ　テーブル部分](http://demo3.ec-cube.net/cart)

Markup:
include /assets/tmpl/elements/15.1.cart.pug
.ec-cartTable
  +ec-cartRow

sg-wrapper:
<div class="ec-cartRole">
  <sg-wrapper-content/>
</div>


Styleguide 15.1.4
*/

.ec-cartRow{
  display: table-row;
  & &__delColumn{
    border-bottom: 1px dotted #ccc;
    text-align: center;
    display: table-cell;
    width: 14%;
    vertical-align: middle;
    @include media_desktop{
      width: 8.3333333%;
    }
    .ec-icon {
      svg {
        width: 1.5em;
        height: 1.5em;
        @include media_desktop {
          width: 1em;
          height: 1em;
        }
      }
    }
  }
  & &__contentColumn{
    border-bottom: 1px dotted #ccc;
    padding: 10px 0;
    display: table-cell;
  }
  & &__img{
    display: inline-block;
    width: 40%;
    vertical-align: top;
    @include media_desktop {
      min-width: 80px;
      max-width: 100px;
    }
  }
  & &__summary{
    display: inline-block;
    margin-left: 5px;
    font-weight: bold;
    vertical-align: top;
    width: 46%;
    @include media_desktop {
      margin-left: 20px;
      vertical-align: middle;
    }
    .ec-cartRow__name {
      margin-bottom: 5px;
    }
    .ec-cartRow__sutbtotalSP {
      display: block;
      font-weight: normal;
      @include media_desktop {
        display: none;
      }
    }
  }
  & &__amountColumn{
    display: table-cell;
    border-bottom: 1px dotted #ccc;
    vertical-align: middle;
    text-align: center;
    width: 20%;
    @include media_desktop {
      width: 16.66666667%;
    }

    .ec-cartRow__amount {
      display: none;
      margin-bottom: 10px;
      @include media_desktop {
        display: block;
      }
    }
    .ec-cartRow__amountSP {
      display: block;
      margin-bottom: 10px;
      @include media_desktop {
        display: none;
      }
    }

    .ec-cartRow__amountUpButton {
      margin: 0 2px;
      display: inline-block;
      border: 2px solid #c9c9c9;
      border-radius: 50%;
      width: 30px;
      min-width: 30px;
      max-width: 30px;
      height: 30px;
      cursor: pointer;
      line-height: 40px;
      vertical-align: middle;
      position: relative;
      text-align: center;
      background: #fff;

      .ec-cartRow__amountDownButton__icon {
        display: block;
        margin-top: -0.7em;
        margin-left: -0.4em;
        width: .8em;
        height: .8em;
        font-size: 20px;
        position: absolute;
        top: 28%;
        left: 50%;
        svg{
          overflow: hidden;
          fill: #525263;
          display: inline-block;
          width: 100%;
          height: 100%;
        }
      }
    }
    .ec-cartRow__amountDownButton {
      margin: 0 2px;
      display: inline-block;
      border: 2px solid #c9c9c9;
      border-radius: 50%;
      width: 30px;
      min-width: 30px;
      max-width: 30px;
      height: 30px;
      cursor: pointer;
      line-height: 40px;
      vertical-align: middle;
      position: relative;
      text-align: center;
      background: #fff;

      .ec-cartRow__amountDownButton__icon {
        position: absolute;
        top: 28%;
        left: 50%;
        margin-top: -0.7em;
        margin-left: -0.4em;
        display: block;
        width: .8em;
        height: .8em;
        font-size: 20px;
        svg{
          overflow: hidden;
          fill: #525263;
          display: inline-block;
          width: 100%;
          height: 100%;
        }
      }
    }
  }
  & &__subtotalColumn{
    display: none;
    border-bottom: 1px dotted #ccc;
    text-align: right;
    width: 16.66666667%;
    @include media_desktop {
      display: table-cell;
    }
  }

}

/*
アラート

カート内の商品に問題があることを示す警告メッセージです。

ex [マイページ　カート](http://demo3.ec-cube.net/cart)

Markup:
include /assets/tmpl/elements/15.1.cart.pug
.ec-cartRole
  .ec-cartRole__cart
    +ec-alert-warning

Styleguide 15.1.5
*/

.ec-alert-warning {
  width: 100%;
  padding: 10px;
  text-align: center;
  background: #F99;
  margin-bottom: 20px;


  & &__icon {
    display: inline-block;
    width: 20px;
    height: 20px;
    color: #fff;
    fill: #fff;
    position: absolute;
    left: -30px;
  }
  & &__text {
    display: inline-block;
    font-size: 16px;
    font-weight: bold;
    color: #fff;
    position: relative;
  }
}




/*
アラート(空)

カートが空であることを示す警告メッセージです。

ex [マイページ　カート](http://demo3.ec-cube.net/cart)

Markup:
include /assets/tmpl/elements/15.1.cart.pug
.ec-off3Grid
        .ec-off3Grid__cell
            +ec-alert-warningEnpty

Styleguide 15.1.6
*/