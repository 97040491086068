@charset "UTF-8";
@import url(/node_modules/normalize.css/normalize.css);
body {
  font-family: Roboto, "游ゴシック", YuGothic, "Yu Gothic", "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", Arial, "メイリオ", Meiryo, sans-serif;
  color: #525263;
  transition: z-index 0ms 5.28455ms;
  background: #f6f6f6;
  margin: 0; }

a {
  text-decoration: none; }

/**
メディアクエリ
SP フォーストで記述する。
Twitter Bootstrap デフォルト準拠
 */
/*
見出し

ページ内で見出しとして機能する要素のスタイル群です。

sg-wrapper:
<div class="ec-role">
  <sg-wrapper-content/>
</div>

Styleguide 1.1
*/
/*
見出し

商品紹介等で利用される、一般的な見出しのスタイルです。

ex [商品詳細ページ　商品見出し部分](http://demo3.ec-cube.net/products/detail/27)

Markup:
.ec-headingTitle マトリョーシカ

Styleguide 1.1.1
*/
.ec-headingTitle {
  margin: 0 0 8px;
  font-size: 32px;
  font-weight: normal;
  color: #525263; }

/*
ページヘッダ

各種ページで用いられるページヘッダのデザインです。

ex [利用規約ページ　ページヘッダ部](http://demo3.ec-cube.net/help/agreement)

Markup:
.ec-pageHeader
  h1 利用規約

Styleguide 1.1.2
*/
.ec-pageHeader h1 {
  margin: 0 0 8px;
  border-bottom: 1px dotted #ccc;
  border-top: 1px solid #ccc;
  padding: 8px 0 12px;
  font-size: 16px;
  font-weight: bold; }
  @media only screen and (min-width: 768px) {
    .ec-pageHeader h1 {
      border-top: none;
      border-bottom: 1px solid #ccc;
      margin: 10px 16px 48px;
      padding: 8px;
      font-size: 32px;
      font-weight: bold; } }

/*
サブ見出し

利用規約など、文字主体のページで用いられるサブ見出しです。

ex [利用規約ページ サブ見出し部分](http://demo3.ec-cube.net/help/agreement)

Markup:
.ec-heading 第1条 (会員)

Styleguide 1.1.3
*/
.ec-heading {
  margin: 24px 0; }

/*
サブ見出し(太字)

文字主体のページで用いられるサブ見出しの太字のスタイルです。

ex [プライバシーポリシー サブ見出し部分](http://demo3.ec-cube.net/help/privacy)

Markup:
.ec-heading-bold 個人情報の定義

Styleguide 1.1.4
*/
.ec-heading-bold {
  margin: 16px 0;
  font-size: 16px;
  font-weight: bold; }
  @media only screen and (min-width: 768px) {
    .ec-heading-bold {
      font-size: 18px; } }

/*
背景付き見出し

マイページ注文履歴等で用いられる背景付きの見出しです。

ex [ご注文履歴詳細　背景付き見出し部分](http://demo3.ec-cube.net/mypage/history/1063)

Markup:
.ec-rectHeading
  h2 配送情報
.ec-rectHeading
  h2 お支払について

Styleguide 1.1.5
*/
.ec-rectHeading h1, .ec-rectHeading h2, .ec-rectHeading h3,
.ec-rectHeading h4, .ec-rectHeading h5, .ec-rectHeading h6 {
  background: #F3F3F3;
  padding: 8px 12px;
  font-size: 20px;
  font-weight: bold; }

/*
サブ見出し TODO 文言を修正

ユーザが行った操作に対する、完了報告やエラー表示のページで使用される見出しのスタイルです。TODO テキストを変更

ex [注文完了](http://demo3.ec-cube.net/shopping/complete)

Markup:
.ec-reportHeading
  h2 ご注文ありがとうございました

Styleguide 1.1.6
*/
.ec-reportHeading {
  width: 100%;
  border-top: 1px dotted #ccc;
  margin: 20px 0 30px;
  padding: 0;
  text-align: center;
  font-size: 24px;
  font-weight: bold; }
  @media only screen and (min-width: 768px) {
    .ec-reportHeading {
      border-top: 0;
      font-size: 32px; } }
  .ec-reportHeading h1, .ec-reportHeading h2, .ec-reportHeading h3,
  .ec-reportHeading h4, .ec-reportHeading h5, .ec-reportHeading h6, .ec-reportHeading p {
    font-weight: bold;
    font-size: 24px; }
    @media only screen and (min-width: 768px) {
      .ec-reportHeading h1, .ec-reportHeading h2, .ec-reportHeading h3,
      .ec-reportHeading h4, .ec-reportHeading h5, .ec-reportHeading h6, .ec-reportHeading p {
        font-size: 32px; } }

/**
メディアクエリ
SP フォーストで記述する。
Twitter Bootstrap デフォルト準拠
 */
/*
文字装飾

文字装飾をするためのスタイル群です。

sg-wrapper:
<div class="ec-role">
  <sg-wrapper-content/>
</div>

Styleguide 1.2
*/
/*
テキストリンク

テキストリンクのスタイルです。

Markup:
a(href="#").ec-link さくらのクラウド

Styleguide 1.2.1
*/
.ec-link {
  color: #0092C4;
  text-decoration: none;
  cursor: pointer; }
  .ec-link:hover {
    color: #33A8D0;
    text-decoration: none; }

/*
テキスト（太字）

テキストを太くするためのスタイルです。

Markup:
p.ec-font-bold この季節にぴったりな商品をご用意しました

Styleguide 1.2.2
*/
.ec-font-bold {
  font-weight: bold; }

/*
テキスト（グレー）

テキストをグレーにするためのスタイルです。

Markup:
p.ec-color-grey 青色が美しい職人が仕上げた吹きガラス

Styleguide 1.2.3
*/
.ec-color-grey {
  color: #9a947e; }

/*
テキスト（赤）

テキストを赤にするためのスタイルです。

Markup:
p.ec-color-red ¥ 2,728 税込
p.ec-color-accent ¥ 2,728 税込

Styleguide 1.2.4
*/
.ec-color-red {
  color: #DE5D50; }

.ec-color-accent {
  color: #DE5D50; }

/*
フォントサイズ

フォントサイズを指定するためのスタイルです。

Markup:
.ec-font-size-1 さわやかな日差しが過ごしやすい季節
.ec-font-size-2 さわやかな日差しが過ごしやすい季節
.ec-font-size-3 さわやかな日差しが過ごしやすい季節
.ec-font-size-4 さわやかな日差しが過ごしやすい季節
.ec-font-size-5 さわやかな日差しが過ごしやすい季節
.ec-font-size-6 さわやかな日差しが過ごしやすい季節


Styleguide 1.2.5
*/
.ec-font-size-1 {
  font-size: 12px; }

.ec-font-size-2 {
  font-size: 14px; }

.ec-font-size-3 {
  font-size: 16px; }

.ec-font-size-4 {
  font-size: 20px; }

.ec-font-size-5 {
  font-size: 32px; }

.ec-font-size-6 {
  font-size: 40px; }

/*
テキスト水平位置

テキストをセンタリングするためのスタイルです。

Markup:
p.ec-text-ac さわやかな日差しが過ごしやすい季節

Styleguide 1.2.6
*/
.ec-text-ac {
  text-align: center; }

/*
価格テキスト

価格を表示するテキストです。

価格文字にスペースを取るほか、税込み等の表示を小さくする効果もあります。

spanを用いたインライン要素として利用します。

Markup:
div(style="color:#DE5D50;font-size:28px")
    span.ec-price
      span.ec-price__unit ¥
      span.ec-price__price 1,280
      span.ec-price__tax 税込

Styleguide 1.2.7
*/
.ec-price .ec-price__unit {
  font-size: 18px;
  font-weight: bold; }
  @media only screen and (min-width: 768px) {
    .ec-price .ec-price__unit {
      font-size: 1em; } }

.ec-price .ec-price__price {
  display: inline-block;
  padding: 0 .3em;
  font-size: 18px;
  font-weight: bold; }
  @media only screen and (min-width: 768px) {
    .ec-price .ec-price__price {
      font-size: 1em; } }

.ec-price .ec-price__tax {
  font-size: 10px; }
  @media only screen and (min-width: 768px) {
    .ec-price .ec-price__tax {
      font-size: 0.57em; } }

/*
テキストの位置

テキストや、入れ子にしたインライン要素を
「左揃え」「中央揃え」「右揃え」に設定することができます。

Markup:
h3 左揃え
p.text-left
  | Lorem ipsum dolor sit amet, consectetur adipisicing elit. Incidunt praesentium repellat sapiente suscipit, unde veniam! Doloribus error, expedita id impedit iusto qui sint totam? Aspernatur error facere possimus quam quos?
br
h3 中央揃え
p.text-center
  | Lorem ipsum dolor sit amet, consectetur adipisicing elit. Incidunt praesentium repellat sapiente suscipit, unde veniam! Doloribus error, expedita id impedit iusto qui sint totam? Aspernatur error facere possimus quam quos?
br
h3 右揃え
p.text-right
  | Lorem ipsum dolor sit amet, consectetur adipisicing elit. Incidunt praesentium repellat sapiente suscipit, unde veniam! Doloribus error, expedita id impedit iusto qui sint totam? Aspernatur error facere possimus quam quos?

Styleguide 1.2.8
*/
.text-left {
  text-align: left; }

.text-center {
  text-align: center; }

.text-right {
  text-align: right; }

/*
テキストの位置

ユーザが行った操作に対する、完了報告やエラー表示のページで使用されるテキストのスタイルです。TODO テキストを変更

ex [注文完了](http://demo3.ec-cube.net/shopping/complete)

Markup:
.ec-rectHeading
  h2 ご注文ありがとうございました
p.ec-reportDescription
      | ただいま、ご注文の確認メールをお送りさせていただきました。
      br
      | 万一、ご確認メールが届かない場合は、トラブルの可能性もありますので大変お手数ではございますがもう一度お問い合わせいただくか、お電話にてお問い合わせくださいませ。
      br
      | 今後ともご愛顧賜りますようよろしくお願い申し上げます。


Styleguide 1.2.9
*/
.ec-reportDescription {
  margin-bottom: 32px;
  text-align: center;
  font-size: 16px;
  line-height: 1.4; }

/**
メディアクエリ
SP フォーストで記述する。
Twitter Bootstrap デフォルト準拠
 */
/*
リスト

シンプルなリストを構成するためのスタイル群です。F

sg-wrapper:
<div class="ec-role">
  <sg-wrapper-content/>
</div>

Styleguide 1.3
*/
/*
水平定義リスト

シンプルな定義リストのスタイルを定義します。

dl要素を用いてコーディングします。

ex [当サイトについて　水平定義リスト部分](http://demo3.ec-cube.net/help/about)

Markup:
dl.ec-definitions
    dt 店名
    dd EC-CUBE3 DEMO SHOP
dl.ec-definitions
    dt 会社名
    dd EC-CUBE3
dl.ec-definitions--soft
    dt 所在地
    dd 〒 550-0001

Styleguide 1.3.1
*/
.ec-definitions, .ec-definitions--soft {
  margin: 5px 0;
  display: block; }
  .ec-definitions dt, .ec-definitions--soft dt, .ec-definitions dd, .ec-definitions--soft dd {
    display: inline-block;
    margin: 0; }
  .ec-definitions dt, .ec-definitions--soft dt {
    font-weight: bold; }

.ec-definitions--soft dt {
  font-weight: normal; }

/*
下線つき定義リスト

線が添えられた定義リストのスタイルを定義します。

dl要素を用いてコーディングします。

ex [当サイトについて　下線つき定義リスト](http://demo3.ec-cube.net/help/about)

Markup:
.ec-borderedDefs
  dl
    dt 店名
    dd EC-CUBE3 DEMO SHOP
  dl
    dt 会社名
    dd EC-CUBE3
  dl
    dt 所在地
    dd 〒550 - 0001

Styleguide 1.3.2
*/
.ec-borderedDefs {
  width: 100%;
  border-top: 1px dotted #ccc;
  margin-bottom: 16px; }
  .ec-borderedDefs dl {
    display: flex;
    border-bottom: 1px dotted #ccc;
    margin: 0;
    padding: 8px 0;
    flex-wrap: wrap; }
    @media only screen and (min-width: 768px) {
      .ec-borderedDefs dl {
        flex-wrap: nowrap; } }
  .ec-borderedDefs dt, .ec-borderedDefs dd {
    padding: 0; }
    @media only screen and (min-width: 768px) {
      .ec-borderedDefs dt, .ec-borderedDefs dd {
        padding: 0 16px; } }
  .ec-borderedDefs dt {
    font-weight: normal;
    width: 100%; }
    @media only screen and (min-width: 768px) {
      .ec-borderedDefs dt {
        width: 30%; } }
  .ec-borderedDefs dd {
    padding: 0;
    width: 100%;
    line-height: 2.5; }
    @media only screen and (min-width: 768px) {
      .ec-borderedDefs dd {
        width: 70%;
        line-height: 3; } }
  .ec-borderedDefs p {
    line-height: 1.4; }

.ec-list-chilled {
  display: table-row;
  border: 0 none;
  padding: 8px 0; }
  .ec-list-chilled dt, .ec-list-chilled dd {
    display: table-cell;
    border-bottom: 1px dotted #ccc;
    padding: 0; }
    @media only screen and (min-width: 768px) {
      .ec-list-chilled dt, .ec-list-chilled dd {
        padding: 16px 0; } }
  .ec-list-chilled dt {
    width: 30%; }
  .ec-list-chilled dd {
    padding: 0; }
    @media only screen and (min-width: 768px) {
      .ec-list-chilled dd {
        padding: 16px; } }

/*
ボーダーリスト


ex [当サイトについて　ボーダーリスト](http://demo3.ec-cube.net/help/about)

Markup:
ul.ec-borderedList
  li: p lorem
  li: p lorem
  li: p lorem


Styleguide 1.3.3
*/
.ec-borderedList {
  width: 100%;
  border-top: 0;
  list-style: none;
  padding: 0; }
  @media only screen and (min-width: 768px) {
    .ec-borderedList {
      border-top: 1px dotted #ccc; } }
  .ec-borderedList li {
    border-bottom: 1px dotted #ccc; }

.ec-list-chilled {
  display: table-row;
  border: 0 none;
  padding: 8px 0; }
  .ec-list-chilled dt, .ec-list-chilled dd {
    display: table-cell;
    border-bottom: 1px dotted #ccc;
    padding: 16px 0; }
  .ec-list-chilled dt {
    width: 30%; }
  .ec-list-chilled dd {
    padding: 16px; }

/*
水平リスト

TODO content 廃止

ex [商品一覧カテゴリ絞り込み　水平リスト部分](http://demo3.ec-cube.net/products/list?category_id=7)

Markup:
ol.ec-list-flat
    li
        a(href="#").ec-link 全商品
    li
        a(href="#").ec-link キッチンツール
    li
        a(href="#").ec-link キッチン小物

Styleguide 1.3.4
*/
.ec-list-flat {
  list-style: none;
  overflow: hidden;
  padding: 0 0.7em; }
  .ec-list-flat li {
    float: left; }
    .ec-list-flat li:after {
      content: "｜"; }
    .ec-list-flat li:last-child:after {
      content: ''; }
  .ec-list-flat a {
    float: left;
    display: inline-block; }

/*
ボタンサイズ

ボタンサイズ

sg-wrapper:
<div class="ec-role">
  <sg-wrapper-content/>
</div>

Styleguide 2.1
*/
/*
通常ボタン

インラインの要素としてボタンを定義出来ます。

ex [トップページ　ボタン部分](http://demo3.ec-cube.net/)

Markup:
.ec-inlineBtn 住所検索
.ec-inlineBtn--primary もっと見る
.ec-inlineBtn--action カートに入れる
.ec-inlineBtn--cancel キャンセル

Styleguide 2.1.1
*/
.ec-inlineBtn {
  display: inline-block;
  margin-bottom: 0;
  font-weight: bold;
  text-align: center;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  background-image: none;
  border: 1px solid transparent;
  white-space: nowrap;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857;
  border-radius: 0px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  padding: 10px 16px;
  text-decoration: none;
  color: #525263;
  background-color: #F5F7F8;
  border-color: #ccc; }
  .ec-inlineBtn:focus, .ec-inlineBtn.focus, .ec-inlineBtn:active:focus, .ec-inlineBtn:active.focus, .ec-inlineBtn.active:focus, .ec-inlineBtn.active.focus {
    outline: 5px auto -webkit-focus-ring-color;
    outline-offset: -2px; }
  .ec-inlineBtn:hover, .ec-inlineBtn:focus, .ec-inlineBtn.focus {
    color: #525263;
    text-decoration: none; }
  .ec-inlineBtn:active, .ec-inlineBtn.active {
    outline: 0;
    background-image: none;
    -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125); }
  .ec-inlineBtn.disabled, .ec-inlineBtn[disabled],
  fieldset[disabled] .ec-inlineBtn {
    cursor: not-allowed;
    opacity: 0.65;
    filter: alpha(opacity=65);
    -webkit-box-shadow: none;
    box-shadow: none; }
  .ec-inlineBtn:focus, .ec-inlineBtn.focus {
    color: #525263;
    background-color: #d7dfe3;
    border-color: #8c8c8c; }
  .ec-inlineBtn:hover {
    color: #525263;
    background-color: #d7dfe3;
    border-color: #adadad; }
  .ec-inlineBtn:active, .ec-inlineBtn.active,
  .open > .ec-inlineBtn.dropdown-toggle {
    color: #525263;
    background-color: #d7dfe3;
    border-color: #adadad; }
    .ec-inlineBtn:active:hover, .ec-inlineBtn:active:focus, .ec-inlineBtn:active.focus, .ec-inlineBtn.active:hover, .ec-inlineBtn.active:focus, .ec-inlineBtn.active.focus,
    .open > .ec-inlineBtn.dropdown-toggle:hover,
    .open > .ec-inlineBtn.dropdown-toggle:focus,
    .open > .ec-inlineBtn.dropdown-toggle.focus {
      color: #525263;
      background-color: #c2ced4;
      border-color: #8c8c8c; }
  .ec-inlineBtn:active, .ec-inlineBtn.active,
  .open > .ec-inlineBtn.dropdown-toggle {
    background-image: none; }
  .ec-inlineBtn.disabled:hover, .ec-inlineBtn.disabled:focus, .ec-inlineBtn.disabled.focus, .ec-inlineBtn[disabled]:hover, .ec-inlineBtn[disabled]:focus, .ec-inlineBtn[disabled].focus,
  fieldset[disabled] .ec-inlineBtn:hover,
  fieldset[disabled] .ec-inlineBtn:focus,
  fieldset[disabled] .ec-inlineBtn.focus {
    background-color: #F5F7F8;
    border-color: #ccc; }
  .ec-inlineBtn .badge {
    color: #F5F7F8;
    background-color: #525263; }

.ec-inlineBtn--primary {
  display: inline-block;
  margin-bottom: 0;
  font-weight: bold;
  text-align: center;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  background-image: none;
  border: 1px solid transparent;
  white-space: nowrap;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857;
  border-radius: 0px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  padding: 10px 16px;
  text-decoration: none;
  color: #fff;
  background-color: #5CB1B1;
  border-color: #5CB1B1; }
  .ec-inlineBtn--primary:focus, .ec-inlineBtn--primary.focus, .ec-inlineBtn--primary:active:focus, .ec-inlineBtn--primary:active.focus, .ec-inlineBtn--primary.active:focus, .ec-inlineBtn--primary.active.focus {
    outline: 5px auto -webkit-focus-ring-color;
    outline-offset: -2px; }
  .ec-inlineBtn--primary:hover, .ec-inlineBtn--primary:focus, .ec-inlineBtn--primary.focus {
    color: #525263;
    text-decoration: none; }
  .ec-inlineBtn--primary:active, .ec-inlineBtn--primary.active {
    outline: 0;
    background-image: none;
    -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125); }
  .ec-inlineBtn--primary.disabled, .ec-inlineBtn--primary[disabled],
  fieldset[disabled] .ec-inlineBtn--primary {
    cursor: not-allowed;
    opacity: 0.65;
    filter: alpha(opacity=65);
    -webkit-box-shadow: none;
    box-shadow: none; }
  .ec-inlineBtn--primary:focus, .ec-inlineBtn--primary.focus {
    color: #fff;
    background-color: #479393;
    border-color: #2e6060; }
  .ec-inlineBtn--primary:hover {
    color: #fff;
    background-color: #479393;
    border-color: #438d8d; }
  .ec-inlineBtn--primary:active, .ec-inlineBtn--primary.active,
  .open > .ec-inlineBtn--primary.dropdown-toggle {
    color: #fff;
    background-color: #479393;
    border-color: #438d8d; }
    .ec-inlineBtn--primary:active:hover, .ec-inlineBtn--primary:active:focus, .ec-inlineBtn--primary:active.focus, .ec-inlineBtn--primary.active:hover, .ec-inlineBtn--primary.active:focus, .ec-inlineBtn--primary.active.focus,
    .open > .ec-inlineBtn--primary.dropdown-toggle:hover,
    .open > .ec-inlineBtn--primary.dropdown-toggle:focus,
    .open > .ec-inlineBtn--primary.dropdown-toggle.focus {
      color: #fff;
      background-color: #3b7b7b;
      border-color: #2e6060; }
  .ec-inlineBtn--primary:active, .ec-inlineBtn--primary.active,
  .open > .ec-inlineBtn--primary.dropdown-toggle {
    background-image: none; }
  .ec-inlineBtn--primary.disabled:hover, .ec-inlineBtn--primary.disabled:focus, .ec-inlineBtn--primary.disabled.focus, .ec-inlineBtn--primary[disabled]:hover, .ec-inlineBtn--primary[disabled]:focus, .ec-inlineBtn--primary[disabled].focus,
  fieldset[disabled] .ec-inlineBtn--primary:hover,
  fieldset[disabled] .ec-inlineBtn--primary:focus,
  fieldset[disabled] .ec-inlineBtn--primary.focus {
    background-color: #5CB1B1;
    border-color: #5CB1B1; }
  .ec-inlineBtn--primary .badge {
    color: #5CB1B1;
    background-color: #fff; }

.ec-inlineBtn--action {
  display: inline-block;
  margin-bottom: 0;
  font-weight: bold;
  text-align: center;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  background-image: none;
  border: 1px solid transparent;
  white-space: nowrap;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857;
  border-radius: 0px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  padding: 10px 16px;
  text-decoration: none;
  color: #fff;
  background-color: #DE5D50;
  border-color: #DE5D50; }
  .ec-inlineBtn--action:focus, .ec-inlineBtn--action.focus, .ec-inlineBtn--action:active:focus, .ec-inlineBtn--action:active.focus, .ec-inlineBtn--action.active:focus, .ec-inlineBtn--action.active.focus {
    outline: 5px auto -webkit-focus-ring-color;
    outline-offset: -2px; }
  .ec-inlineBtn--action:hover, .ec-inlineBtn--action:focus, .ec-inlineBtn--action.focus {
    color: #525263;
    text-decoration: none; }
  .ec-inlineBtn--action:active, .ec-inlineBtn--action.active {
    outline: 0;
    background-image: none;
    -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125); }
  .ec-inlineBtn--action.disabled, .ec-inlineBtn--action[disabled],
  fieldset[disabled] .ec-inlineBtn--action {
    cursor: not-allowed;
    opacity: 0.65;
    filter: alpha(opacity=65);
    -webkit-box-shadow: none;
    box-shadow: none; }
  .ec-inlineBtn--action:focus, .ec-inlineBtn--action.focus {
    color: #fff;
    background-color: #d33828;
    border-color: #93271c; }
  .ec-inlineBtn--action:hover {
    color: #fff;
    background-color: #d33828;
    border-color: #cb3526; }
  .ec-inlineBtn--action:active, .ec-inlineBtn--action.active,
  .open > .ec-inlineBtn--action.dropdown-toggle {
    color: #fff;
    background-color: #d33828;
    border-color: #cb3526; }
    .ec-inlineBtn--action:active:hover, .ec-inlineBtn--action:active:focus, .ec-inlineBtn--action:active.focus, .ec-inlineBtn--action.active:hover, .ec-inlineBtn--action.active:focus, .ec-inlineBtn--action.active.focus,
    .open > .ec-inlineBtn--action.dropdown-toggle:hover,
    .open > .ec-inlineBtn--action.dropdown-toggle:focus,
    .open > .ec-inlineBtn--action.dropdown-toggle.focus {
      color: #fff;
      background-color: #b53022;
      border-color: #93271c; }
  .ec-inlineBtn--action:active, .ec-inlineBtn--action.active,
  .open > .ec-inlineBtn--action.dropdown-toggle {
    background-image: none; }
  .ec-inlineBtn--action.disabled:hover, .ec-inlineBtn--action.disabled:focus, .ec-inlineBtn--action.disabled.focus, .ec-inlineBtn--action[disabled]:hover, .ec-inlineBtn--action[disabled]:focus, .ec-inlineBtn--action[disabled].focus,
  fieldset[disabled] .ec-inlineBtn--action:hover,
  fieldset[disabled] .ec-inlineBtn--action:focus,
  fieldset[disabled] .ec-inlineBtn--action.focus {
    background-color: #DE5D50;
    border-color: #DE5D50; }
  .ec-inlineBtn--action .badge {
    color: #DE5D50;
    background-color: #fff; }

.ec-inlineBtn--cancel {
  display: inline-block;
  margin-bottom: 0;
  font-weight: bold;
  text-align: center;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  background-image: none;
  border: 1px solid transparent;
  white-space: nowrap;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857;
  border-radius: 0px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  padding: 10px 16px;
  text-decoration: none;
  color: #fff;
  background-color: #525263;
  border-color: #525263; }
  .ec-inlineBtn--cancel:focus, .ec-inlineBtn--cancel.focus, .ec-inlineBtn--cancel:active:focus, .ec-inlineBtn--cancel:active.focus, .ec-inlineBtn--cancel.active:focus, .ec-inlineBtn--cancel.active.focus {
    outline: 5px auto -webkit-focus-ring-color;
    outline-offset: -2px; }
  .ec-inlineBtn--cancel:hover, .ec-inlineBtn--cancel:focus, .ec-inlineBtn--cancel.focus {
    color: #525263;
    text-decoration: none; }
  .ec-inlineBtn--cancel:active, .ec-inlineBtn--cancel.active {
    outline: 0;
    background-image: none;
    -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125); }
  .ec-inlineBtn--cancel.disabled, .ec-inlineBtn--cancel[disabled],
  fieldset[disabled] .ec-inlineBtn--cancel {
    cursor: not-allowed;
    opacity: 0.65;
    filter: alpha(opacity=65);
    -webkit-box-shadow: none;
    box-shadow: none; }
  .ec-inlineBtn--cancel:focus, .ec-inlineBtn--cancel.focus {
    color: #fff;
    background-color: #3b3b47;
    border-color: #18181d; }
  .ec-inlineBtn--cancel:hover {
    color: #fff;
    background-color: #3b3b47;
    border-color: #363642; }
  .ec-inlineBtn--cancel:active, .ec-inlineBtn--cancel.active,
  .open > .ec-inlineBtn--cancel.dropdown-toggle {
    color: #fff;
    background-color: #3b3b47;
    border-color: #363642; }
    .ec-inlineBtn--cancel:active:hover, .ec-inlineBtn--cancel:active:focus, .ec-inlineBtn--cancel:active.focus, .ec-inlineBtn--cancel.active:hover, .ec-inlineBtn--cancel.active:focus, .ec-inlineBtn--cancel.active.focus,
    .open > .ec-inlineBtn--cancel.dropdown-toggle:hover,
    .open > .ec-inlineBtn--cancel.dropdown-toggle:focus,
    .open > .ec-inlineBtn--cancel.dropdown-toggle.focus {
      color: #fff;
      background-color: #2b2b34;
      border-color: #18181d; }
  .ec-inlineBtn--cancel:active, .ec-inlineBtn--cancel.active,
  .open > .ec-inlineBtn--cancel.dropdown-toggle {
    background-image: none; }
  .ec-inlineBtn--cancel.disabled:hover, .ec-inlineBtn--cancel.disabled:focus, .ec-inlineBtn--cancel.disabled.focus, .ec-inlineBtn--cancel[disabled]:hover, .ec-inlineBtn--cancel[disabled]:focus, .ec-inlineBtn--cancel[disabled].focus,
  fieldset[disabled] .ec-inlineBtn--cancel:hover,
  fieldset[disabled] .ec-inlineBtn--cancel:focus,
  fieldset[disabled] .ec-inlineBtn--cancel.focus {
    background-color: #525263;
    border-color: #525263; }
  .ec-inlineBtn--cancel .badge {
    color: #525263;
    background-color: #fff; }

/*
ブロックボタン（全幅）

ボタンサイズは em で指定するため、テキストサイズの変更でボタンサイズを変更できます。

ex [商品詳細ページ　カートボタン部分](http://demo3.ec-cube.net/products/detail/30)

Markup:
p: .ec-blockBtn 住所検索
p: .ec-blockBtn--primary もっと見る
p: .ec-blockBtn--action カートに入れる
p: .ec-blockBtn--cancel キャンセル

Styleguide 2.1.2
*/
.ec-blockBtn {
  display: inline-block;
  margin-bottom: 0;
  font-weight: bold;
  text-align: center;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  background-image: none;
  border: 1px solid transparent;
  white-space: nowrap;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857;
  border-radius: 0px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  padding: 10px 16px;
  text-decoration: none;
  color: #525263;
  background-color: #F5F7F8;
  border-color: #ccc;
  display: block;
  width: 100%;
  height: 56px;
  line-height: 56px;
  padding-top: 0;
  padding-bottom: 0; }
  .ec-blockBtn:focus, .ec-blockBtn.focus, .ec-blockBtn:active:focus, .ec-blockBtn:active.focus, .ec-blockBtn.active:focus, .ec-blockBtn.active.focus {
    outline: 5px auto -webkit-focus-ring-color;
    outline-offset: -2px; }
  .ec-blockBtn:hover, .ec-blockBtn:focus, .ec-blockBtn.focus {
    color: #525263;
    text-decoration: none; }
  .ec-blockBtn:active, .ec-blockBtn.active {
    outline: 0;
    background-image: none;
    -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125); }
  .ec-blockBtn.disabled, .ec-blockBtn[disabled],
  fieldset[disabled] .ec-blockBtn {
    cursor: not-allowed;
    opacity: 0.65;
    filter: alpha(opacity=65);
    -webkit-box-shadow: none;
    box-shadow: none; }
  .ec-blockBtn:focus, .ec-blockBtn.focus {
    color: #525263;
    background-color: #d7dfe3;
    border-color: #8c8c8c; }
  .ec-blockBtn:hover {
    color: #525263;
    background-color: #d7dfe3;
    border-color: #adadad; }
  .ec-blockBtn:active, .ec-blockBtn.active,
  .open > .ec-blockBtn.dropdown-toggle {
    color: #525263;
    background-color: #d7dfe3;
    border-color: #adadad; }
    .ec-blockBtn:active:hover, .ec-blockBtn:active:focus, .ec-blockBtn:active.focus, .ec-blockBtn.active:hover, .ec-blockBtn.active:focus, .ec-blockBtn.active.focus,
    .open > .ec-blockBtn.dropdown-toggle:hover,
    .open > .ec-blockBtn.dropdown-toggle:focus,
    .open > .ec-blockBtn.dropdown-toggle.focus {
      color: #525263;
      background-color: #c2ced4;
      border-color: #8c8c8c; }
  .ec-blockBtn:active, .ec-blockBtn.active,
  .open > .ec-blockBtn.dropdown-toggle {
    background-image: none; }
  .ec-blockBtn.disabled:hover, .ec-blockBtn.disabled:focus, .ec-blockBtn.disabled.focus, .ec-blockBtn[disabled]:hover, .ec-blockBtn[disabled]:focus, .ec-blockBtn[disabled].focus,
  fieldset[disabled] .ec-blockBtn:hover,
  fieldset[disabled] .ec-blockBtn:focus,
  fieldset[disabled] .ec-blockBtn.focus {
    background-color: #F5F7F8;
    border-color: #ccc; }
  .ec-blockBtn .badge {
    color: #F5F7F8;
    background-color: #525263; }

.ec-blockBtn--primary {
  display: inline-block;
  margin-bottom: 0;
  font-weight: bold;
  text-align: center;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  background-image: none;
  border: 1px solid transparent;
  white-space: nowrap;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857;
  border-radius: 0px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  padding: 10px 16px;
  text-decoration: none;
  color: #fff;
  background-color: #5CB1B1;
  border-color: #5CB1B1;
  display: block;
  width: 100%;
  height: 56px;
  line-height: 56px;
  padding-top: 0;
  padding-bottom: 0; }
  .ec-blockBtn--primary:focus, .ec-blockBtn--primary.focus, .ec-blockBtn--primary:active:focus, .ec-blockBtn--primary:active.focus, .ec-blockBtn--primary.active:focus, .ec-blockBtn--primary.active.focus {
    outline: 5px auto -webkit-focus-ring-color;
    outline-offset: -2px; }
  .ec-blockBtn--primary:hover, .ec-blockBtn--primary:focus, .ec-blockBtn--primary.focus {
    color: #525263;
    text-decoration: none; }
  .ec-blockBtn--primary:active, .ec-blockBtn--primary.active {
    outline: 0;
    background-image: none;
    -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125); }
  .ec-blockBtn--primary.disabled, .ec-blockBtn--primary[disabled],
  fieldset[disabled] .ec-blockBtn--primary {
    cursor: not-allowed;
    opacity: 0.65;
    filter: alpha(opacity=65);
    -webkit-box-shadow: none;
    box-shadow: none; }
  .ec-blockBtn--primary:focus, .ec-blockBtn--primary.focus {
    color: #fff;
    background-color: #479393;
    border-color: #2e6060; }
  .ec-blockBtn--primary:hover {
    color: #fff;
    background-color: #479393;
    border-color: #438d8d; }
  .ec-blockBtn--primary:active, .ec-blockBtn--primary.active,
  .open > .ec-blockBtn--primary.dropdown-toggle {
    color: #fff;
    background-color: #479393;
    border-color: #438d8d; }
    .ec-blockBtn--primary:active:hover, .ec-blockBtn--primary:active:focus, .ec-blockBtn--primary:active.focus, .ec-blockBtn--primary.active:hover, .ec-blockBtn--primary.active:focus, .ec-blockBtn--primary.active.focus,
    .open > .ec-blockBtn--primary.dropdown-toggle:hover,
    .open > .ec-blockBtn--primary.dropdown-toggle:focus,
    .open > .ec-blockBtn--primary.dropdown-toggle.focus {
      color: #fff;
      background-color: #3b7b7b;
      border-color: #2e6060; }
  .ec-blockBtn--primary:active, .ec-blockBtn--primary.active,
  .open > .ec-blockBtn--primary.dropdown-toggle {
    background-image: none; }
  .ec-blockBtn--primary.disabled:hover, .ec-blockBtn--primary.disabled:focus, .ec-blockBtn--primary.disabled.focus, .ec-blockBtn--primary[disabled]:hover, .ec-blockBtn--primary[disabled]:focus, .ec-blockBtn--primary[disabled].focus,
  fieldset[disabled] .ec-blockBtn--primary:hover,
  fieldset[disabled] .ec-blockBtn--primary:focus,
  fieldset[disabled] .ec-blockBtn--primary.focus {
    background-color: #5CB1B1;
    border-color: #5CB1B1; }
  .ec-blockBtn--primary .badge {
    color: #5CB1B1;
    background-color: #fff; }

.ec-blockBtn--action {
  display: inline-block;
  margin-bottom: 0;
  font-weight: bold;
  text-align: center;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  background-image: none;
  border: 1px solid transparent;
  white-space: nowrap;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857;
  border-radius: 0px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  padding: 10px 16px;
  text-decoration: none;
  color: #fff;
  background-color: #DE5D50;
  border-color: #DE5D50;
  display: block;
  width: 100%;
  height: 56px;
  line-height: 56px;
  padding-top: 0;
  padding-bottom: 0; }
  .ec-blockBtn--action:focus, .ec-blockBtn--action.focus, .ec-blockBtn--action:active:focus, .ec-blockBtn--action:active.focus, .ec-blockBtn--action.active:focus, .ec-blockBtn--action.active.focus {
    outline: 5px auto -webkit-focus-ring-color;
    outline-offset: -2px; }
  .ec-blockBtn--action:hover, .ec-blockBtn--action:focus, .ec-blockBtn--action.focus {
    color: #525263;
    text-decoration: none; }
  .ec-blockBtn--action:active, .ec-blockBtn--action.active {
    outline: 0;
    background-image: none;
    -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125); }
  .ec-blockBtn--action.disabled, .ec-blockBtn--action[disabled],
  fieldset[disabled] .ec-blockBtn--action {
    cursor: not-allowed;
    opacity: 0.65;
    filter: alpha(opacity=65);
    -webkit-box-shadow: none;
    box-shadow: none; }
  .ec-blockBtn--action:focus, .ec-blockBtn--action.focus {
    color: #fff;
    background-color: #d33828;
    border-color: #93271c; }
  .ec-blockBtn--action:hover {
    color: #fff;
    background-color: #d33828;
    border-color: #cb3526; }
  .ec-blockBtn--action:active, .ec-blockBtn--action.active,
  .open > .ec-blockBtn--action.dropdown-toggle {
    color: #fff;
    background-color: #d33828;
    border-color: #cb3526; }
    .ec-blockBtn--action:active:hover, .ec-blockBtn--action:active:focus, .ec-blockBtn--action:active.focus, .ec-blockBtn--action.active:hover, .ec-blockBtn--action.active:focus, .ec-blockBtn--action.active.focus,
    .open > .ec-blockBtn--action.dropdown-toggle:hover,
    .open > .ec-blockBtn--action.dropdown-toggle:focus,
    .open > .ec-blockBtn--action.dropdown-toggle.focus {
      color: #fff;
      background-color: #b53022;
      border-color: #93271c; }
  .ec-blockBtn--action:active, .ec-blockBtn--action.active,
  .open > .ec-blockBtn--action.dropdown-toggle {
    background-image: none; }
  .ec-blockBtn--action.disabled:hover, .ec-blockBtn--action.disabled:focus, .ec-blockBtn--action.disabled.focus, .ec-blockBtn--action[disabled]:hover, .ec-blockBtn--action[disabled]:focus, .ec-blockBtn--action[disabled].focus,
  fieldset[disabled] .ec-blockBtn--action:hover,
  fieldset[disabled] .ec-blockBtn--action:focus,
  fieldset[disabled] .ec-blockBtn--action.focus {
    background-color: #DE5D50;
    border-color: #DE5D50; }
  .ec-blockBtn--action .badge {
    color: #DE5D50;
    background-color: #fff; }

.ec-blockBtn--cancel {
  display: inline-block;
  margin-bottom: 0;
  font-weight: bold;
  text-align: center;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  background-image: none;
  border: 1px solid transparent;
  white-space: nowrap;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857;
  border-radius: 0px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  padding: 10px 16px;
  text-decoration: none;
  color: #fff;
  background-color: #525263;
  border-color: #525263;
  display: block;
  width: 100%;
  height: 56px;
  line-height: 56px;
  padding-top: 0;
  padding-bottom: 0; }
  .ec-blockBtn--cancel:focus, .ec-blockBtn--cancel.focus, .ec-blockBtn--cancel:active:focus, .ec-blockBtn--cancel:active.focus, .ec-blockBtn--cancel.active:focus, .ec-blockBtn--cancel.active.focus {
    outline: 5px auto -webkit-focus-ring-color;
    outline-offset: -2px; }
  .ec-blockBtn--cancel:hover, .ec-blockBtn--cancel:focus, .ec-blockBtn--cancel.focus {
    color: #525263;
    text-decoration: none; }
  .ec-blockBtn--cancel:active, .ec-blockBtn--cancel.active {
    outline: 0;
    background-image: none;
    -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125); }
  .ec-blockBtn--cancel.disabled, .ec-blockBtn--cancel[disabled],
  fieldset[disabled] .ec-blockBtn--cancel {
    cursor: not-allowed;
    opacity: 0.65;
    filter: alpha(opacity=65);
    -webkit-box-shadow: none;
    box-shadow: none; }
  .ec-blockBtn--cancel:focus, .ec-blockBtn--cancel.focus {
    color: #fff;
    background-color: #3b3b47;
    border-color: #18181d; }
  .ec-blockBtn--cancel:hover {
    color: #fff;
    background-color: #3b3b47;
    border-color: #363642; }
  .ec-blockBtn--cancel:active, .ec-blockBtn--cancel.active,
  .open > .ec-blockBtn--cancel.dropdown-toggle {
    color: #fff;
    background-color: #3b3b47;
    border-color: #363642; }
    .ec-blockBtn--cancel:active:hover, .ec-blockBtn--cancel:active:focus, .ec-blockBtn--cancel:active.focus, .ec-blockBtn--cancel.active:hover, .ec-blockBtn--cancel.active:focus, .ec-blockBtn--cancel.active.focus,
    .open > .ec-blockBtn--cancel.dropdown-toggle:hover,
    .open > .ec-blockBtn--cancel.dropdown-toggle:focus,
    .open > .ec-blockBtn--cancel.dropdown-toggle.focus {
      color: #fff;
      background-color: #2b2b34;
      border-color: #18181d; }
  .ec-blockBtn--cancel:active, .ec-blockBtn--cancel.active,
  .open > .ec-blockBtn--cancel.dropdown-toggle {
    background-image: none; }
  .ec-blockBtn--cancel.disabled:hover, .ec-blockBtn--cancel.disabled:focus, .ec-blockBtn--cancel.disabled.focus, .ec-blockBtn--cancel[disabled]:hover, .ec-blockBtn--cancel[disabled]:focus, .ec-blockBtn--cancel[disabled].focus,
  fieldset[disabled] .ec-blockBtn--cancel:hover,
  fieldset[disabled] .ec-blockBtn--cancel:focus,
  fieldset[disabled] .ec-blockBtn--cancel.focus {
    background-color: #525263;
    border-color: #525263; }
  .ec-blockBtn--cancel .badge {
    color: #525263;
    background-color: #fff; }

/*
アイコンボタン

閉じる、などで利用されるSVGアイコンを用いたアイコンボタンです。

sg-wrapper:
<div class="ec-role">
  <sg-wrapper-content/>


Styleguide 2.2
*/
/*
アイコンボタン

閉じるなどSVGアイコンを用いたボタン装飾で利用します。

ex [ログイン画面　☓ボタン部分](http://demo3.ec-cube.net/mypage/login)

Markup:
include /assets/tmpl/elements/4.1.icon.pug
+b.A.ec-closeBtn
  +e.icon: +svg-cross

Styleguide 2.2.1
*/
.ec-closeBtn .ec-closeBtn__icon svg {
  overflow: hidden;
  fill: #B8BEC4;
  margin-right: 5px;
  position: relative;
  top: -1px;
  display: inline-block;
  width: 1em;
  height: 1em;
  vertical-align: middle; }
  .ec-closeBtn .ec-closeBtn__icon svg .icon-with-bg {
    fill: #FFF; }

/*
アイコンボタン(○)

閉じるなどSVGアイコンを用いたボタン装飾で利用します。

ex [ログイン画面　☓ボタン部分](http://demo3.ec-cube.net/mypage/login)



ex [お届け先編集画面　☓ボタン部分](http://demo3.ec-cube.net/mypage/delivery)

Markup:
include /assets/tmpl/elements/4.1.icon.pug
+b.A.ec-closeBtn--circle
  +e.icon: +svg-cross

Styleguide 2.2.2
*/
.ec-closeBtn--circle {
  display: block;
  border: 0 none;
  padding: 0;
  margin: 0;
  text-shadow: none;
  box-shadow: none;
  border-radius: 50%;
  background: #B8BEC4;
  cursor: pointer;
  width: 40px;
  min-width: 40px;
  max-width: 40px;
  height: 40px;
  line-height: 40px;
  vertical-align: middle;
  position: relative;
  text-align: center; }
  .ec-closeBtn--circle .ec-closeBtn--circle__icon {
    position: absolute;
    top: 28%;
    left: 50%;
    margin-top: -.5em;
    margin-left: -.5em;
    display: block;
    width: 1em;
    height: 1em;
    font-size: 20px; }
    .ec-closeBtn--circle .ec-closeBtn--circle__icon svg {
      overflow: hidden;
      fill: #FFF;
      display: inline-block;
      width: 100%;
      height: 100%; }
      .ec-closeBtn--circle .ec-closeBtn--circle__icon svg .icon-with-bg {
        fill: #FFF; }

/**
メディアクエリ
SP フォーストで記述する。
Twitter Bootstrap デフォルト準拠
 */
/*
フォーム部品(テキスト)

テキスト入力項目に関する要素を定義します。

sg-wrapper:
<div class="ec-role">
  <sg-wrapper-content/>


Styleguide 3.1
*/
/*
フォーム

`.ec-input` 要素は全ての入力項目に関する標準的なコンポーネントクラスです。


ex [会員情報編集画面　フォーム部分](http://demo3.ec-cube.net/mypage/change)

Markup:
p.ec-input
  input(type="number")
p.ec-input
  textarea(rows="6")

Styleguide 3.1.1
*/
.ec-input input[type="search"], .ec-halfInput input[type="search"], .ec-numberInput input[type="search"], .ec-zipInput input[type="search"], .ec-telInput input[type="search"], .ec-select input[type="search"], .ec-birth input[type="search"] {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

.ec-input input[type="radio"], .ec-halfInput input[type="radio"], .ec-numberInput input[type="radio"], .ec-zipInput input[type="radio"], .ec-telInput input[type="radio"], .ec-select input[type="radio"], .ec-birth input[type="radio"],
.ec-input input[type="checkbox"], .ec-halfInput input[type="checkbox"], .ec-numberInput input[type="checkbox"], .ec-zipInput input[type="checkbox"], .ec-telInput input[type="checkbox"], .ec-select input[type="checkbox"], .ec-birth input[type="checkbox"] {
  margin: 4px 0 0;
  margin-top: 1px \9;
  line-height: normal; }

.ec-input input[type="file"], .ec-halfInput input[type="file"], .ec-numberInput input[type="file"], .ec-zipInput input[type="file"], .ec-telInput input[type="file"], .ec-select input[type="file"], .ec-birth input[type="file"] {
  display: block; }

.ec-input input[type="range"], .ec-halfInput input[type="range"], .ec-numberInput input[type="range"], .ec-zipInput input[type="range"], .ec-telInput input[type="range"], .ec-select input[type="range"], .ec-birth input[type="range"] {
  display: block;
  width: 100%; }

.ec-input select[multiple], .ec-halfInput select[multiple], .ec-numberInput select[multiple], .ec-zipInput select[multiple], .ec-telInput select[multiple], .ec-select select[multiple], .ec-birth select[multiple],
.ec-input select[size], .ec-halfInput select[size], .ec-numberInput select[size], .ec-zipInput select[size], .ec-telInput select[size], .ec-select select[size], .ec-birth select[size] {
  height: auto; }

.ec-input input[type="file"]:focus, .ec-halfInput input[type="file"]:focus, .ec-numberInput input[type="file"]:focus, .ec-zipInput input[type="file"]:focus, .ec-telInput input[type="file"]:focus, .ec-select input[type="file"]:focus, .ec-birth input[type="file"]:focus,
.ec-input input[type="radio"]:focus, .ec-halfInput input[type="radio"]:focus, .ec-numberInput input[type="radio"]:focus, .ec-zipInput input[type="radio"]:focus, .ec-telInput input[type="radio"]:focus, .ec-select input[type="radio"]:focus, .ec-birth input[type="radio"]:focus,
.ec-input input[type="checkbox"]:focus, .ec-halfInput input[type="checkbox"]:focus, .ec-numberInput input[type="checkbox"]:focus, .ec-zipInput input[type="checkbox"]:focus, .ec-telInput input[type="checkbox"]:focus, .ec-select input[type="checkbox"]:focus, .ec-birth input[type="checkbox"]:focus {
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px; }

.ec-input input, .ec-halfInput input, .ec-numberInput input, .ec-zipInput input, .ec-telInput input, .ec-select input, .ec-birth input {
  display: block;
  width: 100%;
  height: 34px;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857;
  color: #555555;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  -webkit-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  -o-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  border-radius: 3px; }
  .ec-input input:focus, .ec-halfInput input:focus, .ec-numberInput input:focus, .ec-zipInput input:focus, .ec-telInput input:focus, .ec-select input:focus, .ec-birth input:focus {
    border-color: #66afe9;
    outline: 0;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6); }
  .ec-input input::-moz-placeholder, .ec-halfInput input::-moz-placeholder, .ec-numberInput input::-moz-placeholder, .ec-zipInput input::-moz-placeholder, .ec-telInput input::-moz-placeholder, .ec-select input::-moz-placeholder, .ec-birth input::-moz-placeholder {
    color: #999;
    opacity: 1; }
  .ec-input input:-ms-input-placeholder, .ec-halfInput input:-ms-input-placeholder, .ec-numberInput input:-ms-input-placeholder, .ec-zipInput input:-ms-input-placeholder, .ec-telInput input:-ms-input-placeholder, .ec-select input:-ms-input-placeholder, .ec-birth input:-ms-input-placeholder {
    color: #999; }
  .ec-input input::-webkit-input-placeholder, .ec-halfInput input::-webkit-input-placeholder, .ec-numberInput input::-webkit-input-placeholder, .ec-zipInput input::-webkit-input-placeholder, .ec-telInput input::-webkit-input-placeholder, .ec-select input::-webkit-input-placeholder, .ec-birth input::-webkit-input-placeholder {
    color: #999; }
  .ec-input input::-ms-expand, .ec-halfInput input::-ms-expand, .ec-numberInput input::-ms-expand, .ec-zipInput input::-ms-expand, .ec-telInput input::-ms-expand, .ec-select input::-ms-expand, .ec-birth input::-ms-expand {
    border: 0;
    background-color: transparent; }
  .ec-input input[disabled], .ec-halfInput input[disabled], .ec-numberInput input[disabled], .ec-zipInput input[disabled], .ec-telInput input[disabled], .ec-select input[disabled], .ec-birth input[disabled], .ec-input input[readonly], .ec-halfInput input[readonly], .ec-numberInput input[readonly], .ec-zipInput input[readonly], .ec-telInput input[readonly], .ec-select input[readonly], .ec-birth input[readonly],
  fieldset[disabled] .ec-input input,
  fieldset[disabled] .ec-halfInput input,
  fieldset[disabled] .ec-numberInput input,
  fieldset[disabled] .ec-zipInput input,
  fieldset[disabled] .ec-telInput input,
  fieldset[disabled] .ec-select input,
  fieldset[disabled] .ec-birth input {
    background-color: #eeeeee;
    opacity: 1; }
  .ec-input input[disabled], .ec-halfInput input[disabled], .ec-numberInput input[disabled], .ec-zipInput input[disabled], .ec-telInput input[disabled], .ec-select input[disabled], .ec-birth input[disabled],
  fieldset[disabled] .ec-input input,
  fieldset[disabled] .ec-halfInput input,
  fieldset[disabled] .ec-numberInput input,
  fieldset[disabled] .ec-zipInput input,
  fieldset[disabled] .ec-telInput input,
  fieldset[disabled] .ec-select input,
  fieldset[disabled] .ec-birth input {
    cursor: not-allowed; }

.ec-input select, .ec-halfInput select, .ec-numberInput select, .ec-zipInput select, .ec-telInput select, .ec-select select, .ec-birth select {
  display: block;
  width: 100%;
  height: 34px;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857;
  color: #555555;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  -webkit-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  -o-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  border-radius: 3px; }
  .ec-input select:focus, .ec-halfInput select:focus, .ec-numberInput select:focus, .ec-zipInput select:focus, .ec-telInput select:focus, .ec-select select:focus, .ec-birth select:focus {
    border-color: #66afe9;
    outline: 0;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6); }
  .ec-input select::-moz-placeholder, .ec-halfInput select::-moz-placeholder, .ec-numberInput select::-moz-placeholder, .ec-zipInput select::-moz-placeholder, .ec-telInput select::-moz-placeholder, .ec-select select::-moz-placeholder, .ec-birth select::-moz-placeholder {
    color: #999;
    opacity: 1; }
  .ec-input select:-ms-input-placeholder, .ec-halfInput select:-ms-input-placeholder, .ec-numberInput select:-ms-input-placeholder, .ec-zipInput select:-ms-input-placeholder, .ec-telInput select:-ms-input-placeholder, .ec-select select:-ms-input-placeholder, .ec-birth select:-ms-input-placeholder {
    color: #999; }
  .ec-input select::-webkit-input-placeholder, .ec-halfInput select::-webkit-input-placeholder, .ec-numberInput select::-webkit-input-placeholder, .ec-zipInput select::-webkit-input-placeholder, .ec-telInput select::-webkit-input-placeholder, .ec-select select::-webkit-input-placeholder, .ec-birth select::-webkit-input-placeholder {
    color: #999; }
  .ec-input select::-ms-expand, .ec-halfInput select::-ms-expand, .ec-numberInput select::-ms-expand, .ec-zipInput select::-ms-expand, .ec-telInput select::-ms-expand, .ec-select select::-ms-expand, .ec-birth select::-ms-expand {
    border: 0;
    background-color: transparent; }
  .ec-input select[disabled], .ec-halfInput select[disabled], .ec-numberInput select[disabled], .ec-zipInput select[disabled], .ec-telInput select[disabled], .ec-select select[disabled], .ec-birth select[disabled], .ec-input select[readonly], .ec-halfInput select[readonly], .ec-numberInput select[readonly], .ec-zipInput select[readonly], .ec-telInput select[readonly], .ec-select select[readonly], .ec-birth select[readonly],
  fieldset[disabled] .ec-input select,
  fieldset[disabled] .ec-halfInput select,
  fieldset[disabled] .ec-numberInput select,
  fieldset[disabled] .ec-zipInput select,
  fieldset[disabled] .ec-telInput select,
  fieldset[disabled] .ec-select select,
  fieldset[disabled] .ec-birth select {
    background-color: #eeeeee;
    opacity: 1; }
  .ec-input select[disabled], .ec-halfInput select[disabled], .ec-numberInput select[disabled], .ec-zipInput select[disabled], .ec-telInput select[disabled], .ec-select select[disabled], .ec-birth select[disabled],
  fieldset[disabled] .ec-input select,
  fieldset[disabled] .ec-halfInput select,
  fieldset[disabled] .ec-numberInput select,
  fieldset[disabled] .ec-zipInput select,
  fieldset[disabled] .ec-telInput select,
  fieldset[disabled] .ec-select select,
  fieldset[disabled] .ec-birth select {
    cursor: not-allowed; }

.ec-input textarea, .ec-halfInput textarea, .ec-numberInput textarea, .ec-zipInput textarea, .ec-telInput textarea, .ec-select textarea, .ec-birth textarea {
  display: block;
  width: 100%;
  height: 34px;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857;
  color: #555555;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  -webkit-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  -o-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  border-radius: 3px; }
  .ec-input textarea:focus, .ec-halfInput textarea:focus, .ec-numberInput textarea:focus, .ec-zipInput textarea:focus, .ec-telInput textarea:focus, .ec-select textarea:focus, .ec-birth textarea:focus {
    border-color: #66afe9;
    outline: 0;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6); }
  .ec-input textarea::-moz-placeholder, .ec-halfInput textarea::-moz-placeholder, .ec-numberInput textarea::-moz-placeholder, .ec-zipInput textarea::-moz-placeholder, .ec-telInput textarea::-moz-placeholder, .ec-select textarea::-moz-placeholder, .ec-birth textarea::-moz-placeholder {
    color: #999;
    opacity: 1; }
  .ec-input textarea:-ms-input-placeholder, .ec-halfInput textarea:-ms-input-placeholder, .ec-numberInput textarea:-ms-input-placeholder, .ec-zipInput textarea:-ms-input-placeholder, .ec-telInput textarea:-ms-input-placeholder, .ec-select textarea:-ms-input-placeholder, .ec-birth textarea:-ms-input-placeholder {
    color: #999; }
  .ec-input textarea::-webkit-input-placeholder, .ec-halfInput textarea::-webkit-input-placeholder, .ec-numberInput textarea::-webkit-input-placeholder, .ec-zipInput textarea::-webkit-input-placeholder, .ec-telInput textarea::-webkit-input-placeholder, .ec-select textarea::-webkit-input-placeholder, .ec-birth textarea::-webkit-input-placeholder {
    color: #999; }
  .ec-input textarea::-ms-expand, .ec-halfInput textarea::-ms-expand, .ec-numberInput textarea::-ms-expand, .ec-zipInput textarea::-ms-expand, .ec-telInput textarea::-ms-expand, .ec-select textarea::-ms-expand, .ec-birth textarea::-ms-expand {
    border: 0;
    background-color: transparent; }
  .ec-input textarea[disabled], .ec-halfInput textarea[disabled], .ec-numberInput textarea[disabled], .ec-zipInput textarea[disabled], .ec-telInput textarea[disabled], .ec-select textarea[disabled], .ec-birth textarea[disabled], .ec-input textarea[readonly], .ec-halfInput textarea[readonly], .ec-numberInput textarea[readonly], .ec-zipInput textarea[readonly], .ec-telInput textarea[readonly], .ec-select textarea[readonly], .ec-birth textarea[readonly],
  fieldset[disabled] .ec-input textarea,
  fieldset[disabled] .ec-halfInput textarea,
  fieldset[disabled] .ec-numberInput textarea,
  fieldset[disabled] .ec-zipInput textarea,
  fieldset[disabled] .ec-telInput textarea,
  fieldset[disabled] .ec-select textarea,
  fieldset[disabled] .ec-birth textarea {
    background-color: #eeeeee;
    opacity: 1; }
  .ec-input textarea[disabled], .ec-halfInput textarea[disabled], .ec-numberInput textarea[disabled], .ec-zipInput textarea[disabled], .ec-telInput textarea[disabled], .ec-select textarea[disabled], .ec-birth textarea[disabled],
  fieldset[disabled] .ec-input textarea,
  fieldset[disabled] .ec-halfInput textarea,
  fieldset[disabled] .ec-numberInput textarea,
  fieldset[disabled] .ec-zipInput textarea,
  fieldset[disabled] .ec-telInput textarea,
  fieldset[disabled] .ec-select textarea,
  fieldset[disabled] .ec-birth textarea {
    cursor: not-allowed; }

.ec-input input:focus, .ec-halfInput input:focus, .ec-numberInput input:focus, .ec-zipInput input:focus, .ec-telInput input:focus, .ec-select input:focus, .ec-birth input:focus {
  box-shadow: none;
  border-color: #3c8dbc; }

.ec-input input, .ec-halfInput input, .ec-numberInput input, .ec-zipInput input, .ec-telInput input, .ec-select input, .ec-birth input {
  margin-bottom: 16px;
  height: 40px;
  font-family: Roboto, "游ゴシック", YuGothic, "Yu Gothic", "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", Arial, "メイリオ", Meiryo, sans-serif; }

.ec-input p, .ec-halfInput p, .ec-numberInput p, .ec-zipInput p, .ec-telInput p, .ec-select p, .ec-birth p {
  line-height: 1.4; }

.ec-input .errorMail, .ec-halfInput .errorMail, .ec-numberInput .errorMail, .ec-zipInput .errorMail, .ec-telInput .errorMail, .ec-select .errorMail, .ec-birth .errorMail {
  display: none; }

.ec-input .errorPassword, .ec-halfInput .errorPassword, .ec-numberInput .errorPassword, .ec-zipInput .errorPassword, .ec-telInput .errorPassword, .ec-select .errorPassword, .ec-birth .errorPassword {
  display: none; }

.ec-input .errorPassword2, .ec-halfInput .errorPassword2, .ec-numberInput .errorPassword2, .ec-zipInput .errorPassword2, .ec-telInput .errorPassword2, .ec-select .errorPassword2, .ec-birth .errorPassword2 {
  display: none; }

.error.ec-input input, .error.ec-halfInput input, .error.ec-numberInput input, .error.ec-zipInput input, .error.ec-telInput input, .error.ec-select input, .error.ec-birth input, .error.ec-input select, .error.ec-halfInput select, .error.ec-numberInput select, .error.ec-zipInput select, .error.ec-telInput select, .error.ec-select select, .error.ec-birth select {
  margin-bottom: 5px;
  border-color: #CF3F34;
  background: #FDF1F0; }

.error.ec-input .errorMail, .error.ec-halfInput .errorMail, .error.ec-numberInput .errorMail, .error.ec-zipInput .errorMail, .error.ec-telInput .errorMail, .error.ec-select .errorMail, .error.ec-birth .errorMail {
  display: block;
  margin-bottom: 25px;
  font-size: 12px;
  font-weight: bold;
  color: #DE5D50; }

.error.ec-input .errorPassword, .error.ec-halfInput .errorPassword, .error.ec-numberInput .errorPassword, .error.ec-zipInput .errorPassword, .error.ec-telInput .errorPassword, .error.ec-select .errorPassword, .error.ec-birth .errorPassword {
  display: block;
  margin-bottom: 25px;
  font-size: 12px;
  font-weight: bold;
  color: #DE5D50; }

.error.ec-input .errorPassword2, .error.ec-halfInput .errorPassword2, .error.ec-numberInput .errorPassword2, .error.ec-zipInput .errorPassword2, .error.ec-telInput .errorPassword2, .error.ec-select .errorPassword2, .error.ec-birth .errorPassword2 {
  display: block;
  margin-bottom: 25px;
  font-size: 12px;
  font-weight: bold;
  color: #DE5D50; }

/*
フォーム（text２つ）

姓名など2つ入力させたい入力項目で使用します。

入力フォームを半分で用意したいときにも利用可能です。

ex [会員情報編集画面　フォーム部分](http://demo3.ec-cube.net/mypage/change)

Markup:
p.ec-halfInput
  input(type="text")
  input(type="text")
p.ec-halfInput
  input(type="text")

Styleguide 3.1.2
*/
.ec-halfInput input[type='text'] {
  display: inline-block;
  width: 49%;
  margin-left: 0; }
  @media only screen and (min-width: 768px) {
    .ec-halfInput input[type='text'] {
      margin-left: 15px;
      width: 45%; } }

/*
数量ボタン

数量を表示するための小さなコンポーネントです。

数値表示に最適化するため、数字は右端揃えで表示されます。

ex [商品詳細画面　数量ボタン部分](http://demo3.ec-cube.net/products/detail/27)

Markup:
.ec-numberInput
  span 数量
  input(type="number",value="0")

Styleguide 3.1.3
*/
.ec-numberInput input[type='number'] {
  display: inline-block;
  width: auto;
  max-width: 100px;
  text-align: right; }

/*
郵便番号フォーム

数量を表示するための小さなコンポーネントです。

内部に input 要素を配置してコーディングします。

ex [会員情報編集画面　郵便番号部分](http://demo3.ec-cube.net/mypage/change)

Markup:
.ec-zipInput
  span 〒
  input(type="text")
  span -
  input(type="text")
.ec-zipInputHelp
  a(href="http://www.post.japanpost.jp/zipcode/" target="_blank")
    .ec-zipInputHelp__icon
      .ec-icon
        svg(viewbox='0 0 360 360')
          path(d='M303,116.1c0,9.563-1.377,18.506-4.126,26.828c-2.755,8.325-5.856,15.1-9.317,20.32   c-3.462,5.225-8.345,10.493-14.643,15.804c-6.301,5.313-11.404,9.163-15.306,11.555c-3.906,2.392-9.317,5.537-16.238,9.431   c-7.279,4.075-13.355,9.828-18.235,17.266c-4.883,7.438-7.32,13.373-7.32,17.798c0,3.013-1.065,5.889-3.195,8.632   c-2.13,2.748-4.616,4.116-7.453,4.116h-63.889c-2.663,0-4.925-1.636-6.788-4.913c-1.864-3.274-2.795-6.595-2.795-9.961v-11.953   c0-14.697,5.765-28.556,17.302-41.571c11.535-13.015,24.225-22.621,38.068-28.82c10.47-4.781,17.923-9.737,22.361-14.875   c4.435-5.134,6.654-11.862,6.654-20.188c0-7.438-4.125-13.987-12.378-19.656c-8.252-5.666-17.793-8.5-28.617-8.5   c-11.538,0-21.121,2.569-28.75,7.703c-6.214,4.428-15.707,14.609-28.484,30.547c-2.309,2.834-5.057,4.25-8.251,4.25   c-2.131,0-4.352-0.706-6.656-2.125L61.286,94.584c-2.309-1.769-3.686-3.984-4.127-6.641c-0.444-2.656,0.042-5.134,1.465-7.438   C87.016,33.403,128.191,9.85,182.143,9.85c14.196,0,28.482,2.748,42.859,8.234c14.375,5.491,27.327,12.841,38.865,22.047   c11.534,9.209,20.939,20.499,28.218,33.867C299.361,87.37,303,101.403,303,116.1z M218.879,275.475v63.751   c0,2.833-1.064,5.311-3.194,7.438c-2.13,2.124-4.618,3.187-7.454,3.187h-63.889c-2.841,0-5.324-1.063-7.454-3.187   c-2.13-2.127-3.194-4.604-3.194-7.438v-63.751c0-2.832,1.064-5.313,3.194-7.438c2.13-2.126,4.613-3.187,7.454-3.187h63.889   c2.836,0,5.324,1.061,7.454,3.187C217.815,270.162,218.879,272.643,218.879,275.475z')
    span 郵便番号検索
.ec-zipAuto
  a.ec-inlineBtn 郵便番号から自動入力

Styleguide 3.1.4
*/
.ec-zipInput {
  display: inline-block; }
  .ec-zipInput input {
    display: inline-block;
    width: auto;
    text-align: right;
    max-width: 4em;
    font-size: 16px; }
    @media only screen and (min-width: 768px) {
      .ec-zipInput input {
        max-width: 5em; } }
  .ec-zipInput span {
    display: inline-block;
    padding: 0 5px 0 3px;
    margin-left: 5px; }

.ec-zipInputHelp {
  display: inline-block;
  margin-left: 10px;
  margin-bottom: 16px;
  vertical-align: baseline;
  line-height: 0; }
  .ec-zipInputHelp .ec-zipInputHelp__icon {
    display: inline-block;
    margin-top: -10px;
    width: 20px;
    height: 20px;
    background: #525263;
    border-radius: 50%;
    font-size: 13px;
    position: relative;
    top: -6px; }
    .ec-zipInputHelp .ec-zipInputHelp__icon svg {
      fill: #fff;
      position: relative;
      left: 3px;
      top: 2px; }
  .ec-zipInputHelp span {
    margin-left: 8px;
    display: inline-block; }

.ec-zipAuto {
  margin-bottom: 16px; }
  .ec-zipAuto .ec-inlineBtn {
    font-weight: normal; }

/*
電話番号ボタン

数量を表示するための小さなコンポーネントです。

内部に input 要素を配置してコーディングします。

ex [会員情報編集画面　電話番号部分](http://demo3.ec-cube.net/mypage/change)

Markup:
.ec-telInput
  input(type="text")
  span -
  input(type="text")
  span -
  input(type="text")

Styleguide 3.1.5
*/
.ec-telInput input {
  display: inline-block;
  width: auto;
  font-size: 16px;
  text-align: right;
  max-width: 5em; }

.ec-telInput span {
  display: inline-block;
  padding: 0 5px 0 3px;
  margin-left: 5px; }

/**
 * ECCUBE 固有のスタイルユーティリティ
 */
/**
メディアクエリ
SP フォーストで記述する。
Twitter Bootstrap デフォルト準拠
 */
/*
フォーム部品(テキスト)

テキスト入力項目に関する要素を定義します。

sg-wrapper:
<div class="ec-role">
  <sg-wrapper-content/>


Styleguide 3.1
*/
/*
フォーム

`.ec-input` 要素は全ての入力項目に関する標準的なコンポーネントクラスです。


ex [会員情報編集画面　フォーム部分](http://demo3.ec-cube.net/mypage/change)

Markup:
p.ec-input
  input(type="number")
p.ec-input
  textarea(rows="6")

Styleguide 3.1.1
*/
.ec-input input[type="search"], .ec-halfInput input[type="search"], .ec-numberInput input[type="search"], .ec-zipInput input[type="search"], .ec-telInput input[type="search"], .ec-select input[type="search"], .ec-birth input[type="search"] {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

.ec-input input[type="radio"], .ec-halfInput input[type="radio"], .ec-numberInput input[type="radio"], .ec-zipInput input[type="radio"], .ec-telInput input[type="radio"], .ec-select input[type="radio"], .ec-birth input[type="radio"],
.ec-input input[type="checkbox"], .ec-halfInput input[type="checkbox"], .ec-numberInput input[type="checkbox"], .ec-zipInput input[type="checkbox"], .ec-telInput input[type="checkbox"], .ec-select input[type="checkbox"], .ec-birth input[type="checkbox"] {
  margin: 4px 0 0;
  margin-top: 1px \9;
  line-height: normal; }

.ec-input input[type="file"], .ec-halfInput input[type="file"], .ec-numberInput input[type="file"], .ec-zipInput input[type="file"], .ec-telInput input[type="file"], .ec-select input[type="file"], .ec-birth input[type="file"] {
  display: block; }

.ec-input input[type="range"], .ec-halfInput input[type="range"], .ec-numberInput input[type="range"], .ec-zipInput input[type="range"], .ec-telInput input[type="range"], .ec-select input[type="range"], .ec-birth input[type="range"] {
  display: block;
  width: 100%; }

.ec-input select[multiple], .ec-halfInput select[multiple], .ec-numberInput select[multiple], .ec-zipInput select[multiple], .ec-telInput select[multiple], .ec-select select[multiple], .ec-birth select[multiple],
.ec-input select[size], .ec-halfInput select[size], .ec-numberInput select[size], .ec-zipInput select[size], .ec-telInput select[size], .ec-select select[size], .ec-birth select[size] {
  height: auto; }

.ec-input input[type="file"]:focus, .ec-halfInput input[type="file"]:focus, .ec-numberInput input[type="file"]:focus, .ec-zipInput input[type="file"]:focus, .ec-telInput input[type="file"]:focus, .ec-select input[type="file"]:focus, .ec-birth input[type="file"]:focus,
.ec-input input[type="radio"]:focus, .ec-halfInput input[type="radio"]:focus, .ec-numberInput input[type="radio"]:focus, .ec-zipInput input[type="radio"]:focus, .ec-telInput input[type="radio"]:focus, .ec-select input[type="radio"]:focus, .ec-birth input[type="radio"]:focus,
.ec-input input[type="checkbox"]:focus, .ec-halfInput input[type="checkbox"]:focus, .ec-numberInput input[type="checkbox"]:focus, .ec-zipInput input[type="checkbox"]:focus, .ec-telInput input[type="checkbox"]:focus, .ec-select input[type="checkbox"]:focus, .ec-birth input[type="checkbox"]:focus {
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px; }

.ec-input input, .ec-halfInput input, .ec-numberInput input, .ec-zipInput input, .ec-telInput input, .ec-select input, .ec-birth input {
  display: block;
  width: 100%;
  height: 34px;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857;
  color: #555555;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  -webkit-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  -o-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  border-radius: 3px; }
  .ec-input input:focus, .ec-halfInput input:focus, .ec-numberInput input:focus, .ec-zipInput input:focus, .ec-telInput input:focus, .ec-select input:focus, .ec-birth input:focus {
    border-color: #66afe9;
    outline: 0;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6); }
  .ec-input input::-moz-placeholder, .ec-halfInput input::-moz-placeholder, .ec-numberInput input::-moz-placeholder, .ec-zipInput input::-moz-placeholder, .ec-telInput input::-moz-placeholder, .ec-select input::-moz-placeholder, .ec-birth input::-moz-placeholder {
    color: #999;
    opacity: 1; }
  .ec-input input:-ms-input-placeholder, .ec-halfInput input:-ms-input-placeholder, .ec-numberInput input:-ms-input-placeholder, .ec-zipInput input:-ms-input-placeholder, .ec-telInput input:-ms-input-placeholder, .ec-select input:-ms-input-placeholder, .ec-birth input:-ms-input-placeholder {
    color: #999; }
  .ec-input input::-webkit-input-placeholder, .ec-halfInput input::-webkit-input-placeholder, .ec-numberInput input::-webkit-input-placeholder, .ec-zipInput input::-webkit-input-placeholder, .ec-telInput input::-webkit-input-placeholder, .ec-select input::-webkit-input-placeholder, .ec-birth input::-webkit-input-placeholder {
    color: #999; }
  .ec-input input::-ms-expand, .ec-halfInput input::-ms-expand, .ec-numberInput input::-ms-expand, .ec-zipInput input::-ms-expand, .ec-telInput input::-ms-expand, .ec-select input::-ms-expand, .ec-birth input::-ms-expand {
    border: 0;
    background-color: transparent; }
  .ec-input input[disabled], .ec-halfInput input[disabled], .ec-numberInput input[disabled], .ec-zipInput input[disabled], .ec-telInput input[disabled], .ec-select input[disabled], .ec-birth input[disabled], .ec-input input[readonly], .ec-halfInput input[readonly], .ec-numberInput input[readonly], .ec-zipInput input[readonly], .ec-telInput input[readonly], .ec-select input[readonly], .ec-birth input[readonly],
  fieldset[disabled] .ec-input input,
  fieldset[disabled] .ec-halfInput input,
  fieldset[disabled] .ec-numberInput input,
  fieldset[disabled] .ec-zipInput input,
  fieldset[disabled] .ec-telInput input,
  fieldset[disabled] .ec-select input,
  fieldset[disabled] .ec-birth input {
    background-color: #eeeeee;
    opacity: 1; }
  .ec-input input[disabled], .ec-halfInput input[disabled], .ec-numberInput input[disabled], .ec-zipInput input[disabled], .ec-telInput input[disabled], .ec-select input[disabled], .ec-birth input[disabled],
  fieldset[disabled] .ec-input input,
  fieldset[disabled] .ec-halfInput input,
  fieldset[disabled] .ec-numberInput input,
  fieldset[disabled] .ec-zipInput input,
  fieldset[disabled] .ec-telInput input,
  fieldset[disabled] .ec-select input,
  fieldset[disabled] .ec-birth input {
    cursor: not-allowed; }

.ec-input select, .ec-halfInput select, .ec-numberInput select, .ec-zipInput select, .ec-telInput select, .ec-select select, .ec-birth select {
  display: block;
  width: 100%;
  height: 34px;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857;
  color: #555555;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  -webkit-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  -o-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  border-radius: 3px; }
  .ec-input select:focus, .ec-halfInput select:focus, .ec-numberInput select:focus, .ec-zipInput select:focus, .ec-telInput select:focus, .ec-select select:focus, .ec-birth select:focus {
    border-color: #66afe9;
    outline: 0;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6); }
  .ec-input select::-moz-placeholder, .ec-halfInput select::-moz-placeholder, .ec-numberInput select::-moz-placeholder, .ec-zipInput select::-moz-placeholder, .ec-telInput select::-moz-placeholder, .ec-select select::-moz-placeholder, .ec-birth select::-moz-placeholder {
    color: #999;
    opacity: 1; }
  .ec-input select:-ms-input-placeholder, .ec-halfInput select:-ms-input-placeholder, .ec-numberInput select:-ms-input-placeholder, .ec-zipInput select:-ms-input-placeholder, .ec-telInput select:-ms-input-placeholder, .ec-select select:-ms-input-placeholder, .ec-birth select:-ms-input-placeholder {
    color: #999; }
  .ec-input select::-webkit-input-placeholder, .ec-halfInput select::-webkit-input-placeholder, .ec-numberInput select::-webkit-input-placeholder, .ec-zipInput select::-webkit-input-placeholder, .ec-telInput select::-webkit-input-placeholder, .ec-select select::-webkit-input-placeholder, .ec-birth select::-webkit-input-placeholder {
    color: #999; }
  .ec-input select::-ms-expand, .ec-halfInput select::-ms-expand, .ec-numberInput select::-ms-expand, .ec-zipInput select::-ms-expand, .ec-telInput select::-ms-expand, .ec-select select::-ms-expand, .ec-birth select::-ms-expand {
    border: 0;
    background-color: transparent; }
  .ec-input select[disabled], .ec-halfInput select[disabled], .ec-numberInput select[disabled], .ec-zipInput select[disabled], .ec-telInput select[disabled], .ec-select select[disabled], .ec-birth select[disabled], .ec-input select[readonly], .ec-halfInput select[readonly], .ec-numberInput select[readonly], .ec-zipInput select[readonly], .ec-telInput select[readonly], .ec-select select[readonly], .ec-birth select[readonly],
  fieldset[disabled] .ec-input select,
  fieldset[disabled] .ec-halfInput select,
  fieldset[disabled] .ec-numberInput select,
  fieldset[disabled] .ec-zipInput select,
  fieldset[disabled] .ec-telInput select,
  fieldset[disabled] .ec-select select,
  fieldset[disabled] .ec-birth select {
    background-color: #eeeeee;
    opacity: 1; }
  .ec-input select[disabled], .ec-halfInput select[disabled], .ec-numberInput select[disabled], .ec-zipInput select[disabled], .ec-telInput select[disabled], .ec-select select[disabled], .ec-birth select[disabled],
  fieldset[disabled] .ec-input select,
  fieldset[disabled] .ec-halfInput select,
  fieldset[disabled] .ec-numberInput select,
  fieldset[disabled] .ec-zipInput select,
  fieldset[disabled] .ec-telInput select,
  fieldset[disabled] .ec-select select,
  fieldset[disabled] .ec-birth select {
    cursor: not-allowed; }

.ec-input textarea, .ec-halfInput textarea, .ec-numberInput textarea, .ec-zipInput textarea, .ec-telInput textarea, .ec-select textarea, .ec-birth textarea {
  display: block;
  width: 100%;
  height: 34px;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857;
  color: #555555;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  -webkit-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  -o-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  border-radius: 3px; }
  .ec-input textarea:focus, .ec-halfInput textarea:focus, .ec-numberInput textarea:focus, .ec-zipInput textarea:focus, .ec-telInput textarea:focus, .ec-select textarea:focus, .ec-birth textarea:focus {
    border-color: #66afe9;
    outline: 0;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6); }
  .ec-input textarea::-moz-placeholder, .ec-halfInput textarea::-moz-placeholder, .ec-numberInput textarea::-moz-placeholder, .ec-zipInput textarea::-moz-placeholder, .ec-telInput textarea::-moz-placeholder, .ec-select textarea::-moz-placeholder, .ec-birth textarea::-moz-placeholder {
    color: #999;
    opacity: 1; }
  .ec-input textarea:-ms-input-placeholder, .ec-halfInput textarea:-ms-input-placeholder, .ec-numberInput textarea:-ms-input-placeholder, .ec-zipInput textarea:-ms-input-placeholder, .ec-telInput textarea:-ms-input-placeholder, .ec-select textarea:-ms-input-placeholder, .ec-birth textarea:-ms-input-placeholder {
    color: #999; }
  .ec-input textarea::-webkit-input-placeholder, .ec-halfInput textarea::-webkit-input-placeholder, .ec-numberInput textarea::-webkit-input-placeholder, .ec-zipInput textarea::-webkit-input-placeholder, .ec-telInput textarea::-webkit-input-placeholder, .ec-select textarea::-webkit-input-placeholder, .ec-birth textarea::-webkit-input-placeholder {
    color: #999; }
  .ec-input textarea::-ms-expand, .ec-halfInput textarea::-ms-expand, .ec-numberInput textarea::-ms-expand, .ec-zipInput textarea::-ms-expand, .ec-telInput textarea::-ms-expand, .ec-select textarea::-ms-expand, .ec-birth textarea::-ms-expand {
    border: 0;
    background-color: transparent; }
  .ec-input textarea[disabled], .ec-halfInput textarea[disabled], .ec-numberInput textarea[disabled], .ec-zipInput textarea[disabled], .ec-telInput textarea[disabled], .ec-select textarea[disabled], .ec-birth textarea[disabled], .ec-input textarea[readonly], .ec-halfInput textarea[readonly], .ec-numberInput textarea[readonly], .ec-zipInput textarea[readonly], .ec-telInput textarea[readonly], .ec-select textarea[readonly], .ec-birth textarea[readonly],
  fieldset[disabled] .ec-input textarea,
  fieldset[disabled] .ec-halfInput textarea,
  fieldset[disabled] .ec-numberInput textarea,
  fieldset[disabled] .ec-zipInput textarea,
  fieldset[disabled] .ec-telInput textarea,
  fieldset[disabled] .ec-select textarea,
  fieldset[disabled] .ec-birth textarea {
    background-color: #eeeeee;
    opacity: 1; }
  .ec-input textarea[disabled], .ec-halfInput textarea[disabled], .ec-numberInput textarea[disabled], .ec-zipInput textarea[disabled], .ec-telInput textarea[disabled], .ec-select textarea[disabled], .ec-birth textarea[disabled],
  fieldset[disabled] .ec-input textarea,
  fieldset[disabled] .ec-halfInput textarea,
  fieldset[disabled] .ec-numberInput textarea,
  fieldset[disabled] .ec-zipInput textarea,
  fieldset[disabled] .ec-telInput textarea,
  fieldset[disabled] .ec-select textarea,
  fieldset[disabled] .ec-birth textarea {
    cursor: not-allowed; }

.ec-input input:focus, .ec-halfInput input:focus, .ec-numberInput input:focus, .ec-zipInput input:focus, .ec-telInput input:focus, .ec-select input:focus, .ec-birth input:focus {
  box-shadow: none;
  border-color: #3c8dbc; }

.ec-input input, .ec-halfInput input, .ec-numberInput input, .ec-zipInput input, .ec-telInput input, .ec-select input, .ec-birth input {
  margin-bottom: 16px;
  height: 40px;
  font-family: Roboto, "游ゴシック", YuGothic, "Yu Gothic", "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", Arial, "メイリオ", Meiryo, sans-serif; }

.ec-input p, .ec-halfInput p, .ec-numberInput p, .ec-zipInput p, .ec-telInput p, .ec-select p, .ec-birth p {
  line-height: 1.4; }

.ec-input .errorMail, .ec-halfInput .errorMail, .ec-numberInput .errorMail, .ec-zipInput .errorMail, .ec-telInput .errorMail, .ec-select .errorMail, .ec-birth .errorMail {
  display: none; }

.ec-input .errorPassword, .ec-halfInput .errorPassword, .ec-numberInput .errorPassword, .ec-zipInput .errorPassword, .ec-telInput .errorPassword, .ec-select .errorPassword, .ec-birth .errorPassword {
  display: none; }

.ec-input .errorPassword2, .ec-halfInput .errorPassword2, .ec-numberInput .errorPassword2, .ec-zipInput .errorPassword2, .ec-telInput .errorPassword2, .ec-select .errorPassword2, .ec-birth .errorPassword2 {
  display: none; }

.error.ec-input input, .error.ec-halfInput input, .error.ec-numberInput input, .error.ec-zipInput input, .error.ec-telInput input, .error.ec-select input, .error.ec-birth input, .error.ec-input select, .error.ec-halfInput select, .error.ec-numberInput select, .error.ec-zipInput select, .error.ec-telInput select, .error.ec-select select, .error.ec-birth select {
  margin-bottom: 5px;
  border-color: #CF3F34;
  background: #FDF1F0; }

.error.ec-input .errorMail, .error.ec-halfInput .errorMail, .error.ec-numberInput .errorMail, .error.ec-zipInput .errorMail, .error.ec-telInput .errorMail, .error.ec-select .errorMail, .error.ec-birth .errorMail {
  display: block;
  margin-bottom: 25px;
  font-size: 12px;
  font-weight: bold;
  color: #DE5D50; }

.error.ec-input .errorPassword, .error.ec-halfInput .errorPassword, .error.ec-numberInput .errorPassword, .error.ec-zipInput .errorPassword, .error.ec-telInput .errorPassword, .error.ec-select .errorPassword, .error.ec-birth .errorPassword {
  display: block;
  margin-bottom: 25px;
  font-size: 12px;
  font-weight: bold;
  color: #DE5D50; }

.error.ec-input .errorPassword2, .error.ec-halfInput .errorPassword2, .error.ec-numberInput .errorPassword2, .error.ec-zipInput .errorPassword2, .error.ec-telInput .errorPassword2, .error.ec-select .errorPassword2, .error.ec-birth .errorPassword2 {
  display: block;
  margin-bottom: 25px;
  font-size: 12px;
  font-weight: bold;
  color: #DE5D50; }

/*
フォーム（text２つ）

姓名など2つ入力させたい入力項目で使用します。

入力フォームを半分で用意したいときにも利用可能です。

ex [会員情報編集画面　フォーム部分](http://demo3.ec-cube.net/mypage/change)

Markup:
p.ec-halfInput
  input(type="text")
  input(type="text")
p.ec-halfInput
  input(type="text")

Styleguide 3.1.2
*/
.ec-halfInput input[type='text'] {
  display: inline-block;
  width: 49%;
  margin-left: 0; }
  @media only screen and (min-width: 768px) {
    .ec-halfInput input[type='text'] {
      margin-left: 15px;
      width: 45%; } }

/*
数量ボタン

数量を表示するための小さなコンポーネントです。

数値表示に最適化するため、数字は右端揃えで表示されます。

ex [商品詳細画面　数量ボタン部分](http://demo3.ec-cube.net/products/detail/27)

Markup:
.ec-numberInput
  span 数量
  input(type="number",value="0")

Styleguide 3.1.3
*/
.ec-numberInput input[type='number'] {
  display: inline-block;
  width: auto;
  max-width: 100px;
  text-align: right; }

/*
郵便番号フォーム

数量を表示するための小さなコンポーネントです。

内部に input 要素を配置してコーディングします。

ex [会員情報編集画面　郵便番号部分](http://demo3.ec-cube.net/mypage/change)

Markup:
.ec-zipInput
  span 〒
  input(type="text")
  span -
  input(type="text")
.ec-zipInputHelp
  a(href="http://www.post.japanpost.jp/zipcode/" target="_blank")
    .ec-zipInputHelp__icon
      .ec-icon
        svg(viewbox='0 0 360 360')
          path(d='M303,116.1c0,9.563-1.377,18.506-4.126,26.828c-2.755,8.325-5.856,15.1-9.317,20.32   c-3.462,5.225-8.345,10.493-14.643,15.804c-6.301,5.313-11.404,9.163-15.306,11.555c-3.906,2.392-9.317,5.537-16.238,9.431   c-7.279,4.075-13.355,9.828-18.235,17.266c-4.883,7.438-7.32,13.373-7.32,17.798c0,3.013-1.065,5.889-3.195,8.632   c-2.13,2.748-4.616,4.116-7.453,4.116h-63.889c-2.663,0-4.925-1.636-6.788-4.913c-1.864-3.274-2.795-6.595-2.795-9.961v-11.953   c0-14.697,5.765-28.556,17.302-41.571c11.535-13.015,24.225-22.621,38.068-28.82c10.47-4.781,17.923-9.737,22.361-14.875   c4.435-5.134,6.654-11.862,6.654-20.188c0-7.438-4.125-13.987-12.378-19.656c-8.252-5.666-17.793-8.5-28.617-8.5   c-11.538,0-21.121,2.569-28.75,7.703c-6.214,4.428-15.707,14.609-28.484,30.547c-2.309,2.834-5.057,4.25-8.251,4.25   c-2.131,0-4.352-0.706-6.656-2.125L61.286,94.584c-2.309-1.769-3.686-3.984-4.127-6.641c-0.444-2.656,0.042-5.134,1.465-7.438   C87.016,33.403,128.191,9.85,182.143,9.85c14.196,0,28.482,2.748,42.859,8.234c14.375,5.491,27.327,12.841,38.865,22.047   c11.534,9.209,20.939,20.499,28.218,33.867C299.361,87.37,303,101.403,303,116.1z M218.879,275.475v63.751   c0,2.833-1.064,5.311-3.194,7.438c-2.13,2.124-4.618,3.187-7.454,3.187h-63.889c-2.841,0-5.324-1.063-7.454-3.187   c-2.13-2.127-3.194-4.604-3.194-7.438v-63.751c0-2.832,1.064-5.313,3.194-7.438c2.13-2.126,4.613-3.187,7.454-3.187h63.889   c2.836,0,5.324,1.061,7.454,3.187C217.815,270.162,218.879,272.643,218.879,275.475z')
    span 郵便番号検索
.ec-zipAuto
  a.ec-inlineBtn 郵便番号から自動入力

Styleguide 3.1.4
*/
.ec-zipInput {
  display: inline-block; }
  .ec-zipInput input {
    display: inline-block;
    width: auto;
    text-align: right;
    max-width: 4em;
    font-size: 16px; }
    @media only screen and (min-width: 768px) {
      .ec-zipInput input {
        max-width: 5em; } }
  .ec-zipInput span {
    display: inline-block;
    padding: 0 5px 0 3px;
    margin-left: 5px; }

.ec-zipInputHelp {
  display: inline-block;
  margin-left: 10px;
  margin-bottom: 16px;
  vertical-align: baseline;
  line-height: 0; }
  .ec-zipInputHelp .ec-zipInputHelp__icon {
    display: inline-block;
    margin-top: -10px;
    width: 20px;
    height: 20px;
    background: #525263;
    border-radius: 50%;
    font-size: 13px;
    position: relative;
    top: -6px; }
    .ec-zipInputHelp .ec-zipInputHelp__icon svg {
      fill: #fff;
      position: relative;
      left: 3px;
      top: 2px; }
  .ec-zipInputHelp span {
    margin-left: 8px;
    display: inline-block; }

.ec-zipAuto {
  margin-bottom: 16px; }
  .ec-zipAuto .ec-inlineBtn {
    font-weight: normal; }

/*
電話番号ボタン

数量を表示するための小さなコンポーネントです。

内部に input 要素を配置してコーディングします。

ex [会員情報編集画面　電話番号部分](http://demo3.ec-cube.net/mypage/change)

Markup:
.ec-telInput
  input(type="text")
  span -
  input(type="text")
  span -
  input(type="text")

Styleguide 3.1.5
*/
.ec-telInput input {
  display: inline-block;
  width: auto;
  font-size: 16px;
  text-align: right;
  max-width: 5em; }

.ec-telInput span {
  display: inline-block;
  padding: 0 5px 0 3px;
  margin-left: 5px; }

/*
フォーム部品(その他)

テキスト以外の入力項目に関する要素を定義します。

sg-wrapper:
<div class="ec-role">
  <sg-wrapper-content/>


Styleguide 3.2
*/
/*
フォーム

全てのフォーム要素スタイリングは .ec-input 要素内で反映されます。

http://demo3.ec-cube.net/products/list

Markup:
.ec-input
  input(type="checkbox")
.ec-input
  input(type="radio")

Styleguide 3.2.1
*/
/*
ラジオ（水平）

水平に並ぶラジオボタンフィールドです。

各要素をlabelでくくって、コーディングします。

ex [新規会員登録画面　性別選択部分](http://demo3.ec-cube.net/entry)

Markup:
.ec-radio
  label
    input(type="radio")
    span 男性
  label
    input(type="radio")
    span 女性

Styleguide 3.2.2
*/
.ec-radio label {
  margin-right: 20px; }

.ec-radio input {
  margin-right: 10px;
  margin-bottom: 10px; }

.ec-radio span {
  font-weight: normal; }

/*
ラジオ(垂直)

垂直に並ぶラジオボタンフィールドです。

各要素をlabelでくくって、コーディングします。

ex [購入画面 お支払方法](http://demo3.ec-cube.net/shopping)

Markup:
.ec-blockRadio
  label
    input(type="radio")
    span 郵便振替
  label
    input(type="radio")
    span 現金書留
  label
    input(type="radio")
    span 銀行振込
  label
    input(type="radio")
    span 代金引換

Styleguide 3.2.3
*/
.ec-blockRadio label {
  display: block; }

.ec-blockRadio span {
  padding-left: 10px;
  font-weight: normal; }

/*
セレクトボックス

数量を表示するための小さなコンポーネントです。

数値表示に最適化するため、数字は右端揃えで表示されます。

ex [新規会員登録画面　都道府県選択部分](http://demo3.ec-cube.net/entry)

Markup:
.ec-select
  select
    option 都道府県を選択
    option 北海道
    option 青森県
    option 岩手県
    option ...
.ec-select
  select
    option 選択して下さい
    option 公務員
    option コンサルタント
    option コンピュータ関連技術職
    option コンピュータ関連以外の技術職
    option ...

Styleguide 3.2.4
*/
.ec-selects {
  margin-bottom: 20px;
  border-bottom: 1px dotted #ccc; }

.ec-select {
  margin-bottom: 16px; }
  .ec-select select {
    display: inline-block;
    width: auto;
    background-color: #f8f8f8; }
  .ec-select label {
    margin-right: 10px;
    font-weight: bold; }
  .ec-select label:nth-child(3) {
    margin-left: 10px;
    font-weight: bold; }

.ec-select__delivery {
  display: block;
  margin-right: 16px; }
  @media only screen and (min-width: 768px) {
    .ec-select__delivery {
      display: inline-block; } }

.ec-select__time {
  display: block; }
  @media only screen and (min-width: 768px) {
    .ec-select__time {
      display: inline-block; } }

/*
生年月日選択

数量を表示するための小さなコンポーネントです。

数値表示に最適化するため、数字は右端揃えで表示されます。

ex [新規会員登録画面　生年月日選択部分](http://demo3.ec-cube.net/entry)

Markup:
.ec-birth
  select
    option ----
    option 1960
    option 1961
    option 1962
    option ...
  span /
  select
    option --
    option 01
    option 02
    option 03
    option ...
  span /
  select
    option --
    option 01
    option 02
    option 03
    option ...

Styleguide 3.2.5
*/
.ec-birth select {
  display: inline-block;
  width: auto;
  margin: 0 0 10px;
  background-color: #f8f8f8; }
  @media only screen and (min-width: 768px) {
    .ec-birth select {
      margin: 0 8px 10px; } }

.ec-birth span {
  margin-left: 5px; }

/**
メディアクエリ
SP フォーストで記述する。
Twitter Bootstrap デフォルト準拠
 */
/*
フォームラベル

フォームのラベルに関する要素を定義します。

Styleguide 3.3
*/
/*
ラベル

フォーム要素で利用するラベル要素です。

ex [お問い合わせページ　ラベル部分](http://demo3.ec-cube.net/contact)

Markup:
.ec-borderedDefs
  dl
    dt
      label.ec-label お名前
    dd
      .ec-input
        input(type="text")

Styleguide 3.3.1
*/
.ec-label {
  display: block;
  padding: 5px 0;
  font-weight: bold;
  margin-bottom: 0; }
  @media only screen and (min-width: 768px) {
    .ec-label {
      padding: 12px;
      margin-bottom: 5px; } }

/*
必須ラベル

必須文字を表示するラベル要素です。

ex [お問い合わせページ　必須ラベル部分](http://demo3.ec-cube.net/contact)

Markup:
.ec-borderedDefs
  dl
    dt
      label.ec-label お名前
        span.ec-required 必須
    dd
      .ec-input
        input(type="text")

Styleguide 3.3.2
*/
.ec-required {
  color: #DE5D50;
  margin-left: 1em;
  font-size: 12px;
  font-weight: normal; }

/*
アイコン

デフォルトテンプレートのアイコンは SVGを使用して定義されます。

sg-wrapper:
<div class="ec-role">
  <sg-wrapper-content/>

Markup:
include /assets/tmpl/elements/4.1.icon.pug
div(style="font-size:2em")
  +icon-all


Styleguide 4.1
*/
/*
SVG アイコン

SVGを使用してアイコンを表示させます。

ex [お問い合わせページ　アイコン部分](http://demo3.ec-cube.net/contact)

Markup:
span.ec-icon
  svg(viewbox='0 0 360 360')
    circle.icon-bg(cx='180', cy='179.85', r='155')
    path.icon-with-bg(d='M208.968,226.94c-6.271-1.217-11.042-6.885-11.042-13.573c0-4.763,15.612-19.351,15.504-19.555   c16.115-16.615,24.724-43.762,24.724-64.632c0-32.317-26.04-58.658-58.152-58.658c-32.114,0-58.153,26.341-58.153,58.658   c0,20.969,8.41,48.323,24.614,64.939c0,0,15.612,14.484,15.612,19.247c0,7.089-5.273,12.966-12.169,13.776   c-34.33,3.646-54.8,14.385-70.708,35.156c-4.561,5.976-7.093,18.03-7.193,24.516l-0.014,4.19   c27.915,27.13,66.008,43.845,108.011,43.845c42.044,0,80.171-16.748,108.094-43.927v-4.108c-0.197-6.485-2.729-18.54-7.292-24.516   C264.687,241.325,244.031,230.488,208.968,226.94z')
  span ユーザ
span.ec-icon
  svg(viewbox='0 0 360 360')
    circle.icon-bg(cx='180', cy='179.85', r='155')
    path.icon-with-bg(d='M281.438,160.48c0,16.617-8.61,33.533-25.824,50.747l-70.256,67.662c-1.354,1.354-3.008,2.03-4.962,2.03    c-1.956,0-3.609-0.677-4.962-2.03l-70.369-67.888c-0.752-0.601-1.786-1.579-3.101-2.932c-1.316-1.353-3.402-3.814-6.259-7.387    c-2.858-3.57-5.413-7.234-7.668-10.995c-2.256-3.759-4.269-8.306-6.034-13.645c-1.768-5.338-2.649-10.525-2.649-15.563    c0-16.538,4.772-29.47,14.321-38.793c9.547-9.321,22.74-13.983,39.582-13.983c4.661,0,9.417,0.809,14.266,2.425    c4.85,1.617,9.36,3.797,13.533,6.541c4.172,2.745,7.762,5.32,10.77,7.725c3.005,2.407,5.864,4.961,8.57,7.668    c2.706-2.707,5.563-5.262,8.57-7.668c3.006-2.405,6.597-4.98,10.77-7.725c4.172-2.743,8.684-4.923,13.532-6.541    c4.85-1.616,9.604-2.425,14.266-2.425c16.84,0,30.033,4.663,39.582,13.983C276.663,131.011,281.438,143.943,281.438,160.48z     M267.003,160.48c0-6.089-0.81-11.463-2.425-16.125c-1.618-4.661-3.685-8.363-6.202-11.108c-2.521-2.744-5.582-4.98-9.19-6.71    c-3.608-1.729-7.145-2.893-10.601-3.496c-3.459-0.601-7.144-0.902-11.052-0.902c-3.91,0-8.12,0.958-12.63,2.875    c-4.511,1.917-8.666,4.324-12.462,7.217c-3.797,2.895-7.047,5.602-9.754,8.12c-2.707,2.52-4.962,4.832-6.766,6.935    c-1.354,1.655-3.197,2.481-5.526,2.481c-2.331,0-4.173-0.826-5.525-2.481c-1.805-2.104-4.061-4.416-6.768-6.935    c-2.705-2.518-5.959-5.225-9.754-8.12c-3.798-2.894-7.95-5.3-12.461-7.217c-4.511-1.917-8.722-2.875-12.631-2.875    s-7.594,0.301-11.051,0.902c-3.46,0.603-6.992,1.767-10.601,3.496c-3.608,1.73-6.674,3.966-9.19,6.71    c-2.52,2.745-4.588,6.447-6.203,11.108c-1.617,4.663-2.424,10.036-2.424,16.125c0,12.63,7.029,25.976,21.088,40.034l65.52,63.151    l65.406-63.039C259.935,186.494,267.003,173.111,267.003,160.48z')
  span お気に入り
span.ec-icon
  svg(viewbox='0 0 360 360')
    circle.icon-bg(cx='180', cy='179.85', r='155')
    path.icon-with-bg(d='M253.204,176.911v73.204c0,3.391-1.188,6.271-3.557,8.644c-2.373,2.373-5.256,3.558-8.645,3.558H118.997    c-3.391,0-6.271-1.185-8.643-3.558c-2.373-2.372-3.559-5.253-3.559-8.644v-73.204c0-3.388,1.186-6.27,3.559-8.642    c2.371-2.371,5.252-3.559,8.643-3.559h4.066v-24.401c0-15.589,5.592-28.977,16.775-40.161    c11.186-11.184,24.57-16.776,40.162-16.776c15.588,0,28.977,5.592,40.16,16.776c11.184,11.185,16.775,24.572,16.775,40.161v24.401    h4.066c3.389,0,6.271,1.188,8.645,3.559C252.016,170.641,253.204,173.523,253.204,176.911z M147.463,164.71h65.072v-24.401    c0-8.98-3.178-16.649-9.533-23.003c-6.354-6.355-14.023-9.532-23.002-9.532c-8.982,0-16.65,3.177-23.004,9.532    c-6.355,6.354-9.533,14.023-9.533,23.003V164.71z')
  span ログイン

Styleguide 4.1.1
*/
.ec-icon svg, .ec-icon--colored svg {
  overflow: hidden;
  fill: #B8BEC4;
  margin-right: 5px;
  position: relative;
  top: -1px;
  display: inline-block;
  width: 1em;
  height: 1em;
  vertical-align: middle; }
  .ec-icon svg .icon-with-bg, .ec-icon--colored svg .icon-with-bg {
    fill: #FFF; }

.ec-icon span, .ec-icon--colored span {
  font-size: .5em; }

/*
SVG アイコン(サイズ)

アイコンのサイズは 1em で設定されているため、
フォントサイズを修正したブロック内で利用することで自由にサイズ調整を行うことが可能です。

ex [お問い合わせページ　アイコン部分](http://demo3.ec-cube.net/contact)

Markup:
div(style="font-size:70px;text-align:center")
  .ec-icon
    svg(viewbox='0 0 360 360')
      circle.icon-bg(cx='180', cy='179.85', r='155')
      path.icon-with-bg(d='M208.968,226.94c-6.271-1.217-11.042-6.885-11.042-13.573c0-4.763,15.612-19.351,15.504-19.555   c16.115-16.615,24.724-43.762,24.724-64.632c0-32.317-26.04-58.658-58.152-58.658c-32.114,0-58.153,26.341-58.153,58.658   c0,20.969,8.41,48.323,24.614,64.939c0,0,15.612,14.484,15.612,19.247c0,7.089-5.273,12.966-12.169,13.776   c-34.33,3.646-54.8,14.385-70.708,35.156c-4.561,5.976-7.093,18.03-7.193,24.516l-0.014,4.19   c27.915,27.13,66.008,43.845,108.011,43.845c42.044,0,80.171-16.748,108.094-43.927v-4.108c-0.197-6.485-2.729-18.54-7.292-24.516   C264.687,241.325,244.031,230.488,208.968,226.94z')
div(style="font-size:100px;text-align:center")
  .ec-icon
    svg(viewbox='0 0 360 360')
      path(d='M201.227,34.574l145.551,267.386c4.42,7.975,4.293,15.952-0.379,23.927c-2.151,3.671-5.089,6.582-8.814,8.735   c-3.728,2.152-7.74,3.229-12.033,3.229H34.45c-4.296,0-8.309-1.076-12.035-3.229c-3.728-2.153-6.666-5.064-8.813-8.735   c-4.673-7.975-4.8-15.952-0.379-23.927l145.55-267.386c2.146-3.922,5.117-7.026,8.907-9.305c3.79-2.279,7.895-3.418,12.319-3.418   c4.421,0,8.528,1.14,12.317,3.418C196.11,27.547,199.077,30.651,201.227,34.574z M203.88,211.945l3.41-87.167   c0-1.519-0.633-2.72-1.895-3.608c-1.644-1.392-3.159-2.089-4.548-2.089h-41.695c-1.392,0-2.908,0.697-4.549,2.089   c-1.264,0.888-1.895,2.217-1.895,3.988l3.222,86.787c0,1.266,0.631,2.311,1.895,3.132c1.262,0.826,2.778,1.236,4.548,1.236h35.061   c1.769,0,3.251-0.41,4.454-1.236C203.089,214.255,203.752,213.21,203.88,211.945z M204.258,282.968v-36.082   c0-1.772-0.601-3.259-1.8-4.462c-1.203-1.203-2.623-1.806-4.265-1.806h-36.387c-1.644,0-3.065,0.603-4.265,1.806   c-1.203,1.203-1.801,2.689-1.801,4.462v36.082c0,1.775,0.598,3.261,1.801,4.463c1.199,1.205,2.621,1.804,4.265,1.804h36.387   c1.642,0,3.062-0.599,4.265-1.804C203.658,286.229,204.258,284.744,204.258,282.968z')

Styleguide 4.1.2
*/
/*
SVG アイコン(色)

ec-icon--colored クラスは

アイコンのサイズは 1em で設定されているため、
フォントサイズを修正したブロック内で利用することで自由にサイズ調整を行うことが可能です。

ex [お問い合わせページ　色付きアイコン部分](http://demo3.ec-cube.net/contact)

Markup:
div(style="color:#DE5D50;font-size:70px;text-align:center")
  .ec-icon--colored
    svg(viewbox='0 0 360 360')
      circle.icon-bg(cx='180', cy='179.85', r='155')
      path.icon-with-bg(d='M208.968,226.94c-6.271-1.217-11.042-6.885-11.042-13.573c0-4.763,15.612-19.351,15.504-19.555   c16.115-16.615,24.724-43.762,24.724-64.632c0-32.317-26.04-58.658-58.152-58.658c-32.114,0-58.153,26.341-58.153,58.658   c0,20.969,8.41,48.323,24.614,64.939c0,0,15.612,14.484,15.612,19.247c0,7.089-5.273,12.966-12.169,13.776   c-34.33,3.646-54.8,14.385-70.708,35.156c-4.561,5.976-7.093,18.03-7.193,24.516l-0.014,4.19   c27.915,27.13,66.008,43.845,108.011,43.845c42.044,0,80.171-16.748,108.094-43.927v-4.108c-0.197-6.485-2.729-18.54-7.292-24.516   C264.687,241.325,244.031,230.488,208.968,226.94z')
div(style="color:#9a947e;font-size:70px;text-align:center")
  .ec-icon--colored
    svg(viewbox='0 0 360 360')
      path(d='M201.227,34.574l145.551,267.386c4.42,7.975,4.293,15.952-0.379,23.927c-2.151,3.671-5.089,6.582-8.814,8.735   c-3.728,2.152-7.74,3.229-12.033,3.229H34.45c-4.296,0-8.309-1.076-12.035-3.229c-3.728-2.153-6.666-5.064-8.813-8.735   c-4.673-7.975-4.8-15.952-0.379-23.927l145.55-267.386c2.146-3.922,5.117-7.026,8.907-9.305c3.79-2.279,7.895-3.418,12.319-3.418   c4.421,0,8.528,1.14,12.317,3.418C196.11,27.547,199.077,30.651,201.227,34.574z M203.88,211.945l3.41-87.167   c0-1.519-0.633-2.72-1.895-3.608c-1.644-1.392-3.159-2.089-4.548-2.089h-41.695c-1.392,0-2.908,0.697-4.549,2.089   c-1.264,0.888-1.895,2.217-1.895,3.988l3.222,86.787c0,1.266,0.631,2.311,1.895,3.132c1.262,0.826,2.778,1.236,4.548,1.236h35.061   c1.769,0,3.251-0.41,4.454-1.236C203.089,214.255,203.752,213.21,203.88,211.945z M204.258,282.968v-36.082   c0-1.772-0.601-3.259-1.8-4.462c-1.203-1.203-2.623-1.806-4.265-1.806h-36.387c-1.644,0-3.065,0.603-4.265,1.806   c-1.203,1.203-1.801,2.689-1.801,4.462v36.082c0,1.775,0.598,3.261,1.801,4.463c1.199,1.205,2.621,1.804,4.265,1.804h36.387   c1.642,0,3.062-0.599,4.265-1.804C203.658,286.229,204.258,284.744,204.258,282.968z')

Styleguide 4.1.3
*/
.ec-icon--colored svg {
  fill: currentColor; }

/**
メディアクエリ
SP フォーストで記述する。
Twitter Bootstrap デフォルト準拠
 */
/*
グリッド

グリッド

sg-wrapper:
<div class="ec-role">
  <sg-wrapper-content/>


Styleguide 5.1
*/
/*
2分割グリッド

画面 ２分割の　グリッドです。

Bootstrap の col-sm-6 相当のグリッドを提供します。

ex [商品詳細ページ　コンテンツ部分](http://demo3.ec-cube.net/products/detail/33)

Markup:
.ec-grid2
  .ec-grid2__cell(style="background-color: rgba(86,61,124,.15);border: 1px solid rgba(86,61,124,.2);height:50px;") .ec-grid2__cell
  .ec-grid2__cell(style="background-color: rgba(86,61,124,.15);border: 1px solid rgba(86,61,124,.2);height:50px;") .ec-grid2__cell

sg-wrapper:
<div class="ec-role">
  <sg-wrapper-content/>
</div>

Styleguide 5.1.1
*/
.ec-grid2 {
  display: block;
  margin: 0; }
  @media only screen and (min-width: 768px) {
    .ec-grid2 {
      display: flex; } }
  .ec-grid2 .ec-grid2__cell {
    position: relative;
    min-height: 1px; }
    @media (min-width: 768px) {
      .ec-grid2 .ec-grid2__cell {
        width: 50%; } }
  .ec-grid2 .ec-grid2__cell2 {
    position: relative;
    min-height: 1px; }
    @media (min-width: 768px) {
      .ec-grid2 .ec-grid2__cell2 {
        width: 100%; } }

/*
3分割グリッド

ex [トップページ　商品紹介部分](http://demo3.ec-cube.net/)

Markup:
.ec-grid3
  .ec-grid3__cell .ec-grid3__cell
  .ec-grid3__cell .ec-grid3__cell
  .ec-grid3__cell .ec-grid3__cell

Styleguide 5.1.2
*/
.ec-grid3 {
  display: block;
  margin: 0; }
  @media only screen and (min-width: 768px) {
    .ec-grid3 {
      display: flex; } }
  .ec-grid3 .ec-grid3__cell {
    position: relative;
    min-height: 1px; }
    @media (min-width: 768px) {
      .ec-grid3 .ec-grid3__cell {
        width: 33.33333%; } }
  .ec-grid3 .ec-grid3__cell2 {
    position: relative;
    min-height: 1px; }
    @media (min-width: 768px) {
      .ec-grid3 .ec-grid3__cell2 {
        width: 66.66667%; } }
  .ec-grid3 .ec-grid3__cell3 {
    position: relative;
    min-height: 1px; }
    @media (min-width: 768px) {
      .ec-grid3 .ec-grid3__cell3 {
        width: 100%; } }

/*
4分割グリッド

ex [トップページ　商品紹介部分](http://demo3.ec-cube.net/)

Markup:
.ec-grid4
  .ec-grid4__cell .ec-grid4__cell
  .ec-grid4__cell .ec-grid4__cell
  .ec-grid4__cell .ec-grid4__cell
  .ec-grid4__cell .ec-grid4__cell

Styleguide 5.1.3
*/
.ec-grid4 {
  display: block;
  margin: 0; }
  @media only screen and (min-width: 768px) {
    .ec-grid4 {
      display: flex; } }
  .ec-grid4 .ec-grid4__cell {
    position: relative;
    min-height: 1px; }
    @media (min-width: 768px) {
      .ec-grid4 .ec-grid4__cell {
        width: 25%; } }

/*
6分割グリッド

2つにまとめた cell2 や 3つをまとめた cell3 タグも使用可能です。

ex [トップページ　商品紹介部分](http://demo3.ec-cube.net/)

Markup:
.ec-grid6
  .ec-grid6__cell .ec-grid6__cell
  .ec-grid6__cell .ec-grid6__cell
  .ec-grid6__cell .ec-grid6__cell
  .ec-grid6__cell .ec-grid6__cell
  .ec-grid6__cell .ec-grid6__cell
  .ec-grid6__cell .ec-grid6__cell
.ec-grid6
  .ec-grid6__cell2 .ec-grid6__cell2
  .ec-grid6__cell2 .ec-grid6__cell2
  .ec-grid6__cell2 .ec-grid6__cell2
.ec-grid6
  .ec-grid6__cell3 .ec-grid6__cell3
  .ec-grid6__cell3 .ec-grid6__cell3

Styleguide 5.1.4
*/
.ec-grid6 {
  display: block;
  margin: 0; }
  @media only screen and (min-width: 768px) {
    .ec-grid6 {
      display: flex; } }
  .ec-grid6 .ec-grid6__cell {
    position: relative;
    min-height: 1px; }
    @media (min-width: 768px) {
      .ec-grid6 .ec-grid6__cell {
        width: 16.66667%; } }
  .ec-grid6 .ec-grid6__cell2 {
    position: relative;
    min-height: 1px; }
    @media (min-width: 768px) {
      .ec-grid6 .ec-grid6__cell2 {
        width: 33.33333%; } }
  .ec-grid6 .ec-grid6__cell3 {
    position: relative;
    min-height: 1px; }
    @media (min-width: 768px) {
      .ec-grid6 .ec-grid6__cell3 {
        width: 50%; } }

/*
全幅 内寄せグリッド 10/12

ex [ご利用規約ページ　本文](http://demo3.ec-cube.net/help/agreement)

Markup:
.ec-off1Grid
  .ec-off1Grid__cell Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
  .ec-off1Grid__cell Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod


Styleguide 5.1.5
*/
.ec-off1Grid {
  margin: 0; }
  @media only screen and (min-width: 768px) {
    .ec-off1Grid {
      display: block;
      margin: 0; } }
  @media only screen and (min-width: 768px) and (min-width: 768px) {
    .ec-off1Grid {
      display: flex; } }
  .ec-off1Grid .ec-off1Grid__cell {
    margin: 0; }
    @media only screen and (min-width: 768px) {
      .ec-off1Grid .ec-off1Grid__cell {
        position: relative;
        min-height: 1px;
        margin-left: 8.33333%; } }
  @media only screen and (min-width: 768px) and (min-width: 768px) {
    .ec-off1Grid .ec-off1Grid__cell {
      width: 83.33333%; } }

/*
全幅 内寄せグリッド 8/12

ex [ご利用規約ページ　本文](http://demo3.ec-cube.net/help/agreement)

Markup:
.ec-off2Grid
  .ec-off2Grid__cell Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
  .ec-off2Grid__cell Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod


Styleguide 5.1.6
*/
.ec-off2Grid {
  display: block;
  margin: 0; }
  @media only screen and (min-width: 768px) {
    .ec-off2Grid {
      display: flex; } }
  .ec-off2Grid .ec-off2Grid__cell {
    margin: 0; }
    @media only screen and (min-width: 768px) {
      .ec-off2Grid .ec-off2Grid__cell {
        position: relative;
        min-height: 1px;
        margin-left: 16.66667%; } }
  @media only screen and (min-width: 768px) and (min-width: 768px) {
    .ec-off2Grid .ec-off2Grid__cell {
      width: 66.66667%; } }

/*
全幅 内寄せグリッド 8/12

ex [ご利用規約ページ　本文](http://demo3.ec-cube.net/help/agreement)

Markup:
.ec-off3Grid
  .ec-off3Grid__cell Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
  .ec-off3Grid__cell Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod


Styleguide 5.1.7
*/
.ec-off3Grid {
  display: block;
  margin: 0; }
  @media only screen and (min-width: 768px) {
    .ec-off3Grid {
      display: flex; } }
  .ec-off3Grid .ec-off3Grid__cell {
    margin: 0; }
    @media only screen and (min-width: 768px) {
      .ec-off3Grid .ec-off3Grid__cell {
        position: relative;
        min-height: 1px;
        margin-left: 25%; } }
  @media only screen and (min-width: 768px) and (min-width: 768px) {
    .ec-off3Grid .ec-off3Grid__cell {
      width: 50%; } }

/*
全幅 内寄せグリッド 8/12

ex [ご利用規約ページ　本文](http://demo3.ec-cube.net/help/agreement)

Markup:
.ec-off4Grid
  .ec-off4Grid__cell Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
  .ec-off4Grid__cell Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod


Styleguide 5.1.8
*/
.ec-off4Grid {
  display: block;
  margin: 0; }
  @media only screen and (min-width: 768px) {
    .ec-off4Grid {
      display: flex; } }
  .ec-off4Grid .ec-off4Grid__cell {
    margin: 0; }
    @media only screen and (min-width: 768px) {
      .ec-off4Grid .ec-off4Grid__cell {
        position: relative;
        min-height: 1px;
        margin-left: 33.33333%; } }
  @media only screen and (min-width: 768px) and (min-width: 768px) {
    .ec-off4Grid .ec-off4Grid__cell {
      width: 33.33333%; } }

/*
グリッドオプション

グリッドのセルに対して「左寄せ」「中央寄せ」「右寄せ」のオプションを付与することができます。

sg-wrapper:
<div class="ec-role">
  <sg-wrapper-content/>

Styleguide 5.1.9
*/
/*
グリッドセルの左寄せ

.ec-gridに.ec-grid--leftを付与すると内包してるセルを左寄せにすることができます。

Markup:
.ec-grid4.ec-grid--left
  .ec-grid4__cell(style="background-color: rgba(86,61,124,.15);border: 1px solid rgba(86,61,124,.2);height:50px;") ec-grid4__cell
  .ec-grid4__cell(style="background-color: rgba(86,61,124,.15);border: 1px solid rgba(86,61,124,.2);height:50px;") ec-grid4__cell
  .ec-grid4__cell(style="background-color: rgba(86,61,124,.15);border: 1px solid rgba(86,61,124,.2);height:50px;") ec-grid4__cell

Styleguide 5.1.10
*/
.ec-grid--left {
  justify-content: flex-start; }

/*
グリッドセルの右寄せ

.ec-gridに.ec-grid--leftを付与すると内包してるセルを左寄せにすることができます。

Markup:
.ec-grid4.ec-grid--right
  .ec-grid4__cell(style="background-color: rgba(86,61,124,.15);border: 1px solid rgba(86,61,124,.2);height:50px;") ec-grid4__cell
  .ec-grid4__cell(style="background-color: rgba(86,61,124,.15);border: 1px solid rgba(86,61,124,.2);height:50px;") ec-grid4__cell
  .ec-grid4__cell(style="background-color: rgba(86,61,124,.15);border: 1px solid rgba(86,61,124,.2);height:50px;") ec-grid4__cell

Styleguide 5.1.11
*/
.ec-grid--right {
  justify-content: flex-end; }

/*
グリッドセルの中央寄せ

.ec-gridに.ec-grid--leftを付与すると内包してるセルを左寄せにすることができます。

Markup:
.ec-grid4.ec-grid--center
  .ec-grid4__cell(style="background-color: rgba(86,61,124,.15);border: 1px solid rgba(86,61,124,.2);height:50px;") ec-grid4__cell
  .ec-grid4__cell(style="background-color: rgba(86,61,124,.15);border: 1px solid rgba(86,61,124,.2);height:50px;") ec-grid4__cell
  .ec-grid4__cell(style="background-color: rgba(86,61,124,.15);border: 1px solid rgba(86,61,124,.2);height:50px;") ec-grid4__cell

Styleguide 5.1.12
*/
.ec-grid--center {
  justify-content: center; }

/**
 * ECCUBE 固有のスタイルユーティリティ
 */
/**
メディアクエリ
SP フォーストで記述する。
Twitter Bootstrap デフォルト準拠
 */
/*
レイアウト

レイアウト

Styleguide 5.2
*/
/*
画像レイアウト

画像とテキストを水平に並べるレイアウトです。

画像は20%で表示されます。

ex [カート確認](http://demo3.ec-cube.net/products/detail/33)

Markup:
.ec-imageGrid
  .ec-imageGrid__img: img(src="http://demo3.ec-cube.net/upload/save_image/0701113537_559351f959620.jpeg")
  .ec-imageGrid__content
    p.ec-font-bold ホーローマグ
    p ¥ 1,728 x 1

sg-wrapper:
<div class="ec-role">
  <sg-wrapper-content/>


Styleguide 5.2.1
*/
.ec-imageGrid {
  display: table;
  border-top: 1px dotted #ccc;
  width: 100%; }
  .ec-imageGrid .ec-imageGrid__img {
    display: table-cell;
    padding: 10px;
    width: 100px; }
    @media only screen and (min-width: 768px) {
      .ec-imageGrid .ec-imageGrid__img {
        padding: 10px;
        width: 130px; } }
    .ec-imageGrid .ec-imageGrid__img img {
      width: 100%; }
  .ec-imageGrid .ec-imageGrid__content {
    vertical-align: middle;
    display: table-cell; }
    .ec-imageGrid .ec-imageGrid__content span {
      margin-left: 10px; }
    .ec-imageGrid .ec-imageGrid__content p {
      margin-bottom: 0; }

/**
メディアクエリ
SP フォーストで記述する。
Twitter Bootstrap デフォルト準拠
 */
/**
 * ECCUBE 固有のスタイルユーティリティ
 */
/*
ログイン

ログイン

sg-wrapper:
<div class="ec-role">
  <sg-wrapper-content/>
</div>

Styleguide 6.1
*/
/*
ログインフォーム

http://demo3.ec-cube.net/mypage

Markup:
include /assets/tmpl/elements/6.3.login.pug
+ec-login

Styleguide 6.1.1
*/
.ec-login {
  margin: 0 0 20px;
  padding: 20px 13%;
  height: auto;
  background: #F3F4F4;
  box-sizing: border-box; }
  @media only screen and (min-width: 768px) {
    .ec-login {
      margin: 0 16px;
      height: 430px; } }
  .ec-login .ec-login__icon {
    font-size: 90px;
    text-align: center; }
  .ec-login .ec-login__input {
    margin-bottom: 40px; }
    .ec-login .ec-login__input .ec-checkbox span {
      margin-left: 5px;
      font-weight: normal; }
  .ec-login .ec-login__actions {
    color: #fff; }
    .ec-login .ec-login__actions a {
      color: inherit;
      text-decoration: none; }
    .ec-login .ec-login__actions a:hover {
      text-decoration: none; }
  .ec-login .ec-login__link {
    margin-top: 5px;
    margin-left: 0; }
    @media only screen and (min-width: 768px) {
      .ec-login .ec-login__link {
        margin-left: 20px; } }
  .ec-login .ec-login__error {
    display: none;
    color: #DE5D50; }

@media only screen and (min-width: 768px) {
  .ec-login.is_active {
    height: 500px; } }

.is_active .ec-login__error {
  display: block; }

/*
ゲスト購入

http://demo3.ec-cube.net/mypage

Markup:
include /assets/tmpl/elements/6.3.login.pug
+ec-guest
hoge

Styleguide 6.1.2
*/
.ec-guest {
  display: table;
  margin: 0;
  padding: 13%;
  height: auto;
  box-sizing: border-box;
  background: #F3F4F4; }
  @media only screen and (min-width: 768px) {
    .ec-guest {
      height: 430px;
      margin: 0 16px; } }
  .ec-guest .ec-guest__inner {
    display: table-cell;
    vertical-align: middle;
    text-align: center; }
    .ec-guest .ec-guest__inner p {
      margin-bottom: 16px; }
  .ec-guest .ec-guest__actions {
    display: block;
    vertical-align: middle;
    text-align: center;
    color: #fff; }
    .ec-guest .ec-guest__actions a {
      color: inherit;
      text-decoration: none; }
    .ec-guest .ec-guest__actions a:hover {
      text-decoration: none; }
  .ec-guest .ec-guest__icon {
    font-size: 70px;
    text-align: center; }

/**
メディアクエリ
SP フォーストで記述する。
Twitter Bootstrap デフォルト準拠
 */
/**
 * ECCUBE 固有のスタイルユーティリティ
 */
/*
商品掲載

トップページに商品掲載するスタイルガイド群です。

sg-wrapper:
<div class="ec-role">
  <sg-wrapper-content/>


Styleguide 7.1
*/
/*
商品アイテム（商品紹介B）

TODO リンク設定
TODO レスポンシブ修正
３項目横並びの商品アイテムを表示します。
必要に応じて商品詳細や、キャッチコピーなどを添えることが出来ます。

ex [トップページ　商品紹介部分](http://demo3.ec-cube.net/)

Markup:
include /assets/tmpl/elements/7.1.itembanner.pug
+ec-displayB

Styleguide 7.1.1
*/
.ec-displayB {
  margin-bottom: 24px;
  display: flex;
  justify-content: space-between;
  flex-direction: column; }
  @media only screen and (min-width: 768px) {
    .ec-displayB {
      flex-direction: row; } }
  .ec-displayB .ec-displayB__cell {
    width: 100%;
    margin-bottom: 16px; }
    .ec-displayB .ec-displayB__cell a {
      color: inherit;
      text-decoration: none; }
    .ec-displayB .ec-displayB__cell a:hover {
      text-decoration: none; }
    @media only screen and (min-width: 768px) {
      .ec-displayB .ec-displayB__cell {
        width: 31.4466%;
        margin-bottom: 0; } }
    .ec-displayB .ec-displayB__cell:hover {
      text-decoration: none; }
      .ec-displayB .ec-displayB__cell:hover img {
        opacity: .8; }
      .ec-displayB .ec-displayB__cell:hover a {
        text-decoration: none; }
  .ec-displayB .ec-displayB__img {
    margin-bottom: 15px; }
  .ec-displayB .ec-displayB__catch {
    margin-bottom: 15px;
    text-decoration: none;
    font-weight: bold;
    color: #9a947e; }
  .ec-displayB .ec-displayB__comment {
    margin-bottom: 14px;
    text-decoration: none;
    color: #525263;
    font-size: 14px; }
  .ec-displayB .ec-displayB__link {
    text-decoration: none;
    font-weight: bold;
    color: #9a947e; }

/*
商品アイテム（商品紹介C）

TODO リンク設定
TODO レスポンシブ修正
４項目横並びの商品アイテムを表示します。

ex [トップページ　商品紹介部分](http://demo3.ec-cube.net/)

Markup:
include /assets/tmpl/elements/7.1.itembanner.pug
+ec-displayC
p hoge

Styleguide 7.1.2
*/
.ec-displayC {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 24px; }
  .ec-displayC .ec-displayC__cell {
    width: 47%; }
    .ec-displayC .ec-displayC__cell a {
      color: inherit;
      text-decoration: none; }
    .ec-displayC .ec-displayC__cell a:hover {
      text-decoration: none; }
    @media only screen and (min-width: 768px) {
      .ec-displayC .ec-displayC__cell {
        width: 22.8775%; } }
    .ec-displayC .ec-displayC__cell:hover a {
      text-decoration: none; }
    .ec-displayC .ec-displayC__cell:hover img {
      opacity: .8; }
  .ec-displayC .ec-displayC__img {
    display: block;
    width: 100%;
    margin-bottom: 15px; }
  .ec-displayC .ec-displayC__catch {
    display: block;
    width: 100%;
    font-weight: bold;
    color: #9a947e; }
  .ec-displayC .ec-displayC__title {
    display: block;
    width: 100%;
    color: #525263; }
  .ec-displayC .ec-displayC__price {
    display: block;
    width: 100%;
    font-weight: bold;
    color: #525263; }
  .ec-displayC .ec-displayC__price--sp {
    display: block;
    width: 100%;
    font-weight: bold;
    color: #DE5D50; }

/*
商品アイテム（商品紹介D）

TODO リンク設定
TODO レスポンシブ修正
６項目横並びの商品アイテムを表示します。

ex [トップページ　商品紹介部分](http://demo3.ec-cube.net/)

Markup:
include /assets/tmpl/elements/7.1.itembanner.pug
+ec-displayD

Styleguide 7.1.3
*/
.ec-displayD {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap-reverse; }
  @media only screen and (min-width: 768px) {
    .ec-displayD {
      box-sizing: border-box;
      flex-wrap: nowrap; } }
  .ec-displayD .ec-displayD__cell {
    width: 30%;
    margin-bottom: 8px; }
    .ec-displayD .ec-displayD__cell a {
      color: inherit;
      text-decoration: none; }
    .ec-displayD .ec-displayD__cell a:hover {
      text-decoration: none; }
    @media only screen and (min-width: 768px) {
      .ec-displayD .ec-displayD__cell {
        width: 14.3083%;
        margin-bottom: 16px; } }
    .ec-displayD .ec-displayD__cell:hover {
      text-decoration: none; }
      .ec-displayD .ec-displayD__cell:hover img {
        opacity: .8; }
  .ec-displayD .ec-displayD__img {
    display: block;
    width: 100%; }

/*
商品アイテム（商品一覧）

TODO リンク設定
TODO レスポンシブ修正
４項目横並びの商品アイテムを表示します。

ex [トップページ　商品紹介部分](http://demo3.ec-cube.net/)

Markup:
include /assets/tmpl/elements/7.1.itembanner.pug
+ec-shelf


Styleguide 7.1.4
*/
/**
メディアクエリ
SP フォーストで記述する。
Twitter Bootstrap デフォルト準拠
 */
/**
 * ECCUBE 固有のスタイルユーティリティ
 */
/*
検索・一覧表示

一覧表示

sg-wrapper:
<div class="ec-role">
  <sg-wrapper-content/>
</div>

Styleguide 7.2
*/
/*
トピックパス

TODO deprecated
検索結果で表示されるトピックパスのスタイルです。

ex [商品一覧ページ　横並びリスト部分](http://demo3.ec-cube.net/products/list?category_id=&name=)

Markup:
include /assets/tmpl/elements/7.2.search.pug
+ec-topicpath

Styleguide 7.2.1
*/
.ec-topicpath {
  border-top: 1px solid #ccc;
  border-bottom: 1px dotted #ccc;
  padding: 10px;
  list-style: none;
  overflow: hidden;
  font-size: 12px; }
  @media only screen and (min-width: 768px) {
    .ec-topicpath {
      padding: 1em 0;
      border: 0;
      font-size: 16px; } }
  .ec-topicpath .ec-topicpath__item a {
    color: inherit;
    text-decoration: none; }
  .ec-topicpath .ec-topicpath__item a:hover {
    text-decoration: none; }
  .ec-topicpath .ec-topicpath__item,
  .ec-topicpath .ec-topicpath__divider,
  .ec-topicpath .ec-topicpath__item--active {
    display: inline-block;
    min-width: 29px;
    text-align: center;
    position: relative; }
  .ec-topicpath .ec-topicpath__item--active {
    font-weight: bold; }
    .ec-topicpath .ec-topicpath__item--active a {
      color: inherit;
      text-decoration: none; }
    .ec-topicpath .ec-topicpath__item--active a:hover {
      text-decoration: none; }

/*
ページャ

検索結果で表示される商品一覧のスタイルです。

ex [商品一覧ページ　ページャ部分](http://demo3.ec-cube.net/products/list?category_id=&name=)

Markup:
include /assets/tmpl/elements/7.2.search.pug
+ec-pager

Styleguide 7.2.2
*/
.ec-pager {
  list-style: none;
  list-style-type: none;
  margin: 0 auto;
  padding: 1em 0;
  text-align: center; }
  .ec-pager .ec-pager__item,
  .ec-pager .ec-pager__item--active {
    display: inline-block;
    min-width: 29px;
    padding: 0 3px 0 2px;
    text-align: center;
    position: relative; }
    .ec-pager .ec-pager__item a,
    .ec-pager .ec-pager__item--active a {
      color: inherit;
      text-decoration: none; }
    .ec-pager .ec-pager__item a:hover,
    .ec-pager .ec-pager__item--active a:hover {
      text-decoration: none; }
    .ec-pager .ec-pager__item a,
    .ec-pager .ec-pager__item--active a {
      color: inherit;
      display: block;
      line-height: 1.8;
      padding: 5px 1em;
      text-decoration: none; }
    .ec-pager .ec-pager__item a:hover,
    .ec-pager .ec-pager__item--active a:hover {
      color: inherit; }
  .ec-pager .ec-pager__item--active {
    background: #F3F3F3; }
  .ec-pager .ec-pager__item:hover {
    background: #F3F3F3; }

/*
検索ボックス

検索結果で表示される商品一覧のスタイルです。

ex [商品一覧ページ　検索ボックス部分](http://demo3.ec-cube.net/products/list?category_id=&name=)

Markup:
include /assets/tmpl/elements/7.2.search.pug
+ec-searchbox

Styleguide 7.2.3
*/
.ec-searchbox .ec-searchbox__keyword {
  height: 50px;
  padding: 0 50px 0 1em;
  border-bottom: 1px solid #ccc;
  position: relative;
  margin-left: 1%; }
  .ec-searchbox .ec-searchbox__keyword input[type="text"] {
    height: 50px;
    width: 100%;
    font-size: 16px;
    border: 0 none;
    padding: 0.5em 0;
    box-shadow: none;
    background: none;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box; }
  .ec-searchbox .ec-searchbox__keyword input:focus {
    outline: none; }

/**
メディアクエリ
SP フォーストで記述する。
Twitter Bootstrap デフォルト準拠
 */
@keyframes fadeIn {
  0% {
    opacity: 0;
    visibility: hidden; }
  100% {
    opacity: 1;
    visibility: visible; } }

@keyframes fadeOut {
  0% {
    opacity: 1;
    visibility: visible; }
  100% {
    opacity: 0;
    visibility: hidden; } }

/**
 * ECCUBE 固有のスタイルユーティリティ
 */
/*
カート

ショッピングカートに関するスタイルです。

sg-wrapper:
<div class="ec-role">
  <sg-wrapper-content/>


Styleguide 7.3
*/
/*
カートヘッダ

購入完了までの手順や、現在の状態を表示します。

ul 要素を用いたリスト要素としてマークアップします。

ex [カートページ　ヘッダ部分](http://demo3.ec-cube.net/cart)

Markup:
include /assets/tmpl/elements/7.3.cart.pug
+ec-progress

sg-wrapper:
<div class="ec-role">
  <sg-wrapper-content/>
</div>

Styleguide 7.3.1
*/
.ec-progress {
  margin: 0 auto;
  padding: 8px 0 16px;
  display: table;
  table-layout: fixed;
  width: 100%;
  max-width: 600px;
  list-style: none; }
  @media only screen and (min-width: 768px) {
    .ec-progress {
      margin-bottom: 30px;
      padding: 0; } }
  .ec-progress .ec-progress__item {
    display: table-cell;
    position: relative;
    font-size: 14px;
    text-align: center;
    font-weight: bold;
    z-index: 10; }
    .ec-progress .ec-progress__item:after {
      content: '';
      position: absolute;
      display: block;
      background: #525263;
      width: 100%;
      height: 0.25em;
      top: 1.25em;
      left: 50%;
      margin-left: 1.5em\9;
      z-index: -1; }
    .ec-progress .ec-progress__item:last-child:after {
      display: none; }
  .ec-progress .ec-progress__number {
    line-height: 30px;
    width: 30px;
    height: 30px;
    margin-bottom: 5px;
    font-size: 12px;
    background: #525263;
    color: #fff;
    top: 0;
    left: 18px;
    display: inline-block;
    text-align: center;
    vertical-align: middle;
    border-radius: 50%; }
    @media only screen and (min-width: 768px) {
      .ec-progress .ec-progress__number {
        line-height: 42px;
        width: 42px;
        height: 42px;
        font-size: 20px; } }
  .ec-progress .ec-progress__label {
    font-size: 12px; }
  .ec-progress .is-complete .ec-progress__number {
    background: #5CB1B1; }
  .ec-progress .is-complete .ec-progress__label {
    color: #5CB1B1; }

/*
カートナビゲーション

ex [カートページ　ナビゲーション部分](http://demo3.ec-cube.net/cart)

Markup:
include /assets/tmpl/elements/11.2.header.pug
+ec-headerCart

sg-wrapper:
<div class="ec-role">
  <sg-wrapper-content/>
</div>


Styleguide 7.3.5
*/
.ec-cartNavi {
  border: 1px solid #D9D9D9;
  width: 256px;
  position: relative;
  display: block;
  height: 44px;
  overflow: hidden;
  white-space: nowrap;
  z-index: 3;
  cursor: pointer; }
  .ec-cartNavi .ec-cartNavi__icon {
    width: 30px;
    height: 30px;
    position: relative;
    top: 8px;
    left: 8px;
    fill: #525263;
    display: inline-block;
    opacity: 1;
    visibility: visible;
    animation: fadeIn 200ms linear 0s; }
  .ec-cartNavi .ec-cartNavi__iconClose {
    width: 25px;
    height: 25px;
    position: absolute;
    top: 8px;
    left: 8px;
    fill: #525263;
    opacity: 0;
    visibility: hidden;
    animation: fadeOut 200ms linear 0s; }
  .ec-cartNavi .ec-cartNavi__badge {
    position: relative;
    left: 13px;
    top: -6px;
    display: inline-block;
    min-width: 10px;
    padding: 3px 7px;
    font-size: 10px;
    font-weight: 700;
    line-height: 1;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    background-color: #DE5D50;
    border-radius: 10px;
    display: inline-block;
    opacity: 1;
    visibility: visible;
    animation: fadeIn 150ms linear 0s; }
  .ec-cartNavi .ec-cartNavi__label {
    font-size: 12px;
    position: absolute;
    right: 0;
    top: 0;
    height: 44px;
    line-height: 44px;
    vertical-align: middle;
    margin-right: 16px; }
  .ec-cartNavi .ec-cartNavi__price {
    display: inline-block;
    font-size: 16px; }

.is_active .ec-cartNavi__icon {
  opacity: 0;
  visibility: hidden;
  animation: fadeOut 150ms linear 0s; }

.is_active .ec-cartNavi__badge {
  display: none; }

.is_active .ec-cartNavi__iconClose {
  display: inline-block;
  opacity: 1;
  visibility: visible;
  animation: fadeIn 200ms linear 0s; }

.ec-cartNaviNull {
  display: none;
  min-width: 256px;
  text-align: center;
  background: #f3f3f3;
  box-sizing: border-box;
  padding: 16px;
  z-index: 3;
  position: absolute;
  opacity: 0;
  visibility: hidden;
  animation: fadeOut 150ms linear 0s; }
  .ec-cartNaviNull .ec-cartNaviNull__message {
    border: 1px solid #D9D9D9;
    padding: 16px 0;
    font-size: 16px;
    font-weight: bold;
    color: #fff;
    background-color: #F99; }
    .ec-cartNaviNull .ec-cartNaviNull__message p {
      margin: 0; }

.is_active .ec-cartNaviNull {
  display: block;
  opacity: 1;
  visibility: visible;
  animation: fadeIn 150ms linear 0s;
  display: block; }

.is_active .ec-cartNaviIsset {
  display: block;
  opacity: 1;
  visibility: visible;
  animation: fadeIn 150ms linear 0s;
  display: block; }

/*
カートナビゲーションのポップアップ(商品詳細)

ex [カートページ　ナビゲーション部分](http://demo3.ec-cube.net/cart)

Markup:
div(style="height:350px;")
  // 上記のdivはスタイルガイド都合上、高さをもたせるため設置(mocでは不要)
  .is_active
    .ec-cartNavi
      .ec-cartNavi__icon
        svg(viewbox="0 0 360 360")
          path(d="M345,78.032v101.817c0,3.183-1.06,6-3.173,8.452c-2.116,2.455-4.828,3.877-8.132,4.275l-207.043,24.261   c0.13,0.93,0.427,2.355,0.893,4.275c0.461,1.925,0.858,3.68,1.189,5.271c0.329,1.591,0.496,3.051,0.496,4.375   c0,2.121-1.586,6.362-4.759,12.727h182.452c3.437,0,6.411,1.262,8.924,3.778c2.51,2.52,3.768,5.503,3.768,8.948   c0,3.45-1.258,6.433-3.768,8.949c-2.513,2.52-5.487,3.778-8.924,3.778H103.847c-3.44,0-6.416-1.259-8.925-3.778   c-2.513-2.517-3.768-5.499-3.768-8.949c0-1.854,0.724-4.475,2.181-7.854c1.453-3.381,3.402-7.325,5.85-11.833   c2.445-4.506,3.798-7.026,4.065-7.557L68.149,65.305H27.693c-3.44,0-6.415-1.26-8.924-3.779C16.255,59.008,15,56.026,15,52.577   c0-3.445,1.254-6.428,3.768-8.949c2.51-2.516,5.484-3.777,8.924-3.777h50.77c2.113,0,3.997,0.432,5.651,1.291   c1.651,0.864,2.975,1.891,3.966,3.082c0.993,1.195,1.851,2.82,2.58,4.873c0.724,2.058,1.22,3.813,1.486,5.27   c0.264,1.463,0.626,3.416,1.091,5.867c0.461,2.455,0.759,4.146,0.893,5.072h238.179c3.437,0,6.41,1.262,8.924,3.777   C343.742,71.602,345,74.586,345,78.032z M134.487,276.397c4.957,4.972,7.437,10.972,7.437,17.998   c0,7.025-2.479,13.025-7.437,17.997c-4.958,4.972-10.942,7.458-17.949,7.458c-7.008,0-12.989-2.486-17.947-7.458   c-4.959-4.972-7.437-10.972-7.437-17.997c0-7.026,2.478-13.026,7.437-17.998c4.958-4.971,10.938-7.457,17.947-7.457   C123.544,268.94,129.529,271.426,134.487,276.397z M312.178,276.397c4.958,4.972,7.437,10.972,7.437,17.998   c0,7.025-2.479,13.025-7.437,17.997c-4.957,4.972-10.941,7.458-17.947,7.458c-7.009,0-12.99-2.486-17.947-7.458   c-4.958-4.972-7.437-10.972-7.437-17.997c0-7.026,2.479-13.026,7.437-17.998c4.957-4.971,10.938-7.457,17.947-7.457   C301.237,268.94,307.221,271.426,312.178,276.397z")
      .ec-cartNavi__iconClose
        svg(viewbox="0 0 360 360")
          path(d="M337.572,293.77L223.65,179.85L337.572,65.928c3.547-3.546,3.163-9.678-0.855-13.7l-29.095-29.093  c-4.022-4.021-10.153-4.402-13.7-0.856L180,136.199L66.08,22.279c-3.547-3.547-9.681-3.165-13.702,0.856L23.286,52.228  c-4.02,4.022-4.404,10.154-0.857,13.7L136.35,179.85L22.429,293.77c-3.547,3.545-3.163,9.678,0.857,13.701l29.093,29.092  c4.021,4.023,10.155,4.406,13.702,0.857L180,223.499L293.92,337.42c3.548,3.549,9.679,3.166,13.701-0.857l29.095-29.092  C340.735,303.448,341.121,297.315,337.572,293.77z")
      .ec-cartNavi__badge 1
      .ec-cartNavi__label
        | 合計
        .ec-cartNavi__price ¥1920
    +b.ec-cartNaviIsset
      +e.cart
        +e.cartImage
          img(src='http://demo3.ec-cube.net/upload/save_image/0701104933_5593472d8d179.jpeg')
        +e.cartContent
          +e.cartContentTitle ミニテーブル
          +e.cartContentPrice ¥ 12,960
            +e.cartContentTax 税込
          +e.cartContentNumber 数量：1
      +e.action
        a.ec-blockBtn--action(href="/moc/guest/cart1") カートへ進む
        a.ec-blockBtn.ec-cartNavi--cancel キャンセル

Styleguide 7.3.6
*/
.ec-cartNaviIsset {
  display: none;
  min-width: 256px;
  max-width: 256px;
  text-align: center;
  background: #f3f3f3;
  box-sizing: border-box;
  padding: 16px;
  z-index: 3;
  position: absolute;
  opacity: 0;
  visibility: hidden;
  animation: fadeOut 150ms linear 0s; }
  .ec-cartNaviIsset .ec-cartNaviIsset__cart {
    border-bottom: 1px solid #E8E8E8;
    margin-bottom: 16px;
    padding-bottom: 32px; }
    .ec-cartNaviIsset .ec-cartNaviIsset__cart:after {
      content: " ";
      display: table; }
    .ec-cartNaviIsset .ec-cartNaviIsset__cart:after {
      clear: both; }
  .ec-cartNaviIsset .ec-cartNaviIsset__cartImage {
    float: left;
    width: 50%; }
    .ec-cartNaviIsset .ec-cartNaviIsset__cartImage img {
      width: 100%; }
  .ec-cartNaviIsset .ec-cartNaviIsset__cartContent {
    float: right;
    width: 50%;
    padding-left: 16px;
    text-align: left;
    box-sizing: border-box; }
  .ec-cartNaviIsset .ec-cartNaviIsset__action .ec-blockBtn--action {
    color: #fff;
    margin-bottom: 8px; }
  .ec-cartNaviIsset .ec-cartNaviIsset__cartContentTitle {
    margin-bottom: 8px; }
  .ec-cartNaviIsset .ec-cartNaviIsset__cartContentPrice {
    font-weight: bold; }
  .ec-cartNaviIsset .ec-cartNaviIsset__cartContentTax {
    display: inline-block;
    font-size: 12px;
    font-weight: normal;
    margin-left: 5px; }
  .ec-cartNaviIsset .ec-cartNaviIsset__cartContentNumber {
    font-size: 14px; }

/*
カートナビゲーションのポップアップ(商品なし)

ex [カートページ　ナビゲーション部分](http://demo3.ec-cube.net/cart)

Markup:
div(style="height:170px;")
  // 上記のdivはスタイルガイド都合上、高さをもたせるため設置(mocでは不要)
  .is_active
    .ec-cartNavi
      .ec-cartNavi__icon
        svg(viewbox="0 0 360 360")
          path(d="M345,78.032v101.817c0,3.183-1.06,6-3.173,8.452c-2.116,2.455-4.828,3.877-8.132,4.275l-207.043,24.261   c0.13,0.93,0.427,2.355,0.893,4.275c0.461,1.925,0.858,3.68,1.189,5.271c0.329,1.591,0.496,3.051,0.496,4.375   c0,2.121-1.586,6.362-4.759,12.727h182.452c3.437,0,6.411,1.262,8.924,3.778c2.51,2.52,3.768,5.503,3.768,8.948   c0,3.45-1.258,6.433-3.768,8.949c-2.513,2.52-5.487,3.778-8.924,3.778H103.847c-3.44,0-6.416-1.259-8.925-3.778   c-2.513-2.517-3.768-5.499-3.768-8.949c0-1.854,0.724-4.475,2.181-7.854c1.453-3.381,3.402-7.325,5.85-11.833   c2.445-4.506,3.798-7.026,4.065-7.557L68.149,65.305H27.693c-3.44,0-6.415-1.26-8.924-3.779C16.255,59.008,15,56.026,15,52.577   c0-3.445,1.254-6.428,3.768-8.949c2.51-2.516,5.484-3.777,8.924-3.777h50.77c2.113,0,3.997,0.432,5.651,1.291   c1.651,0.864,2.975,1.891,3.966,3.082c0.993,1.195,1.851,2.82,2.58,4.873c0.724,2.058,1.22,3.813,1.486,5.27   c0.264,1.463,0.626,3.416,1.091,5.867c0.461,2.455,0.759,4.146,0.893,5.072h238.179c3.437,0,6.41,1.262,8.924,3.777   C343.742,71.602,345,74.586,345,78.032z M134.487,276.397c4.957,4.972,7.437,10.972,7.437,17.998   c0,7.025-2.479,13.025-7.437,17.997c-4.958,4.972-10.942,7.458-17.949,7.458c-7.008,0-12.989-2.486-17.947-7.458   c-4.959-4.972-7.437-10.972-7.437-17.997c0-7.026,2.478-13.026,7.437-17.998c4.958-4.971,10.938-7.457,17.947-7.457   C123.544,268.94,129.529,271.426,134.487,276.397z M312.178,276.397c4.958,4.972,7.437,10.972,7.437,17.998   c0,7.025-2.479,13.025-7.437,17.997c-4.957,4.972-10.941,7.458-17.947,7.458c-7.009,0-12.99-2.486-17.947-7.458   c-4.958-4.972-7.437-10.972-7.437-17.997c0-7.026,2.479-13.026,7.437-17.998c4.957-4.971,10.938-7.457,17.947-7.457   C301.237,268.94,307.221,271.426,312.178,276.397z")
      .ec-cartNavi__iconClose
        svg(viewbox="0 0 360 360")
          path(d="M337.572,293.77L223.65,179.85L337.572,65.928c3.547-3.546,3.163-9.678-0.855-13.7l-29.095-29.093  c-4.022-4.021-10.153-4.402-13.7-0.856L180,136.199L66.08,22.279c-3.547-3.547-9.681-3.165-13.702,0.856L23.286,52.228  c-4.02,4.022-4.404,10.154-0.857,13.7L136.35,179.85L22.429,293.77c-3.547,3.545-3.163,9.678,0.857,13.701l29.093,29.092  c4.021,4.023,10.155,4.406,13.702,0.857L180,223.499L293.92,337.42c3.548,3.549,9.679,3.166,13.701-0.857l29.095-29.092  C340.735,303.448,341.121,297.315,337.572,293.77z")
      .ec-cartNavi__badge 1
      .ec-cartNavi__label
        | 合計
        .ec-cartNavi__price ¥1920
    .ec-cartNaviNull
      .ec-cartNaviNull__message
        p 現在カート内に
          br
          | 商品がございません。
    //+b.ec-cartNaviIsset
    //  +e.cart
    //    +e.cartImage
    //      img(src='http://demo3.ec-cube.net/upload/save_image/0701104933_5593472d8d179.jpeg')
    //    +e.cartContent
    //      +e.cartContentTitle ミニテーブル
    //      +e.cartContentPrice ¥ 12,960
    //        +e.cartContentTax 税込
    //      +e.cartContentNumber 数量：1
    //  +e.action
    //    a.ec-blockBtn--action(href="/moc/guest/cart1") カートへ進む
    //    a.ec-blockBtn キャンセル

sg-wrapper:
<div class="ec-role">
  <sg-wrapper-content/>
</div>


Styleguide 7.3.7
*/
/*
総計

ex [カートページ　統計部分](http://demo3.ec-cube.net/cart)

Markup:
include /assets/tmpl/elements/7.3.cart.pug
+ec-totalBox

Styleguide 7.3.8
*/
.ec-totalBox {
  background: #F3F3F3;
  padding: 16px; }
  .ec-totalBox .ec-totalBox__spec {
    position: relative;
    margin-bottom: 8px; }
    .ec-totalBox .ec-totalBox__spec dt {
      font-weight: normal;
      position: relative;
      top: 0;
      left: 0;
      text-align: left; }
    .ec-totalBox .ec-totalBox__spec dd {
      position: absolute;
      top: 0;
      right: 0;
      text-align: right; }
    .ec-totalBox .ec-totalBox__spec .ec-totalBox .ec-totalBox__spec__specTotal {
      color: #DE5D50; }
  .ec-totalBox .ec-totalBox__total {
    padding: 8px 0;
    border-top: 1px dotted #ccc;
    text-align: right;
    font-size: 14px;
    font-weight: bold; }
  .ec-totalBox .ec-totalBox__price {
    margin-left: 16px;
    font-size: 16px;
    font-weight: bold;
    color: #DE5D50; }
    @media only screen and (min-width: 768px) {
      .ec-totalBox .ec-totalBox__price {
        font-size: 24px; } }
  .ec-totalBox .ec-totalBox__taxLabel {
    margin-left: 8px;
    color: #DE5D50;
    font-size: 12px; }
    @media only screen and (min-width: 768px) {
      .ec-totalBox .ec-totalBox__taxLabel {
        font-size: 14px; } }
  .ec-totalBox .ec-totalBox__btn {
    color: #fff; }
    .ec-totalBox .ec-totalBox__btn a {
      color: inherit;
      text-decoration: none; }
    .ec-totalBox .ec-totalBox__btn a:hover {
      text-decoration: none; }
    .ec-totalBox .ec-totalBox__btn .ec-blockBtn--action {
      font-size: 16px;
      font-weight: bold; }

/**
メディアクエリ
SP フォーストで記述する。
Twitter Bootstrap デフォルト準拠
 */
/*
お知らせ

新着情報やバナーなどの掲載項目を紹介していきます。

sg-wrapper:
<div class="ec-role">
  <sg-wrapper-content/>
</div>

Styleguide 8.1
*/
/*
新着情報

新着情報の掲載をします。

ex [トップページ　新着情報部分](http://demo3.ec-cube.net/)

Markup:
include /assets/tmpl/elements/8.1.info.pug
+ec-news

Styleguide 8.1.1
*/
.ec-news {
  margin-bottom: 16px;
  background: #F8F8F8; }
  @media only screen and (min-width: 768px) {
    .ec-news {
      margin-right: 3%; } }
  @media only screen and (min-width: 768px) {
    .ec-news {
      margin-bottom: 32px; } }
  .ec-news .ec-news__title {
    font-weight: bold;
    padding: 8px;
    font-size: 16px;
    text-align: center; }
    @media only screen and (min-width: 768px) {
      .ec-news .ec-news__title {
        padding: 16px;
        text-align: left;
        font-size: 24px; } }
  .ec-news .ec-news__items {
    padding: 0;
    list-style: none;
    border-top: 1px dotted #ccc; }

/*
折りたたみ項目

折りたたみ項目を掲載します。

ex [トップページ　折りたたみ項目部分](http://demo3.ec-cube.net/)

Markup:
include /assets/tmpl/elements/8.1.info.pug
+ec-newsline

Styleguide 8.1.2
*/
.ec-newsline {
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
  padding: 0 16px; }
  .ec-newsline .ec-newsline__info {
    width: 100%;
    padding: 16px 0; }
    .ec-newsline .ec-newsline__info:after {
      content: " ";
      display: table; }
    .ec-newsline .ec-newsline__info:after {
      clear: both; }
  .ec-newsline .ec-newsline__date {
    display: inline-block;
    margin-right: 10px;
    float: left; }
  .ec-newsline .ec-newsline__comment {
    display: inline-block;
    float: left; }
  .ec-newsline .ec-newsline__close {
    float: right;
    display: inline-block;
    text-align: right; }
    .ec-newsline .ec-newsline__close .ec-closeBtn--circle {
      display: inline-block;
      width: 25px;
      height: 25px;
      min-width: 25px;
      min-height: 25px; }
    .ec-newsline .ec-newsline__close .ec-closeBtn--circle__icon {
      top: 20%; }
  .ec-newsline .ec-newsline__description {
    width: 100%;
    height: 0;
    transition: all .2s ease-out; }
  .ec-newsline.is_active .ec-newsline__description {
    height: auto;
    transition: all .2s ease-out;
    padding-bottom: 16px; }

/*
バナー

バナー形式でお知ら情報を伝える際の背景つきボックスです。

ex [トップページ　バナー部分](http://demo3.ec-cube.net/)

Markup:
include /assets/tmpl/elements/8.1.info.pug
+ec-banner

Styleguide 8.1.3
*/
.ec-banner {
  padding: 16px;
  margin-bottom: 16px;
  background: #F8F8F8;
  text-align: center;
  font-weight: bold;
  border: 1px solid #ccc; }
  @media only screen and (min-width: 768px) {
    .ec-banner {
      margin-left: 3%;
      margin-bottom: 16px; } }
  .ec-banner .ec-banner__intro {
    color: #DE5D50;
    font-size: 20px;
    font-weight: bold; }
  .ec-banner .ec-banner__title {
    color: #DE5D50;
    font-size: 32px;
    font-weight: bold; }

/**
 * ECCUBE 固有のスタイルユーティリティ
 */
/**
メディアクエリ
SP フォーストで記述する。
Twitter Bootstrap デフォルト準拠
 */
/*
マイページ

マイページで利用される

sg-wrapper:
<div class="ec-role">
  <sg-wrapper-content/>


Styleguide 9.1
*/
/*
マイページ

マイページで表示するメニューリストです。

ul を利用したリスト要素で記述します。

ex [マイページ　メニューリスト部分](http://demo3.ec-cube.net/mypage)

Markup:
include /assets/tmpl/elements/9.1.mypage.pug
+ec-navlist

Styleguide 9.1.1
*/
.ec-navlistRole .ec-navlistRole__navlist {
  display: flex;
  flex-wrap: wrap;
  border-color: #D0D0D0;
  border-style: solid;
  border-width: 1px 0 0 1px;
  margin-bottom: 32px;
  padding: 0;
  list-style: none; }
  .ec-navlistRole .ec-navlistRole__navlist a {
    color: inherit;
    text-decoration: none; }
  .ec-navlistRole .ec-navlistRole__navlist a:hover {
    text-decoration: none; }
  @media only screen and (min-width: 768px) {
    .ec-navlistRole .ec-navlistRole__navlist {
      flex-wrap: nowrap; } }

.ec-navlistRole .ec-navlistRole__item {
  width: 50%;
  border-color: #D0D0D0;
  border-style: solid;
  border-width: 0 1px 1px 0;
  text-align: center;
  font-weight: bold; }
  .ec-navlistRole .ec-navlistRole__item a {
    padding: 16px;
    width: 100%;
    display: inline-block; }

.ec-navlistRole .active a {
  color: #DE5D50; }

/*
マイページ（お気に入り機能無効）

マイページで表示するメニューリストです。

ul を利用したリスト要素で記述します。

ex [マイページ　メニューリスト部分](http://demo3.ec-cube.net/mypage)

Markup:
include /assets/tmpl/elements/9.1.mypage.pug
+ec-navlist_noFavorite

Styleguide 9.1.2
*/
/*
Welcome メッセージ

マイページで表示するログイン名の表示コンポーネントです。

ex [マイページ　メニューリスト下部分](http://demo3.ec-cube.net/mypage)

Markup:
include /assets/tmpl/elements/9.1.mypage.pug
+ec-welcomeMsg

Styleguide 9.1.3
*/
.ec-welcomeMsg {
  margin-right: auto;
  margin-left: auto;
  padding-left: 16px;
  padding-right: 16px;
  box-sizing: border-box;
  font-size: 16px;
  font-family: Roboto, "游ゴシック", YuGothic, "Yu Gothic", "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", Arial, "メイリオ", Meiryo, sans-serif;
  line-height: 1.4;
  color: #525263;
  -webkit-text-size-adjust: 100%;
  width: 100%;
  margin: 1em 0;
  padding-bottom: 32px;
  text-align: center;
  border-bottom: 1px dotted #ccc; }
  .ec-welcomeMsg:after {
    content: " ";
    display: table; }
  .ec-welcomeMsg:after {
    clear: both; }
  .ec-welcomeMsg textarea {
    /* for chrome fontsize bug */
    font-family: sans-serif; }
  .ec-welcomeMsg img {
    max-width: 100%; }
  .ec-welcomeMsg html {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box; }
  .ec-welcomeMsg *,
  .ec-welcomeMsg *::before,
  .ec-welcomeMsg *::after {
    -webkit-box-sizing: inherit;
    -moz-box-sizing: inherit;
    box-sizing: inherit; }
  .ec-welcomeMsg img {
    width: 100%; }
  @media only screen and (min-width: 768px) {
    .ec-welcomeMsg {
      padding-left: 26px;
      padding-right: 26px; } }

/*
お気に入り一覧

お気に入り一覧で表示するアイテムの表示コンポーネントです。

ex [マイページ　お気に入り一覧](http://demo3.ec-cube.net/mypage/favorite)

Markup:
include /assets/tmpl/elements/9.1.mypage.pug
+ec-favorite

Styleguide 9.1.4
*/
.ec-favoriteRole .ec-favoriteRole__header {
  margin-bottom: 16px; }

.ec-favoriteRole .ec-favoriteRole__itemList {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 0;
  list-style: none; }
  .ec-favoriteRole .ec-favoriteRole__itemList a {
    color: inherit;
    text-decoration: none; }
  .ec-favoriteRole .ec-favoriteRole__itemList a:hover {
    text-decoration: none; }

.ec-favoriteRole .ec-favoriteRole__item {
  margin-bottom: 8px;
  width: 47.5%;
  position: relative; }
  @media only screen and (min-width: 768px) {
    .ec-favoriteRole .ec-favoriteRole__item {
      width: 22.8142%;
      margin-bottom: 24px; } }
  .ec-favoriteRole .ec-favoriteRole__item img {
    width: 100%; }
  .ec-favoriteRole .ec-favoriteRole__item .ec-closeBtn--circle {
    position: absolute;
    right: 10px;
    top: 10px; }

.ec-favoriteRole .ec-favoriteRole__itemThumb {
  display: block;
  height: auto;
  margin-bottom: 8px; }

.ec-favoriteRole .ec-favoriteRole__itemTitle {
  margin-bottom: 2px; }

.ec-favoriteRole .ec-favoriteRole__itemPrice {
  font-weight: bold;
  margin-bottom: 0; }

/**
メディアクエリ
SP フォーストで記述する。
Twitter Bootstrap デフォルト準拠
 */
/*
標準セクション

通常のコンテナブロックです。

ex [商品詳細ページ　コンテナ](http://demo3.ec-cube.net/products/detail/33)

Markup:
include /assets/tmpl/elements/11.1.role.pug
+ec-roleRole

Styleguide 11.1
*/
.ec-role {
  margin-right: auto;
  margin-left: auto;
  padding-left: 10px;
  padding-right: 10px;
  box-sizing: border-box;
  font-size: 16px;
  font-family: Roboto, "游ゴシック", YuGothic, "Yu Gothic", "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", Arial, "メイリオ", Meiryo, sans-serif;
  line-height: 1.4;
  color: #525263;
  -webkit-text-size-adjust: 100%;
  width: 100%;
  max-width: 1150px; }
  .ec-role:after {
    content: " ";
    display: table; }
  .ec-role:after {
    clear: both; }
  .ec-role textarea {
    /* for chrome fontsize bug */
    font-family: sans-serif; }
  .ec-role img {
    max-width: 100%; }
  .ec-role html {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box; }
  .ec-role *,
  .ec-role *::before,
  .ec-role *::after {
    -webkit-box-sizing: inherit;
    -moz-box-sizing: inherit;
    box-sizing: inherit; }
  .ec-role img {
    width: 100%; }

/*
マイページセクション

マイページ専用のコンテナブロックです。

ex [マイページ　コンテナ](http://demo3.ec-cube.net/mypage)

Markup:
include /assets/tmpl/elements/11.1.role.pug
+ec-roleRole

Styleguide 11.1.2
*/
.ec-mypageRole {
  margin-right: auto;
  margin-left: auto;
  padding-left: 16px;
  padding-right: 16px;
  box-sizing: border-box;
  font-size: 16px;
  font-family: Roboto, "游ゴシック", YuGothic, "Yu Gothic", "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", Arial, "メイリオ", Meiryo, sans-serif;
  line-height: 1.4;
  color: #525263;
  -webkit-text-size-adjust: 100%;
  width: 100%; }
  .ec-mypageRole:after {
    content: " ";
    display: table; }
  .ec-mypageRole:after {
    clear: both; }
  .ec-mypageRole textarea {
    /* for chrome fontsize bug */
    font-family: sans-serif; }
  .ec-mypageRole img {
    max-width: 100%; }
  .ec-mypageRole html {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box; }
  .ec-mypageRole *,
  .ec-mypageRole *::before,
  .ec-mypageRole *::after {
    -webkit-box-sizing: inherit;
    -moz-box-sizing: inherit;
    box-sizing: inherit; }
  .ec-mypageRole img {
    width: 100%; }
  @media only screen and (min-width: 768px) {
    .ec-mypageRole {
      padding-left: 26px;
      padding-right: 26px; } }
  @media only screen and (min-width: 768px) {
    .ec-mypageRole .ec-pageHeader h1 {
      margin: 10px 0 48px; } }

/**
メディアクエリ
SP フォーストで記述する。
Twitter Bootstrap デフォルト準拠
 */
/**
 * ECCUBE 固有のスタイルユーティリティ
 */
/*
ヘッダー

ヘッダー用のプロジェクトコンポーネントを提供します。

ex [商品詳細ページ　ヘッダー](http://demo3.ec-cube.net/products/detail/33)

Markup:
include /assets/tmpl/elements/11.2.header.pug
+ec-headerRole

Styleguide 11.2
*/
.ec-headerRole {
  margin-right: auto;
  margin-left: auto;
  padding-left: 10px;
  padding-right: 10px;
  box-sizing: border-box;
  font-size: 16px;
  font-family: Roboto, "游ゴシック", YuGothic, "Yu Gothic", "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", Arial, "メイリオ", Meiryo, sans-serif;
  line-height: 1.4;
  color: #525263;
  -webkit-text-size-adjust: 100%;
  width: 100%;
  max-width: 1150px;
  padding-top: 15px;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: auto; }
  .ec-headerRole:after {
    content: " ";
    display: table; }
  .ec-headerRole:after {
    clear: both; }
  .ec-headerRole textarea {
    /* for chrome fontsize bug */
    font-family: sans-serif; }
  .ec-headerRole img {
    max-width: 100%; }
  .ec-headerRole html {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box; }
  .ec-headerRole *,
  .ec-headerRole *::before,
  .ec-headerRole *::after {
    -webkit-box-sizing: inherit;
    -moz-box-sizing: inherit;
    box-sizing: inherit; }
  .ec-headerRole img {
    width: 100%; }
  .ec-headerRole:after {
    display: none; }
  @media only screen and (min-width: 768px) {
    .ec-headerRole:after {
      content: " ";
      display: table; }
    .ec-headerRole:after {
      clear: both; } }
  .ec-headerRole::before {
    display: none; }
  @media only screen and (min-width: 768px) {
    .ec-headerRole {
      width: 100%; }
      .ec-headerRole:after {
        content: " ";
        display: table; }
      .ec-headerRole:after {
        clear: both; } }
  .ec-headerRole .ec-headerRole__title {
    width: 100%; }
  .ec-headerRole .ec-headerRole__cart {
    display: none;
    position: absolute;
    top: 15px;
    right: 0; }
    @media only screen and (min-width: 768px) {
      .ec-headerRole .ec-headerRole__cart {
        display: block; }
        .ec-headerRole .ec-headerRole__cart a {
          color: inherit;
          text-decoration: none; }
        .ec-headerRole .ec-headerRole__cart a:hover {
          text-decoration: none; } }
  .ec-headerRole .ec-headerRole__navSP {
    display: block;
    position: absolute;
    top: 15px;
    right: 10px;
    width: 25%;
    text-align: right; }
    @media only screen and (min-width: 768px) {
      .ec-headerRole .ec-headerRole__navSP {
        display: none; } }

/*
ヘッダータイトル

ヘッダー内で使用されるタイトルコンポーネントです。

Markup:
include /assets/tmpl/elements/11.2.header.pug
+ec-headerTitle

sg-wrapper:
<div class="ec-headerRole">
  <div class="ec-headerRole__search">
    <sg-wrapper-content/>
  </div>
</div>

Styleguide 11.2.1
*/
.ec-headerTitle .ec-headerTitle__title h1 {
  margin: 0;
  padding: 0; }

.ec-headerTitle .ec-headerTitle__title a {
  display: inline-block;
  margin-bottom: 15px;
  text-decoration: none;
  font-size: 20px;
  font-weight: bold;
  color: #000; }
  @media only screen and (min-width: 768px) {
    .ec-headerTitle .ec-headerTitle__title a {
      margin-bottom: 30px;
      font-size: 40px; } }
  .ec-headerTitle .ec-headerTitle__title a:hover {
    color: #666; }

.ec-headerTitle .ec-headerTitle__subtitle {
  font-size: 10px; }
  @media only screen and (min-width: 768px) {
    .ec-headerTitle .ec-headerTitle__subtitle {
      font-size: 16px;
      margin-bottom: 10px; } }
  .ec-headerTitle .ec-headerTitle__subtitle a {
    display: inline-block;
    color: #0092C4;
    text-decoration: none;
    cursor: pointer; }

/*
ナビゲーション

ヘッダー内で使用されるナビゲーションコンポーネントです。

Markup:
include /assets/tmpl/elements/11.2.header.pug
+ec-headerNav

sg-wrapper:
<div class="ec-headerRole">
  <div class="ec-headerRole__nav">
    <sg-wrapper-content/>
  </div>
</div>

Styleguide 11.2.3
*/
.ec-headerNavSP {
  margin-right: 10px;
  width: 100%;
  height: auto;
  vertical-align: top;
  text-align: right; }
  .ec-headerNavSP .ec-headerNavSP__item {
    display: inline-block;
    margin-right: 10px;
    width: auto;
    text-align: center; }
  .ec-headerNavSP .ec-headerNavSP__item:nth-of-type(2) {
    margin-left: 5px; }
  .ec-headerNavSP .ec-cartNaviSP {
    display: inline-block;
    font-size: 30px;
    cursor: pointer;
    position: relative; }
    .ec-headerNavSP .ec-cartNaviSP .ec-cartNaviSP__icon {
      display: inline-block;
      width: 30px;
      height: 30px;
      color: #525263; }
    .ec-headerNavSP .ec-cartNaviSP .ec-cartNaviSP__badge {
      display: inline-block;
      padding: 3px 7px;
      min-width: 10px;
      font-size: 10px;
      font-weight: 700;
      line-height: 1;
      color: #fff;
      text-align: center;
      white-space: nowrap;
      vertical-align: baseline;
      background: #DE5D50;
      border-radius: 10px;
      position: absolute;
      top: 0;
      right: -10px; }
  .ec-headerNavSP .ec-headerNavSP__itemMenu {
    display: block;
    position: relative;
    width: 25px;
    height: 15px;
    cursor: pointer; }
    .ec-headerNavSP .ec-headerNavSP__itemMenu span {
      display: inline-block;
      transition: all .4s;
      box-sizing: border-box;
      position: absolute;
      left: 0;
      width: 100%;
      height: 3px;
      background: #525263; }
    .ec-headerNavSP .ec-headerNavSP__itemMenu span:nth-of-type(1) {
      top: 0;
      transform: translateY(0) rotate(0);
      transition: all .3s; }
    .ec-headerNavSP .ec-headerNavSP__itemMenu span:nth-of-type(2) {
      top: 6px;
      opacity: 1;
      transition: all .3s; }
    .ec-headerNavSP .ec-headerNavSP__itemMenu span:nth-of-type(3) {
      bottom: 0;
      transform: translateY(0) rotate(0);
      transition: all .3s; }

.have_curtain .ec-headerNavSP__itemMenu span:nth-of-type(1) {
  transform: translateY(2px) rotate(-45deg);
  transition: all .3s; }

.have_curtain .ec-headerNavSP__itemMenu span:nth-of-type(2) {
  opacity: 0;
  transition: all .3s; }

.have_curtain .ec-headerNavSP__itemMenu span:nth-of-type(3) {
  transform: translateY(-10px) rotate(45deg);
  transition: all .3s; }

/**
メディアクエリ
SP フォーストで記述する。
Twitter Bootstrap デフォルト準拠
 */
/**
 * ECCUBE 固有のスタイルユーティリティ
 */
.ec-headerNaviRole {
  margin-right: auto;
  margin-left: auto;
  padding-left: 10px;
  padding-right: 10px;
  box-sizing: border-box;
  font-size: 16px;
  font-family: Roboto, "游ゴシック", YuGothic, "Yu Gothic", "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", Arial, "メイリオ", Meiryo, sans-serif;
  line-height: 1.4;
  color: #525263;
  -webkit-text-size-adjust: 100%;
  width: 100%;
  max-width: 1150px; }
  .ec-headerNaviRole:after {
    content: " ";
    display: table; }
  .ec-headerNaviRole:after {
    clear: both; }
  .ec-headerNaviRole textarea {
    /* for chrome fontsize bug */
    font-family: sans-serif; }
  .ec-headerNaviRole img {
    max-width: 100%; }
  .ec-headerNaviRole html {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box; }
  .ec-headerNaviRole *,
  .ec-headerNaviRole *::before,
  .ec-headerNaviRole *::after {
    -webkit-box-sizing: inherit;
    -moz-box-sizing: inherit;
    box-sizing: inherit; }
  .ec-headerNaviRole img {
    width: 100%; }
  .ec-headerNaviRole:after {
    content: " ";
    display: table; }
  .ec-headerNaviRole:after {
    clear: both; }
  .ec-headerNaviRole .ec-headerNaviRole__search {
    display: none; }
    @media only screen and (min-width: 768px) {
      .ec-headerNaviRole .ec-headerNaviRole__search {
        display: block;
        float: left;
        width: 370px; }
        .ec-headerNaviRole .ec-headerNaviRole__search a {
          color: inherit;
          text-decoration: none; }
        .ec-headerNaviRole .ec-headerNaviRole__search a:hover {
          text-decoration: none; } }
  .ec-headerNaviRole .ec-headerNaviRole__nav {
    display: none; }
    @media only screen and (min-width: 768px) {
      .ec-headerNaviRole .ec-headerNaviRole__nav {
        display: block;
        float: right;
        width: 50%; }
        .ec-headerNaviRole .ec-headerNaviRole__nav a {
          color: inherit;
          text-decoration: none; }
        .ec-headerNaviRole .ec-headerNaviRole__nav a:hover {
          text-decoration: none; } }

/*
検索ボックス

ヘッダー内で使用される商品検索コンポーネントです。

Markup:
include /assets/tmpl/elements/11.3.headerNavi.pug
+ec-headerSearch

sg-wrapper:
<div class="ec-headerRole">
  <div class="ec-headerRole__title">
    <sg-wrapper-content/>
  </div>
</div>

Styleguide 11.3.1
*/
.ec-headerSearch:after {
  content: " ";
  display: table; }

.ec-headerSearch:after {
  clear: both; }

.ec-headerSearch .ec-headerSearch__category {
  float: none; }
  @media only screen and (min-width: 768px) {
    .ec-headerSearch .ec-headerSearch__category {
      float: left; } }
  .ec-headerSearch .ec-headerSearch__category .ec-select {
    margin-bottom: 6px; }
  .ec-headerSearch .ec-headerSearch__category select {
    width: auto;
    max-width: 100%;
    height: 50px;
    border: 1px solid #ccc;
    font-size: 16px;
    font-size: 1.6rem;
    padding: 0.5em 1em;
    border-radius: 0;
    box-shadow: none;
    box-sizing: border-box; }
    @media only screen and (min-width: 768px) {
      .ec-headerSearch .ec-headerSearch__category select {
        max-width: 180px; } }

.ec-headerSearch .ec-headerSearch__keyword {
  float: none;
  height: 50px;
  padding: 0 35px 0 18px;
  border-bottom: 1px solid #ccc;
  position: relative;
  margin-left: 2%;
  color: #525263; }
  @media only screen and (min-width: 768px) {
    .ec-headerSearch .ec-headerSearch__keyword {
      float: left; } }
  .ec-headerSearch .ec-headerSearch__keyword input[type="search"] {
    height: 50px;
    width: 100%;
    font-size: 16px;
    font-size: 1.6rem;
    border: 0 none;
    padding: 0.5em 0;
    box-shadow: none;
    background: none;
    box-sizing: border-box; }
  .ec-headerSearch .ec-headerSearch__keyword .ec-icon svg, .ec-headerSearch .ec-headerSearch__keyword .ec-icon--colored svg {
    fill: #636378; }

.ec-headerSearch .ec-headerSearch__keywordBtn {
  border: 0;
  background: none;
  position: absolute;
  right: 0;
  top: 6px;
  display: block;
  font-size: 25px;
  overflow: hidden;
  white-space: nowrap;
  z-index: 1; }

/*
ナビゲーション

ヘッダー内で使用されるナビゲーションコンポーネントです。

Markup:
include /assets/tmpl/elements/11.3.headerNavi.pug
+ec-headerNav

sg-wrapper:
<div class="ec-headerRole">
  <div class="ec-headerRole__nav">
    <sg-wrapper-content/>
  </div>
</div>

Styleguide 11.3.2
*/
.ec-headerNav {
  text-align: right; }
  .ec-headerNav .ec-headerNav__item {
    margin-left: 0;
    display: inline-block;
    font-size: 28px;
    line-height: 50px; }
    @media only screen and (min-width: 768px) {
      .ec-headerNav .ec-headerNav__item {
        margin-left: 10px; } }
    .ec-headerNav .ec-headerNav__item a {
      color: inherit;
      text-decoration: none; }
    .ec-headerNav .ec-headerNav__item a:hover {
      text-decoration: none; }
    .ec-headerNav .ec-headerNav__item span {
      font-size: 14px; }

/**
メディアクエリ
SP フォーストで記述する。
Twitter Bootstrap デフォルト準拠
 */
/**
 * ECCUBE 固有のスタイルユーティリティ
 */
/*
ヘッダー:カテゴリリスト

ヘッダー用のプロジェクトコンポーネントを提供します。

ex [商品詳細ページ　ヘッダー](http://demo3.ec-cube.net/products/detail/33)

Markup:
include /assets/tmpl/elements/11.2.header.pug
+ec-headerRole
p hoge

Styleguide 11.4
*/
.ec-cateoryNaviRole {
  margin-right: auto;
  margin-left: auto;
  padding-left: 10px;
  padding-right: 10px;
  box-sizing: border-box;
  font-size: 16px;
  font-family: Roboto, "游ゴシック", YuGothic, "Yu Gothic", "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", Arial, "メイリオ", Meiryo, sans-serif;
  line-height: 1.4;
  color: #525263;
  -webkit-text-size-adjust: 100%;
  width: 100%;
  max-width: 1150px;
  display: none; }
  .ec-cateoryNaviRole:after {
    content: " ";
    display: table; }
  .ec-cateoryNaviRole:after {
    clear: both; }
  .ec-cateoryNaviRole textarea {
    /* for chrome fontsize bug */
    font-family: sans-serif; }
  .ec-cateoryNaviRole img {
    max-width: 100%; }
  .ec-cateoryNaviRole html {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box; }
  .ec-cateoryNaviRole *,
  .ec-cateoryNaviRole *::before,
  .ec-cateoryNaviRole *::after {
    -webkit-box-sizing: inherit;
    -moz-box-sizing: inherit;
    box-sizing: inherit; }
  .ec-cateoryNaviRole img {
    width: 100%; }
  @media only screen and (min-width: 768px) {
    .ec-cateoryNaviRole {
      display: block;
      width: 100%; }
      .ec-cateoryNaviRole a {
        color: inherit;
        text-decoration: none; }
      .ec-cateoryNaviRole a:hover {
        text-decoration: none; } }

/*
カテゴリナビ

カテゴリ一覧として使用します。

Markup:
include /assets/tmpl/elements/11.4.categoryNavi.pug
+ec-itemNav

sg-wrapper:
<div class="ec-headerRole">
  <div class="ec-headerRole__itemnav">
    <sg-wrapper-content/>
  </div>
</div>

Styleguide 11.4.1
*/
.ec-itemNav {
  display: block;
  border: 0;
  margin: 0;
  padding: 0;
  width: 100%;
  text-align: center;
  list-style: none;
  position: relative; }
  @media only screen and (min-width: 768px) {
    .ec-itemNav {
      margin: 20px 0 30px;
      border-bottom: 1px solid #D9D9D9;
      border-top: 1px solid #D9D9D9; } }
  .ec-itemNav .ec-itemNav__item {
    display: inline-block;
    font-weight: bold; }
    .ec-itemNav .ec-itemNav__item a {
      color: inherit;
      text-decoration: none; }
    .ec-itemNav .ec-itemNav__item a:hover {
      text-decoration: none; }
    .ec-itemNav .ec-itemNav__item:hover {
      background: #fafafa; }
    .ec-itemNav .ec-itemNav__item a {
      display: inline-block;
      margin-top: 0;
      padding: 16px;
      height: 55px; }
    .ec-itemNav .ec-itemNav__item:hover > .ec-itemNavAccordion {
      overflow: visible;
      height: 50px;
      transition: all .2s ease-out; }
      .ec-itemNav .ec-itemNav__item:hover > .ec-itemNavAccordion .ec-itemNavAccordion__item {
        height: 50px;
        transition: all .2s ease-out; }
  .ec-itemNav .ec-itemNavAccordion {
    z-index: 1000;
    position: absolute;
    padding: 0;
    height: 0;
    overflow: hidden;
    transition: all .2s ease-out; }
    .ec-itemNav .ec-itemNavAccordion .ec-itemNavAccordion__item {
      border-bottom: 1px solid #ccc;
      list-style: none;
      height: 0;
      transition: all .2s ease-out; }
      .ec-itemNav .ec-itemNavAccordion .ec-itemNavAccordion__item a {
        list-style: none;
        border-top: 1px solid #E8E8E8;
        border-bottom: 1px solid #E8E8E8;
        margin-top: 0;
        padding: 15px 10px;
        min-width: 200px;
        width: 100%;
        text-align: left;
        background: #fff;
        transition: all .5s ease-out; }
        .ec-itemNav .ec-itemNavAccordion .ec-itemNavAccordion__item a:hover {
          background: #fafafa; }

/**
メディアクエリ
SP フォーストで記述する。
Twitter Bootstrap デフォルト準拠
 */
/**
 * ECCUBE 固有のスタイルユーティリティ
 */
/*
ヘッダー

ヘッダー用のプロジェクトコンポーネントを提供します。

ex [商品詳細ページ　ヘッダー](http://demo3.ec-cube.net/products/detail/33)

Markup:
include /assets/tmpl/elements/11.2.header.pug
+ec-headerRole
p hoge

Styleguide 11.5
*/
.ec-drawerRole {
  display: block;
  background: #F6F6F6;
  position: fixed;
  top: 0;
  width: 260px;
  height: 100vh;
  right: 0;
  z-index: -1;
  transition: z-index 0ms 1ms; }
  @media only screen and (min-width: 768px) {
    .ec-drawerRole {
      display: none; } }

.ec-drawerRole.is_active {
  z-index: 1;
  transition: z-index 0ms 300ms; }
  @media only screen and (min-width: 768px) {
    .ec-drawerRole.is_active {
      display: none; } }

/*
ドロワー内における検索ボックスの表示


Markup:
include /assets/tmpl/elements/11.2.header.pug
.ec-drawerRole
p hoge

Styleguide 11.5.1
*/
.ec-drawerRole .ec-headerSearch {
  padding: 16px 8px 26px;
  background: #EBEBEB;
  color: #636378; }
  .ec-drawerRole .ec-headerSearch select {
    width: 100% !important; }

.ec-drawerRole .ec-headerNav .ec-headerNav__item {
  text-align: left;
  display: block;
  padding-left: 10px;
  height: 60px;
  border-bottom: 1px solid #e8e8e8;
  line-height: 50px;
  font-size: 30px; }
  @media only screen and (min-width: 768px) {
    .ec-drawerRole .ec-headerNav .ec-headerNav__item {
      padding: 0 10px;
      height: 50px; } }

.ec-drawerRole .ec-itemNav .ec-itemNav__item {
  display: block;
  text-align: left;
  border-bottom: 1px solid #e8e8e8;
  line-height: 50px;
  font-weight: bold; }
  .ec-drawerRole .ec-itemNav .ec-itemNav__item a {
    padding: 0 10px;
    height: 50px; }

.ec-drawerRole .ec-itemNav .ec-itemNavAccordion {
  display: none; }

/**
メディアクエリ
SP フォーストで記述する。
Twitter Bootstrap デフォルト準拠
 */
@keyframes fadeIn {
  0% {
    opacity: 0;
    visibility: hidden; }
  100% {
    opacity: 1;
    visibility: visible; } }

@keyframes fadeOut {
  0% {
    opacity: 1;
    visibility: visible; }
  100% {
    opacity: 0;
    visibility: hidden; } }

/**
 * ECCUBE 固有のスタイルユーティリティ
 */
/*
ヘッダー

ヘッダー用のプロジェクトコンポーネントを提供します。

ex [商品詳細ページ　ヘッダー](http://demo3.ec-cube.net/products/detail/33)

Markup:
include /assets/tmpl/elements/11.2.header.pug
+ec-headerRole

Styleguide 11.6
*/
.ec-overlayRole {
  position: absolute;
  width: 100%;
  height: 100vh;
  top: 77px;
  left: 0;
  opacity: 0;
  background: transparent;
  transform: translateX(0);
  transition: all .3s;
  opacity: 0;
  visibility: hidden;
  animation: fadeOut 150ms linear 0s; }

.have_curtain .ec-overlayRole {
  opacity: 1;
  background: rgba(0, 0, 0, 0.5);
  transform: translateX(-260px);
  transition: all .3s;
  display: block;
  opacity: 1;
  visibility: visible;
  animation: fadeIn 150ms linear 0s; }

/**
メディアクエリ
SP フォーストで記述する。
Twitter Bootstrap デフォルト準拠
 */
/**
 * ECCUBE 固有のスタイルユーティリティ
 */
/*
ヘッダー

ヘッダー用のプロジェクトコンポーネントを提供します。

ex [商品詳細ページ　ヘッダー](http://demo3.ec-cube.net/products/detail/33)

Markup:
include /assets/tmpl/elements/11.2.header.pug
+ec-headerRole
p hoge

Styleguide 11.7
*/
.ec-layoutRole {
  width: 100%;
  transition: transform 0.3s;
  background: #fff; }
  .ec-layoutRole .ec-layoutRole__contentTop {
    padding: 0; }
  .ec-layoutRole .ec-layoutRole__contents {
    margin-right: auto;
    margin-left: auto;
    width: 100%;
    max-width: 1150px;
    display: flex;
    flex-wrap: nowrap; }
  .ec-layoutRole .ec-layoutRole__main {
    width: 100%; }
  .ec-layoutRole .ec-layoutRole__mainWithColumn {
    width: 100%; }
    @media only screen and (min-width: 768px) {
      .ec-layoutRole .ec-layoutRole__mainWithColumn {
        width: 75%; } }
  .ec-layoutRole .ec-layoutRole__mainBetweenColumn {
    width: 100%; }
    @media only screen and (min-width: 768px) {
      .ec-layoutRole .ec-layoutRole__mainBetweenColumn {
        width: 50%; } }
  .ec-layoutRole .ec-layoutRole__left,
  .ec-layoutRole .ec-layoutRole__right {
    display: none; }
    @media only screen and (min-width: 768px) {
      .ec-layoutRole .ec-layoutRole__left,
      .ec-layoutRole .ec-layoutRole__right {
        display: block;
        width: 25%; } }

.ec-layoutRole.is_active {
  transform: translateX(-260px);
  transition: transform 0.3s;
  z-index: 10; }
  @media only screen and (min-width: 768px) {
    .ec-layoutRole.is_active {
      transform: translateX(0px); } }

/**
メディアクエリ
SP フォーストで記述する。
Twitter Bootstrap デフォルト準拠
 */
/**
 * ECCUBE 固有のスタイルユーティリティ
 */
/*
フッター

全ページで使用されるフッターのプロジェクトコンポーネントです。

ex [商品詳細ページ　フッター](http://demo3.ec-cube.net/products/detail/33)

Markup:
include /assets/tmpl/elements/11.8.footer.pug
+ec-footerRole

Styleguide 11.3
*/
.ec-footerRole {
  background: #f8f8f8;
  border-top: 1px solid #e8e8e8;
  padding-top: 12px;
  padding-bottom: 24px;
  margin-top: 36px; }
  @media only screen and (min-width: 768px) {
    .ec-footerRole {
      padding-top: 32px;
      margin-top: 80px; } }
  .ec-footerRole .ec-footerRole__inner {
    margin-right: auto;
    margin-left: auto;
    padding-left: 10px;
    padding-right: 10px;
    box-sizing: border-box;
    font-size: 16px;
    font-family: Roboto, "游ゴシック", YuGothic, "Yu Gothic", "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", Arial, "メイリオ", Meiryo, sans-serif;
    line-height: 1.4;
    color: #525263;
    -webkit-text-size-adjust: 100%;
    width: 100%;
    max-width: 1150px; }
    .ec-footerRole .ec-footerRole__inner:after {
      content: " ";
      display: table; }
    .ec-footerRole .ec-footerRole__inner:after {
      clear: both; }
    .ec-footerRole .ec-footerRole__inner textarea {
      /* for chrome fontsize bug */
      font-family: sans-serif; }
    .ec-footerRole .ec-footerRole__inner img {
      max-width: 100%; }
    .ec-footerRole .ec-footerRole__inner html {
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box; }
    .ec-footerRole .ec-footerRole__inner *,
    .ec-footerRole .ec-footerRole__inner *::before,
    .ec-footerRole .ec-footerRole__inner *::after {
      -webkit-box-sizing: inherit;
      -moz-box-sizing: inherit;
      box-sizing: inherit; }
    .ec-footerRole .ec-footerRole__inner img {
      width: 100%; }

/*
フッターナビ

フッタープロジェクトで使用するナビゲーション用のコンポーネントです。

ulタグを用いてリスト要素として宣言します。

ex [商品詳細ページ　フッターナビ(http://demo3.ec-cube.net/products/detail/33)

Markup:
include /assets/tmpl/elements/11.8.footer.pug
+ec-footerNav

sg-wrapper:
<div class="ec-footerRole">
  <div class="ec-footerRole__inner">
    <sg-wrapper-content/>
  </div>
</div>

Styleguide 11.8.1
*/
.ec-footerNavi {
  list-style: none;
  text-align: center;
  padding: 0; }
  .ec-footerNavi .ec-footerNavi__link {
    display: inline-block;
    margin: 0 8px 8px; }
    .ec-footerNavi .ec-footerNavi__link a {
      color: inherit;
      text-decoration: none; }
    .ec-footerNavi .ec-footerNavi__link:hover a {
      color: #686868;
      text-decoration: none; }

/*
フッタータイトル

フッタープロジェクトで使用するタイトル用のコンポーネントです。

ulタグを用いてリスト要素として宣言します。

ex [商品詳細ページ　フッタータイトル](http://demo3.ec-cube.net/products/detail/33)

Markup:
include /assets/tmpl/elements/11.8.footer.pug
+ec-footerTitle

sg-wrapper:
<div class="ec-footerRole">
  <div class="ec-footerRole__inner">
    <sg-wrapper-content/>
  </div>
</div>

Styleguide 11.8.2
*/
.ec-footerTitle {
  margin-top: 20px;
  text-align: center; }
  @media only screen and (min-width: 768px) {
    .ec-footerTitle {
      margin-top: 80px; } }
  .ec-footerTitle .ec-footerTitle__logo {
    font-weight: bold; }
    .ec-footerTitle .ec-footerTitle__logo a {
      color: inherit;
      text-decoration: none; }
    .ec-footerTitle .ec-footerTitle__logo a:hover {
      text-decoration: none; }
    .ec-footerTitle .ec-footerTitle__logo a {
      font-size: 16px;
      color: inherit; }
      @media only screen and (min-width: 768px) {
        .ec-footerTitle .ec-footerTitle__logo a {
          font-size: 2em; } }
    .ec-footerTitle .ec-footerTitle__logo:hover a {
      color: #686868;
      text-decoration: none; }
  .ec-footerTitle .ec-footerTitle__copyright {
    font-size: 10px; }
    @media only screen and (min-width: 768px) {
      .ec-footerTitle .ec-footerTitle__copyright {
        font-size: .8em; } }

/**
メディアクエリ
SP フォーストで記述する。
Twitter Bootstrap デフォルト準拠
 */
/*
トップページ

トップページ スライド部に関する Project コンポーネントを定義します。

ex [トップページ](http://demo3.ec-cube.net/)

Markup:
include /assets/tmpl/elements/12.1.slider.pug
+ec-sliderRole

Styleguide 12.1
*/
.ec-sliderRole {
  margin-right: auto;
  margin-left: auto;
  padding-left: 10px;
  padding-right: 10px;
  box-sizing: border-box;
  font-size: 16px;
  font-family: Roboto, "游ゴシック", YuGothic, "Yu Gothic", "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", Arial, "メイリオ", Meiryo, sans-serif;
  line-height: 1.4;
  color: #525263;
  -webkit-text-size-adjust: 100%;
  width: 100%;
  max-width: 1150px;
  margin-bottom: 24px; }
  .ec-sliderRole:after {
    content: " ";
    display: table; }
  .ec-sliderRole:after {
    clear: both; }
  .ec-sliderRole textarea {
    /* for chrome fontsize bug */
    font-family: sans-serif; }
  .ec-sliderRole img {
    max-width: 100%; }
  .ec-sliderRole html {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box; }
  .ec-sliderRole *,
  .ec-sliderRole *::before,
  .ec-sliderRole *::after {
    -webkit-box-sizing: inherit;
    -moz-box-sizing: inherit;
    box-sizing: inherit; }
  .ec-sliderRole img {
    width: 100%; }
  .ec-sliderRole ul {
    padding: 0;
    list-style: none; }

.ec-sliderItemRole {
  margin-right: auto;
  margin-left: auto;
  padding-left: 10px;
  padding-right: 10px;
  box-sizing: border-box;
  font-size: 16px;
  font-family: Roboto, "游ゴシック", YuGothic, "Yu Gothic", "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", Arial, "メイリオ", Meiryo, sans-serif;
  line-height: 1.4;
  color: #525263;
  -webkit-text-size-adjust: 100%;
  width: 100%;
  max-width: 1150px;
  margin-bottom: 24px; }
  .ec-sliderItemRole:after {
    content: " ";
    display: table; }
  .ec-sliderItemRole:after {
    clear: both; }
  .ec-sliderItemRole textarea {
    /* for chrome fontsize bug */
    font-family: sans-serif; }
  .ec-sliderItemRole img {
    max-width: 100%; }
  .ec-sliderItemRole html {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box; }
  .ec-sliderItemRole *,
  .ec-sliderItemRole *::before,
  .ec-sliderItemRole *::after {
    -webkit-box-sizing: inherit;
    -moz-box-sizing: inherit;
    box-sizing: inherit; }
  .ec-sliderItemRole img {
    width: 100%; }
  .ec-sliderItemRole ul {
    padding: 0;
    list-style: none; }
  .ec-sliderItemRole .item_nav {
    display: none; }
    @media only screen and (min-width: 768px) {
      .ec-sliderItemRole .item_nav {
        display: flex;
        justify-content: space-between;
        margin-bottom: 0; } }
  .ec-sliderItemRole .slideThumb {
    margin-bottom: 25px;
    opacity: .8; }
    .ec-sliderItemRole .slideThumb:focus {
      outline: none; }
    .ec-sliderItemRole .slideThumb:hover {
      opacity: 1; }
    .ec-sliderItemRole .slideThumb img {
      width: 80%; }

/**
メディアクエリ
SP フォーストで記述する。
Twitter Bootstrap デフォルト準拠
 */
/*
アイキャッチ

トップページ アイキャッチ部に関する Project コンポーネントを定義します。

ex [トップページスライダー直下 アイキャッチ部](http://demo3.ec-cube.net/)

Markup:
include /assets/tmpl/elements/12.2.eyecatch.pug
+ec-eyecatchRole

Styleguide 12.2
*/
.ec-eyecatchRole {
  margin-right: auto;
  margin-left: auto;
  padding-left: 10px;
  padding-right: 10px;
  box-sizing: border-box;
  font-size: 16px;
  font-family: Roboto, "游ゴシック", YuGothic, "Yu Gothic", "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", Arial, "メイリオ", Meiryo, sans-serif;
  line-height: 1.4;
  color: #525263;
  -webkit-text-size-adjust: 100%;
  width: 100%;
  max-width: 1150px;
  margin-bottom: 30px;
  text-align: center; }
  .ec-eyecatchRole:after {
    content: " ";
    display: table; }
  .ec-eyecatchRole:after {
    clear: both; }
  .ec-eyecatchRole textarea {
    /* for chrome fontsize bug */
    font-family: sans-serif; }
  .ec-eyecatchRole img {
    max-width: 100%; }
  .ec-eyecatchRole html {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box; }
  .ec-eyecatchRole *,
  .ec-eyecatchRole *::before,
  .ec-eyecatchRole *::after {
    -webkit-box-sizing: inherit;
    -moz-box-sizing: inherit;
    box-sizing: inherit; }
  .ec-eyecatchRole img {
    width: 100%; }
  .ec-eyecatchRole .ec-eyecatchRole__intro {
    box-sizing: border-box;
    padding: 0 3%; }
  .ec-eyecatchRole .ec-eyecatchRole__introTitle {
    margin-top: 10px;
    font-size: 24px;
    font-weight: bold; }
    @media only screen and (min-width: 768px) {
      .ec-eyecatchRole .ec-eyecatchRole__introTitle {
        font-size: 32px; } }
  .ec-eyecatchRole p {
    font-size: 16px; }
    @media only screen and (min-width: 768px) {
      .ec-eyecatchRole p {
        font-size: 20px; } }

/*
トップページ

アイキャッチで使用する 分割画像レイアウトです。

ex [トップページ](http://demo3.ec-cube.net/)

Markup:
include /assets/tmpl/elements/12.2.eyecatch.pug
+ec-eyecatchTop

sg-wrapper:
<div class="ec-eyecatchRole">
  <sg-wrapper-content/>
</div>



Styleguide 12.2.1
*/
.ec-splitImg {
  display: flex; }
  .ec-splitImg .ec-splitImg__item {
    width: 50%; }
    .ec-splitImg .ec-splitImg__item img {
      width: 100%; }
  .ec-splitImg .ec-splitImg__item:nth-of-type(1) {
    padding-right: 8px; }
    @media only screen and (min-width: 768px) {
      .ec-splitImg .ec-splitImg__item:nth-of-type(1) {
        padding-right: 3%; } }
  .ec-splitImg .ec-splitImg__item:nth-of-type(2) {
    padding-left: 8px; }
    @media only screen and (min-width: 768px) {
      .ec-splitImg .ec-splitImg__item:nth-of-type(2) {
        padding-left: 3%; } }

/**
メディアクエリ
SP フォーストで記述する。
Twitter Bootstrap デフォルト準拠
 */
/*
検索ラベル

商品一覧 ヘッダー部 に関する Project コンポーネントを定義します。

ex [商品一覧 ヘッダー部](http://demo3.ec-cube.net/products/list)

Markup:
include /assets/tmpl/elements/13.1.searchnav.pug
+ec-searchnavRole__topicpath
+ec-searchnavRole__info

Styleguide 13.1

*/
.ec-searchnavRole {
  margin-bottom: 0;
  padding: 0; }
  @media only screen and (min-width: 768px) {
    .ec-searchnavRole {
      margin-right: auto;
      margin-left: auto;
      padding-left: 10px;
      padding-right: 10px;
      box-sizing: border-box;
      font-size: 16px;
      font-family: Roboto, "游ゴシック", YuGothic, "Yu Gothic", "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", Arial, "メイリオ", Meiryo, sans-serif;
      line-height: 1.4;
      color: #525263;
      -webkit-text-size-adjust: 100%;
      width: 100%;
      max-width: 1150px; }
      .ec-searchnavRole:after {
        content: " ";
        display: table; }
      .ec-searchnavRole:after {
        clear: both; }
      .ec-searchnavRole textarea {
        /* for chrome fontsize bug */
        font-family: sans-serif; }
      .ec-searchnavRole img {
        max-width: 100%; }
      .ec-searchnavRole html {
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box; }
      .ec-searchnavRole *,
      .ec-searchnavRole *::before,
      .ec-searchnavRole *::after {
        -webkit-box-sizing: inherit;
        -moz-box-sizing: inherit;
        box-sizing: inherit; }
      .ec-searchnavRole img {
        width: 100%; } }
  .ec-searchnavRole .ec-searchnavRole__infos {
    margin-right: auto;
    margin-left: auto;
    padding-left: 10px;
    padding-right: 10px;
    box-sizing: border-box;
    font-size: 16px;
    font-family: Roboto, "游ゴシック", YuGothic, "Yu Gothic", "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", Arial, "メイリオ", Meiryo, sans-serif;
    line-height: 1.4;
    color: #525263;
    -webkit-text-size-adjust: 100%;
    width: 100%;
    max-width: 1150px;
    display: flex;
    border-top: 0;
    margin-bottom: 16px;
    padding-top: 5px;
    flex-direction: column; }
    .ec-searchnavRole .ec-searchnavRole__infos:after {
      content: " ";
      display: table; }
    .ec-searchnavRole .ec-searchnavRole__infos:after {
      clear: both; }
    .ec-searchnavRole .ec-searchnavRole__infos textarea {
      /* for chrome fontsize bug */
      font-family: sans-serif; }
    .ec-searchnavRole .ec-searchnavRole__infos img {
      max-width: 100%; }
    .ec-searchnavRole .ec-searchnavRole__infos html {
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box; }
    .ec-searchnavRole .ec-searchnavRole__infos *,
    .ec-searchnavRole .ec-searchnavRole__infos *::before,
    .ec-searchnavRole .ec-searchnavRole__infos *::after {
      -webkit-box-sizing: inherit;
      -moz-box-sizing: inherit;
      box-sizing: inherit; }
    .ec-searchnavRole .ec-searchnavRole__infos img {
      width: 100%; }
    @media only screen and (min-width: 768px) {
      .ec-searchnavRole .ec-searchnavRole__infos {
        border-top: 1px solid #ccc;
        padding-top: 16px;
        flex-direction: row; } }
  .ec-searchnavRole .ec-searchnavRole__counter {
    margin-bottom: 16px;
    width: 100%; }
    @media only screen and (min-width: 768px) {
      .ec-searchnavRole .ec-searchnavRole__counter {
        margin-bottom: 0;
        width: 50%; } }
  .ec-searchnavRole .ec-searchnavRole__actions {
    text-align: right;
    width: 100%; }
    @media only screen and (min-width: 768px) {
      .ec-searchnavRole .ec-searchnavRole__actions {
        width: 50%; } }

/**
メディアクエリ
SP フォーストで記述する。
Twitter Bootstrap デフォルト準拠
 */
/**
 * ECCUBE 固有のスタイルユーティリティ
 */
/*
商品一覧

商品一覧 に関する Project コンポーネントを定義します。

SP版２列、PC版４列の特殊グリッドを構成します。

ex [商品一覧 商品掲載部](http://demo3.ec-cube.net/products/list)

Markup:
include /assets/tmpl/elements/13.2.shelf.pug
+b.ec-shelfRole
  +ec-shelfGrid

Styleguide 13.2

*/
.ec-shelfRole {
  margin-right: auto;
  margin-left: auto;
  padding-left: 10px;
  padding-right: 10px;
  box-sizing: border-box;
  font-size: 16px;
  font-family: Roboto, "游ゴシック", YuGothic, "Yu Gothic", "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", Arial, "メイリオ", Meiryo, sans-serif;
  line-height: 1.4;
  color: #525263;
  -webkit-text-size-adjust: 100%;
  width: 100%;
  max-width: 1150px; }
  .ec-shelfRole:after {
    content: " ";
    display: table; }
  .ec-shelfRole:after {
    clear: both; }
  .ec-shelfRole textarea {
    /* for chrome fontsize bug */
    font-family: sans-serif; }
  .ec-shelfRole img {
    max-width: 100%; }
  .ec-shelfRole html {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box; }
  .ec-shelfRole *,
  .ec-shelfRole *::before,
  .ec-shelfRole *::after {
    -webkit-box-sizing: inherit;
    -moz-box-sizing: inherit;
    box-sizing: inherit; }
  .ec-shelfRole img {
    width: 100%; }

/*
商品一覧グリッド

商品一覧 で使用するグリッドコンポーネントです。

SP版２列、PC版４列の特殊グリッドを構成します。

Markup:
include /assets/tmpl/elements/13.2.shelf.pug
+b.ec-shelfRole
  +ec-shelfGrid

Styleguide 13.2.1

*/
.ec-shelfGrid {
  display: flex;
  margin-left: 0;
  margin-right: 0;
  flex-wrap: wrap;
  padding: 0;
  list-style: none; }
  .ec-shelfGrid a {
    color: inherit;
    text-decoration: none; }
  .ec-shelfGrid a:hover {
    text-decoration: none; }
  @media only screen and (min-width: 768px) {
    .ec-shelfGrid {
      margin-left: -16px;
      margin-right: -16px; } }
  .ec-shelfGrid .ec-shelfGrid__item {
    margin-bottom: 30px;
    width: 50%; }
    .ec-shelfGrid .ec-shelfGrid__item img {
      margin-bottom: 15px;
      width: 100%; }
    @media only screen and (min-width: 768px) {
      .ec-shelfGrid .ec-shelfGrid__item {
        padding: 0 16px;
        width: 25%; } }
  .ec-shelfGrid .ec-shelfGrid__item:nth-child(odd) {
    padding-right: 8px; }
    @media only screen and (min-width: 768px) {
      .ec-shelfGrid .ec-shelfGrid__item:nth-child(odd) {
        padding: 0 16px; } }
  .ec-shelfGrid .ec-shelfGrid__item:nth-child(even) {
    padding-left: 8px; }
    @media only screen and (min-width: 768px) {
      .ec-shelfGrid .ec-shelfGrid__item:nth-child(even) {
        padding: 0 16px; } }

/*
商品一覧フッター

商品一覧 フッター に関する Project コンポーネントを定義します。

ex [商品一覧 ページャ部](http://demo3.ec-cube.net/products/list)

Markup:
include /assets/tmpl/elements/13.3.pager.pug
+ec-pagerRole

Styleguide 13.3

*/
/**
メディアクエリ
SP フォーストで記述する。
Twitter Bootstrap デフォルト準拠
 */
/*
商品詳細

商品詳細ページに関する Project コンポーネントを定義します。

ex [商品詳細ページ](http://demo3.ec-cube.net/products/detail/18)


Markup:
include /assets/tmpl/elements/14.1.product.pug
+ec-productSimpleRole

Styleguide 14.1
*/
.ec-productRole {
  margin-right: auto;
  margin-left: auto;
  padding-left: 10px;
  padding-right: 10px;
  box-sizing: border-box;
  font-size: 16px;
  font-family: Roboto, "游ゴシック", YuGothic, "Yu Gothic", "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", Arial, "メイリオ", Meiryo, sans-serif;
  line-height: 1.4;
  color: #525263;
  -webkit-text-size-adjust: 100%;
  width: 100%;
  max-width: 1150px; }
  .ec-productRole:after {
    content: " ";
    display: table; }
  .ec-productRole:after {
    clear: both; }
  .ec-productRole textarea {
    /* for chrome fontsize bug */
    font-family: sans-serif; }
  .ec-productRole img {
    max-width: 100%; }
  .ec-productRole html {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box; }
  .ec-productRole *,
  .ec-productRole *::before,
  .ec-productRole *::after {
    -webkit-box-sizing: inherit;
    -moz-box-sizing: inherit;
    box-sizing: inherit; }
  .ec-productRole img {
    width: 100%; }
  .ec-productRole .ec-productRole__img {
    margin-right: 0;
    margin-bottom: 20px; }
    @media only screen and (min-width: 768px) {
      .ec-productRole .ec-productRole__img {
        margin-right: 16px;
        margin-bottom: 0; } }
  .ec-productRole .ec-productRole__profile {
    margin-left: 0; }
    @media only screen and (min-width: 768px) {
      .ec-productRole .ec-productRole__profile {
        margin-left: 16px; } }
  .ec-productRole .ec-productRole__title .ec-headingTitle {
    font-size: 20px; }
    @media only screen and (min-width: 768px) {
      .ec-productRole .ec-productRole__title .ec-headingTitle {
        font-size: 32px; } }
  .ec-productRole .ec-productRole__tags {
    margin-top: 16px;
    padding: 0;
    padding-bottom: 16px;
    border-bottom: 1px dotted #ccc; }
  .ec-productRole .ec-productRole__tag {
    display: inline-block;
    padding: 2px 5px;
    list-style: none;
    font-size: 80%;
    color: #525263;
    border: solid 1px #D7DADD;
    border-radius: 3px;
    background-color: #F5F7F8; }
  .ec-productRole .ec-productRole__priceRegular {
    padding-top: 14px; }
  .ec-productRole .ec-productRole__priceRegularTax {
    margin-left: 5px;
    font-size: 12px; }
  .ec-productRole .ec-productRole__price {
    color: #DE5D50;
    font-size: 28px;
    padding: 0;
    border-bottom: 0; }
    @media only screen and (min-width: 768px) {
      .ec-productRole .ec-productRole__price {
        padding: 14px 0;
        border-bottom: 1px dotted #ccc; } }
  .ec-productRole .ec-productRole__code {
    padding: 14px 0;
    border-bottom: 1px dotted #ccc; }
  .ec-productRole .ec-productRole__category {
    padding: 14px 0;
    border-bottom: 1px dotted #ccc; }
    .ec-productRole .ec-productRole__category ul {
      list-style: none;
      padding: 0;
      margin: 0; }
  .ec-productRole .ec-productRole__actions {
    padding: 14px 0; }
    .ec-productRole .ec-productRole__actions .ec-select select {
      height: 40px;
      max-width: 100%;
      min-width: 100%; }
      @media only screen and (min-width: 768px) {
        .ec-productRole .ec-productRole__actions .ec-select select {
          min-width: 350px;
          max-width: 350px; } }
  .ec-productRole .ec-productRole__btn {
    width: 100%;
    margin-bottom: 10px; }
    @media only screen and (min-width: 768px) {
      .ec-productRole .ec-productRole__btn {
        width: 60%;
        margin-bottom: 16px;
        min-width: 350px; } }
  .ec-productRole .ec-productRole__description {
    margin-bottom: 16px; }

/**
メディアクエリ
SP フォーストで記述する。
Twitter Bootstrap デフォルト準拠
 */
/**
 * ECCUBE 固有のスタイルユーティリティ
 */
/*
カート

カート 注文詳細 に関する Project コンポーネントを定義します。

ex [カートページ](http://demo3.ec-cube.net/shopping)

(カート内に商品がある状態でアクセス)

Markup:
include /assets/tmpl/elements/15.1.cart.pug
+ec-cartRole

Styleguide 15.1

*/
.ec-cartRole {
  margin-right: auto;
  margin-left: auto;
  padding-left: 10px;
  padding-right: 10px;
  box-sizing: border-box;
  font-size: 16px;
  font-family: Roboto, "游ゴシック", YuGothic, "Yu Gothic", "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", Arial, "メイリオ", Meiryo, sans-serif;
  line-height: 1.4;
  color: #525263;
  -webkit-text-size-adjust: 100%;
  width: 100%;
  max-width: 1150px;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end; }
  .ec-cartRole:after {
    content: " ";
    display: table; }
  .ec-cartRole:after {
    clear: both; }
  .ec-cartRole textarea {
    /* for chrome fontsize bug */
    font-family: sans-serif; }
  .ec-cartRole img {
    max-width: 100%; }
  .ec-cartRole html {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box; }
  .ec-cartRole *,
  .ec-cartRole *::before,
  .ec-cartRole *::after {
    -webkit-box-sizing: inherit;
    -moz-box-sizing: inherit;
    box-sizing: inherit; }
  .ec-cartRole img {
    width: 100%; }
  .ec-cartRole::before {
    display: none; }
  .ec-cartRole .ec-cartRole__progress {
    width: 100%;
    text-align: center; }
  .ec-cartRole .ec-cartRole__totalText {
    margin-bottom: 0;
    padding: 16px 0 6px;
    width: 100%;
    text-align: center;
    font-weight: normal; }
    @media only screen and (min-width: 768px) {
      .ec-cartRole .ec-cartRole__totalText {
        margin-bottom: 30px;
        padding: 0; } }
  .ec-cartRole .ec-cartRole__cart {
    margin: 0;
    width: 100%; }
    @media only screen and (min-width: 768px) {
      .ec-cartRole .ec-cartRole__cart {
        margin: 0 10%; } }
  .ec-cartRole .ec-cartRole__actions {
    text-align: right;
    width: 100%; }
    @media only screen and (min-width: 768px) {
      .ec-cartRole .ec-cartRole__actions {
        width: 20%;
        margin-right: 10%; } }
  .ec-cartRole .ec-cartRole__total {
    padding: 15px 0 30px;
    font-weight: bold;
    font-size: 16px; }
  .ec-cartRole .ec-cartRole__totalAmount {
    margin-left: 30px;
    color: #de5d50;
    font-size: 16px; }
    @media only screen and (min-width: 768px) {
      .ec-cartRole .ec-cartRole__totalAmount {
        font-size: 24px; } }
  .ec-cartRole .ec-blockBtn--action {
    margin-bottom: 10px; }

/*
カート

カート内のアイテムを表示するテーブル枠です。

ex [カートページ　テーブル部分](http://demo3.ec-cube.net/cart)

Markup:
.ec-cartTable

sg-wrapper:
<div class="ec-cartRole">
  <sg-wrapper-content/>
</div>

Styleguide 15.1.2
*/
.ec-cartTable {
  display: table;
  border-top: 1px dotted #ccc;
  width: 100%; }
  @media only screen and (min-width: 768px) {
    .ec-cartTable {
      border-top: none; } }

/*
カート商品表示枠

カート内のアイテムを表示するテーブルヘッダです。
スマホでは非表示となります。

ex [カートページ　カートテーブルヘッダ部分](http://demo3.ec-cube.net/cart)

Markup:
include /assets/tmpl/elements/15.1.cart.pug
.ec-cartTable
  +ec-cartHeader

sg-wrapper:
<div class="ec-cartRole">
  <sg-wrapper-content/>
</div>


Styleguide 15.1.3
*/
.ec-cartHeader {
  display: none;
  width: 100%;
  background: #F4F3F0; }
  @media only screen and (min-width: 768px) {
    .ec-cartHeader {
      display: table-row; } }
  .ec-cartHeader .ec-cartHeader__label {
    display: table-cell;
    padding: 16px;
    text-align: center;
    background: #F4F3F0;
    overflow-x: hidden;
    font-weight: bold; }

.ec-cartCompleteRole {
  margin-right: auto;
  margin-left: auto;
  padding-left: 10px;
  padding-right: 10px;
  box-sizing: border-box;
  font-size: 16px;
  font-family: Roboto, "游ゴシック", YuGothic, "Yu Gothic", "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", Arial, "メイリオ", Meiryo, sans-serif;
  line-height: 1.4;
  color: #525263;
  -webkit-text-size-adjust: 100%;
  width: 100%;
  max-width: 1150px; }
  .ec-cartCompleteRole:after {
    content: " ";
    display: table; }
  .ec-cartCompleteRole:after {
    clear: both; }
  .ec-cartCompleteRole textarea {
    /* for chrome fontsize bug */
    font-family: sans-serif; }
  .ec-cartCompleteRole img {
    max-width: 100%; }
  .ec-cartCompleteRole html {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box; }
  .ec-cartCompleteRole *,
  .ec-cartCompleteRole *::before,
  .ec-cartCompleteRole *::after {
    -webkit-box-sizing: inherit;
    -moz-box-sizing: inherit;
    box-sizing: inherit; }
  .ec-cartCompleteRole img {
    width: 100%; }

/*
カート内商品

カート内のアイテムを表示するテーブル行です。
スマホでは非表示となります。

ex [カートページ　テーブル部分](http://demo3.ec-cube.net/cart)

Markup:
include /assets/tmpl/elements/15.1.cart.pug
.ec-cartTable
  +ec-cartRow

sg-wrapper:
<div class="ec-cartRole">
  <sg-wrapper-content/>
</div>


Styleguide 15.1.4
*/
.ec-cartRow {
  display: table-row; }
  .ec-cartRow .ec-cartRow__delColumn {
    border-bottom: 1px dotted #ccc;
    text-align: center;
    display: table-cell;
    width: 14%;
    vertical-align: middle; }
    @media only screen and (min-width: 768px) {
      .ec-cartRow .ec-cartRow__delColumn {
        width: 8.3333333%; } }
    .ec-cartRow .ec-cartRow__delColumn .ec-icon svg, .ec-cartRow .ec-cartRow__delColumn .ec-icon--colored svg {
      width: 1.5em;
      height: 1.5em; }
      @media only screen and (min-width: 768px) {
        .ec-cartRow .ec-cartRow__delColumn .ec-icon svg, .ec-cartRow .ec-cartRow__delColumn .ec-icon--colored svg {
          width: 1em;
          height: 1em; } }
  .ec-cartRow .ec-cartRow__contentColumn {
    border-bottom: 1px dotted #ccc;
    padding: 10px 0;
    display: table-cell; }
  .ec-cartRow .ec-cartRow__img {
    display: inline-block;
    width: 40%;
    vertical-align: top; }
    @media only screen and (min-width: 768px) {
      .ec-cartRow .ec-cartRow__img {
        min-width: 80px;
        max-width: 100px; } }
  .ec-cartRow .ec-cartRow__summary {
    display: inline-block;
    margin-left: 5px;
    font-weight: bold;
    vertical-align: top;
    width: 46%; }
    @media only screen and (min-width: 768px) {
      .ec-cartRow .ec-cartRow__summary {
        margin-left: 20px;
        vertical-align: middle; } }
    .ec-cartRow .ec-cartRow__summary .ec-cartRow__name {
      margin-bottom: 5px; }
    .ec-cartRow .ec-cartRow__summary .ec-cartRow__sutbtotalSP {
      display: block;
      font-weight: normal; }
      @media only screen and (min-width: 768px) {
        .ec-cartRow .ec-cartRow__summary .ec-cartRow__sutbtotalSP {
          display: none; } }
  .ec-cartRow .ec-cartRow__amountColumn {
    display: table-cell;
    border-bottom: 1px dotted #ccc;
    vertical-align: middle;
    text-align: center;
    width: 20%; }
    @media only screen and (min-width: 768px) {
      .ec-cartRow .ec-cartRow__amountColumn {
        width: 16.66666667%; } }
    .ec-cartRow .ec-cartRow__amountColumn .ec-cartRow__amount {
      display: none;
      margin-bottom: 10px; }
      @media only screen and (min-width: 768px) {
        .ec-cartRow .ec-cartRow__amountColumn .ec-cartRow__amount {
          display: block; } }
    .ec-cartRow .ec-cartRow__amountColumn .ec-cartRow__amountSP {
      display: block;
      margin-bottom: 10px; }
      @media only screen and (min-width: 768px) {
        .ec-cartRow .ec-cartRow__amountColumn .ec-cartRow__amountSP {
          display: none; } }
    .ec-cartRow .ec-cartRow__amountColumn .ec-cartRow__amountUpButton {
      margin: 0 2px;
      display: inline-block;
      border: 2px solid #c9c9c9;
      border-radius: 50%;
      width: 30px;
      min-width: 30px;
      max-width: 30px;
      height: 30px;
      cursor: pointer;
      line-height: 40px;
      vertical-align: middle;
      position: relative;
      text-align: center;
      background: #fff; }
      .ec-cartRow .ec-cartRow__amountColumn .ec-cartRow__amountUpButton .ec-cartRow__amountDownButton__icon {
        display: block;
        margin-top: -0.7em;
        margin-left: -0.4em;
        width: .8em;
        height: .8em;
        font-size: 20px;
        position: absolute;
        top: 28%;
        left: 50%; }
        .ec-cartRow .ec-cartRow__amountColumn .ec-cartRow__amountUpButton .ec-cartRow__amountDownButton__icon svg {
          overflow: hidden;
          fill: #525263;
          display: inline-block;
          width: 100%;
          height: 100%; }
    .ec-cartRow .ec-cartRow__amountColumn .ec-cartRow__amountDownButton {
      margin: 0 2px;
      display: inline-block;
      border: 2px solid #c9c9c9;
      border-radius: 50%;
      width: 30px;
      min-width: 30px;
      max-width: 30px;
      height: 30px;
      cursor: pointer;
      line-height: 40px;
      vertical-align: middle;
      position: relative;
      text-align: center;
      background: #fff; }
      .ec-cartRow .ec-cartRow__amountColumn .ec-cartRow__amountDownButton .ec-cartRow__amountDownButton__icon {
        position: absolute;
        top: 28%;
        left: 50%;
        margin-top: -0.7em;
        margin-left: -0.4em;
        display: block;
        width: .8em;
        height: .8em;
        font-size: 20px; }
        .ec-cartRow .ec-cartRow__amountColumn .ec-cartRow__amountDownButton .ec-cartRow__amountDownButton__icon svg {
          overflow: hidden;
          fill: #525263;
          display: inline-block;
          width: 100%;
          height: 100%; }
  .ec-cartRow .ec-cartRow__subtotalColumn {
    display: none;
    border-bottom: 1px dotted #ccc;
    text-align: right;
    width: 16.66666667%; }
    @media only screen and (min-width: 768px) {
      .ec-cartRow .ec-cartRow__subtotalColumn {
        display: table-cell; } }

/*
アラート

カート内の商品に問題があることを示す警告メッセージです。

ex [マイページ　カート](http://demo3.ec-cube.net/cart)

Markup:
include /assets/tmpl/elements/15.1.cart.pug
.ec-cartRole
  .ec-cartRole__cart
    +ec-alert-warning

Styleguide 15.1.5
*/
.ec-alert-warning {
  width: 100%;
  padding: 10px;
  text-align: center;
  background: #F99;
  margin-bottom: 20px; }
  .ec-alert-warning .ec-alert-warning__icon {
    display: inline-block;
    width: 20px;
    height: 20px;
    color: #fff;
    fill: #fff;
    position: absolute;
    left: -30px; }
  .ec-alert-warning .ec-alert-warning__text {
    display: inline-block;
    font-size: 16px;
    font-weight: bold;
    color: #fff;
    position: relative; }

/*
アラート(空)

カートが空であることを示す警告メッセージです。

ex [マイページ　カート](http://demo3.ec-cube.net/cart)

Markup:
include /assets/tmpl/elements/15.1.cart.pug
.ec-off3Grid
        .ec-off3Grid__cell
            +ec-alert-warningEnpty

Styleguide 15.1.6
*/
/**
メディアクエリ
SP フォーストで記述する。
Twitter Bootstrap デフォルト準拠
 */
/**
 * ECCUBE 固有のスタイルユーティリティ
 */
/*
注文内容確認

カート内 注文内容確認に関する Project コンポーネントを定義します。

ex [マイページ　注文詳細](http://demo3.ec-cube.net/shopping)

Markup:
include /assets/tmpl/elements/15.2.order.pug
+ec-orderRole

Styleguide 15.2
*/
.ec-orderRole {
  margin-right: auto;
  margin-left: auto;
  padding-left: 10px;
  padding-right: 10px;
  box-sizing: border-box;
  font-size: 16px;
  font-family: Roboto, "游ゴシック", YuGothic, "Yu Gothic", "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", Arial, "メイリオ", Meiryo, sans-serif;
  line-height: 1.4;
  color: #525263;
  -webkit-text-size-adjust: 100%;
  width: 100%;
  max-width: 1150px;
  display: flex;
  flex-direction: column;
  margin-top: 0; }
  .ec-orderRole:after {
    content: " ";
    display: table; }
  .ec-orderRole:after {
    clear: both; }
  .ec-orderRole textarea {
    /* for chrome fontsize bug */
    font-family: sans-serif; }
  .ec-orderRole img {
    max-width: 100%; }
  .ec-orderRole html {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box; }
  .ec-orderRole *,
  .ec-orderRole *::before,
  .ec-orderRole *::after {
    -webkit-box-sizing: inherit;
    -moz-box-sizing: inherit;
    box-sizing: inherit; }
  .ec-orderRole img {
    width: 100%; }
  @media only screen and (min-width: 768px) {
    .ec-orderRole {
      margin-top: 20px;
      flex-direction: row; } }
  .ec-orderRole .ec-inlineBtn {
    font-weight: normal; }
  .ec-orderRole .ec-orderRole__detail {
    padding: 0;
    width: 100%; }
    @media only screen and (min-width: 768px) {
      .ec-orderRole .ec-orderRole__detail {
        padding: 0 16px;
        width: 66.66666%; } }
  .ec-orderRole .ec-orderRole__summary {
    width: 100%; }
    @media only screen and (min-width: 768px) {
      .ec-orderRole .ec-orderRole__summary {
        width: 33.33333%;
        padding: 0 16px; } }
  .ec-orderRole .ec-borderedList {
    margin-bottom: 20px;
    border-top: 1px dotted #ccc; }
    @media only screen and (min-width: 768px) {
      .ec-orderRole .ec-borderedList {
        border-top: none; } }

/*
注文履歴詳細 オーダ情報

マイページ 注文履歴詳細部に関する Project コンポーネントを定義します。

ex [マイページ　オーダ情報](http://demo3.ec-cube.net/mypage)
(要ログイン → 詳細を見るボタン押下)

Markup:
include /assets/tmpl/elements/15.2.order.pug
+ec-orderInfo

Styleguide 15.2.1
*/
.ec-orderOrder {
  margin-bottom: 30px; }
  .ec-orderOrder .ec-orderOrder__items {
    border-bottom: 1px dotted #ccc;
    border-top: 1px dotted #ccc; }

/*
注文履歴詳細 お客様情報

マイページ 注文詳細部に関する Project コンポーネントを定義します。

ex [マイページ　オーダ情報](http://demo3.ec-cube.net/mypage)
(要ログイン → 詳細を見るボタン押下)

Markup:
include /assets/tmpl/elements/15.2.order.pug
+ec-orderAccount

Styleguide 15.2.2
*/
.ec-orderAccount {
  margin-bottom: 30px; }
  .ec-orderAccount p {
    margin-bottom: 0; }
  .ec-orderAccount:after {
    content: " ";
    display: table; }
  .ec-orderAccount:after {
    clear: both; }
  .ec-orderAccount .ec-orderAccount__change {
    display: inline-block;
    margin-left: 10px;
    float: right; }
  .ec-orderAccount .ec-orderAccount__account {
    margin-bottom: 16px; }

/*
注文詳細 配送情報

マイページ 注文履歴詳細部に関する Project コンポーネントを定義します。

ex [マイページ　配送情報](http://demo3.ec-cube.net/mypage)
(要ログイン → 詳細を見るボタン押下)

Markup:
include /assets/tmpl/elements/15.2.order.pug
+ec-orderDelivery

Styleguide 15.2.3
*/
.ec-orderDelivery .ec-orderDelivery__title {
  padding: 16px 0 17px;
  font-weight: bold;
  font-size: 18px;
  position: relative; }

.ec-orderDelivery .ec-orderDelivery__change {
  display: inline-block;
  position: absolute;
  right: 0;
  top: 0; }

.ec-orderDelivery .ec-orderDelivery__items {
  border-bottom: 1px dotted #ccc;
  border-top: 1px dotted #ccc; }

.ec-orderDelivery .ec-orderDelivery__address {
  margin: 10px 0px 18px; }
  .ec-orderDelivery .ec-orderDelivery__address p {
    margin: 0; }

/*
注文履歴詳細 支払情報

マイページ 注文履歴詳細部に関する Project コンポーネントを定義します。

ex [マイページ　支払情報](http://demo3.ec-cube.net/mypage)
(要ログイン → 詳細を見るボタン押下)

Markup:
.ec-orderRole
  .ec-orderPayment
    .ec-rectHeading
      h2 お支払方法
    p 支払方法： 郵便振替

Styleguide 15.2.4
*/
/*
注文履歴詳細 お問い合わせ

マイページ 注文履歴詳細部に関する Project コンポーネントを定義します。

ex [マイページ　お問い合わせ](http://demo3.ec-cube.net/mypage)
(要ログイン → 詳細を見るボタン押下)

Markup:
.ec-orderRole
  .ec-orderConfirm
    .ec-rectHeading
      h2 お問い合わせ
    p 記載なし

Styleguide 15.2.5
*/
.ec-orderConfirm {
  margin-bottom: 20px; }
  @media only screen and (min-width: 768px) {
    .ec-orderConfirm {
      margin-bottom: 0; } }
  .ec-orderConfirm .ec-input textarea, .ec-orderConfirm .ec-halfInput textarea, .ec-orderConfirm .ec-numberInput textarea, .ec-orderConfirm .ec-zipInput textarea, .ec-orderConfirm .ec-telInput textarea, .ec-orderConfirm .ec-select textarea, .ec-orderConfirm .ec-birth textarea {
    height: 96px; }

/*
お届け先の複数指定

お届け先の複数指定に関するコンポーネントを定義します。

ex [マイページ　お届け先の複数指定](http://demo3.ec-cube.net/shopping/shipping_multiple)
(商品購入画面 → 「お届け先を追加する」を押下)

Markup:
include /assets/tmpl/elements/15.2.order.pug
+ec-orderAddAddress

Styleguide 15.2.6
*/
.ec-AddAddress {
  margin: 0; }
  @media only screen and (min-width: 768px) {
    .ec-AddAddress {
      margin: 0 10%; } }
  .ec-AddAddress .ec-AddAddress__info {
    margin-bottom: 32px;
    text-align: center;
    font-size: 16px; }
  .ec-AddAddress .ec-AddAddress__add {
    border-top: 1px solid #f4f4f4;
    padding-top: 20px;
    margin-bottom: 20px; }
  .ec-AddAddress .ec-AddAddress__item {
    display: table;
    padding: 16px;
    background: #f4f4f4;
    margin-bottom: 16px; }
  .ec-AddAddress .ec-AddAddress__itemThumb {
    display: table-cell;
    min-width: 160px;
    width: 20%; }
    .ec-AddAddress .ec-AddAddress__itemThumb img {
      width: 100%; }
  .ec-AddAddress .ec-AddAddress__itemtContent {
    display: table-cell;
    vertical-align: middle;
    padding-left: 16px;
    font-size: 16px; }
  .ec-AddAddress .ec-AddAddress__itemtTitle {
    font-weight: bold;
    margin-bottom: 10px; }
  .ec-AddAddress .ec-AddAddress__itemtSize {
    margin-bottom: 10px; }
  .ec-AddAddress .ec-AddAddress__selectAddress {
    display: inline-block; }
    .ec-AddAddress .ec-AddAddress__selectAddress label {
      font-size: 16px;
      font-weight: normal; }
  .ec-AddAddress .ec-AddAddress__selectNumber {
    display: inline-block;
    margin-left: 30px; }
    .ec-AddAddress .ec-AddAddress__selectNumber label {
      font-size: 16px;
      font-weight: normal; }
    .ec-AddAddress .ec-AddAddress__selectNumber input {
      display: inline-block;
      margin-left: 10px;
      width: 80px; }

/**
メディアクエリ
SP フォーストで記述する。
Twitter Bootstrap デフォルト準拠
 */
/**
 * ECCUBE 固有のスタイルユーティリティ
 */
/*
注文履歴一覧

マイページ 注文履歴部に関する Project コンポーネントを定義します。

ex [マイページ　注文履歴一覧](http://demo3.ec-cube.net/mypage)
(要ログイン)

Markup:
include /assets/tmpl/elements/16.1.history.pug
+ec-historyRole

Styleguide 16.1
*/
.ec-historyRole {
  margin-right: auto;
  margin-left: auto;
  padding-left: 10px;
  padding-right: 10px;
  box-sizing: border-box;
  font-size: 16px;
  font-family: Roboto, "游ゴシック", YuGothic, "Yu Gothic", "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", Arial, "メイリオ", Meiryo, sans-serif;
  line-height: 1.4;
  color: #525263;
  -webkit-text-size-adjust: 100%;
  width: 100%;
  max-width: 1150px; }
  .ec-historyRole:after {
    content: " ";
    display: table; }
  .ec-historyRole:after {
    clear: both; }
  .ec-historyRole textarea {
    /* for chrome fontsize bug */
    font-family: sans-serif; }
  .ec-historyRole img {
    max-width: 100%; }
  .ec-historyRole html {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box; }
  .ec-historyRole *,
  .ec-historyRole *::before,
  .ec-historyRole *::after {
    -webkit-box-sizing: inherit;
    -moz-box-sizing: inherit;
    box-sizing: inherit; }
  .ec-historyRole img {
    width: 100%; }
  .ec-historyRole .ec-historyRole__contents {
    padding-top: 1em;
    padding-bottom: 16px;
    border-top: 1px solid #ccc;
    display: flex;
    flex-direction: column; }
    @media only screen and (min-width: 768px) {
      .ec-historyRole .ec-historyRole__contents {
        flex-direction: row; } }
  .ec-historyRole .ec-historyRole__header {
    width: 100%; }
    @media only screen and (min-width: 768px) {
      .ec-historyRole .ec-historyRole__header {
        width: 33.3333%; } }
  .ec-historyRole .ec-historyRole__detail {
    border-top: 1px dotted #ccc;
    width: 100%; }
    .ec-historyRole .ec-historyRole__detail .ec-imageGrid:nth-of-type(1) {
      border-top: none; }
    @media only screen and (min-width: 768px) {
      .ec-historyRole .ec-historyRole__detail {
        width: 66.6666%;
        border-top: none; } }

/*
注文履歴一覧ヘッダ

注文履歴一覧で使用するヘッダのコンポーネントを定義します。

ex [マイページ　注文履歴一覧ヘッダ](http://demo3.ec-cube.net/mypage)
(要ログイン)

Markup:
include /assets/tmpl/elements/16.1.history.pug
+ec-historyHeader

Styleguide 16.1.1
*/
.ec-historyListHeader .ec-historyListHeader__date {
  font-weight: bold;
  font-size: 16px; }
  @media only screen and (min-width: 768px) {
    .ec-historyListHeader .ec-historyListHeader__date {
      font-weight: bold;
      font-size: 20px; } }

.ec-historyListHeader .ec-historyListHeader__action {
  margin: 16px 0; }
  .ec-historyListHeader .ec-historyListHeader__action a {
    font-size: 12px;
    font-weight: normal; }
    @media only screen and (min-width: 768px) {
      .ec-historyListHeader .ec-historyListHeader__action a {
        font-size: 14px; } }

/**
 * ECCUBE 固有のスタイルユーティリティ
 */
/*
注文履歴詳細

マイページ 注文履歴詳細部に関する Project コンポーネントを定義します。

ex [マイページ　注文詳細](http://demo3.ec-cube.net/mypage)
(要ログイン → 詳細を見るボタン押下)

Markup:
include /assets/tmpl/elements/16.2.historyDetail.pug
+ec-historyDetailRole

Styleguide 16.2
*/
/*
注文履歴詳細 メール履歴

マイページ 注文履歴詳細部に関する Project コンポーネントを定義します。

ex [マイページ　メール履歴](http://demo3.ec-cube.net/mypage)
(要ログイン → 詳細を見るボタン押下)

Markup:
include /assets/tmpl/elements/16.2.historyDetail.pug
+ec-historyDetailMail

Styleguide 16.2.5
*/
.ec-orderMails .ec-orderMails__item {
  padding-bottom: 10px;
  border-bottom: 1px dotted #ccc; }

.ec-orderMails .ec-orderMails__time {
  margin: 0; }

.ec-orderMails .ec-orderMails__body {
  display: none; }

.ec-orderMails .ec-orderMails__close {
  display: none; }

.ec-orderMails .ec-orderMails__item.is-active .ec-orderMails .ec-orderMails__item.is-active__body {
  display: block; }

.ec-orderMails .ec-orderMails__item.is-active__close {
  display: block; }

/*
注文履歴詳細 メール履歴個別

マイページ 注文履歴詳細部に関する Project コンポーネントを定義します。

ex [マイページ　メール履歴個別](http://demo3.ec-cube.net/mypage)
(要ログイン → 詳細を見るボタン押下)

Markup:
include /assets/tmpl/elements/16.2.historyDetail.pug
+ec-historyDetailMailHistory

Styleguide 16.2.6
*/
.ec-orderMail {
  padding-bottom: 10px;
  border-bottom: 1px dotted #ccc; }
  .ec-orderMail .ec-orderMail__time {
    margin: 0; }
  .ec-orderMail .ec-orderMail__body {
    display: none; }
  .ec-orderMail .ec-orderMail__close {
    display: none; }
  .ec-orderMail.is-active .ec-orderMail__body {
    display: block; }
  .ec-orderMail.is-active .ec-orderMail__close {
    display: block; }

/*
住所一覧

カート 注文詳細 に関する Project コンポーネントを定義します。

ex [マイページ内 お届け先編集](http://demo3.ec-cube.net/mypage/delivery)

Markup:
include /assets/tmpl/elements/17.1.address.pug
+ec-addressRole

sg-wrapper:
<div class="ec-addressRole">
  <sg-wrapper-content/>
</div>

Styleguide 17.1

*/
.ec-addressRole .ec-addressRole__item {
  border-top: 1px dotted #ccc; }

.ec-addressRole .ec-addressRole__actions {
  margin-top: 32px;
  padding-bottom: 20px;
  border-bottom: 1px dotted #ccc; }

/*
住所一覧リスト

カート 注文詳細 に関する Project コンポーネントを定義します。

ex [マイページ内 お届け先編集](http://demo3.ec-cube.net/mypage/delivery)

Markup:
include /assets/tmpl/elements/17.1.address.pug
+ec-addressList

Styleguide 17.1.1

*/
.ec-addressList .ec-addressList__item {
  display: table;
  width: 100%;
  position: relative;
  border-bottom: 1px dotted #ccc; }

.ec-addressList .ec-addressList__remove {
  display: table-cell;
  vertical-align: middle;
  padding: 16px;
  text-align: center; }

.ec-addressList .ec-addressList__address {
  display: table-cell;
  vertical-align: middle;
  padding: 16px;
  margin-right: 4em; }

.ec-addressList .ec-addressList__action {
  position: absolute;
  vertical-align: middle;
  top: 27px;
  right: 0; }

/**
メディアクエリ
SP フォーストで記述する。
Twitter Bootstrap デフォルト準拠
 */
/*
パスワードリセット

カート 注文詳細 に関する Project コンポーネントを定義します。

ex [パスワードリセット画面](http://demo3.ec-cube.net/forgot)

(カート内に商品がある状態でアクセス)

Markup:
include /assets/tmpl/elements/18.1.password.pug
+ec-passwordRole

Styleguide 18.1

*/
.ec-forgotRole {
  margin-right: auto;
  margin-left: auto;
  padding-left: 10px;
  padding-right: 10px;
  box-sizing: border-box;
  font-size: 16px;
  font-family: Roboto, "游ゴシック", YuGothic, "Yu Gothic", "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", Arial, "メイリオ", Meiryo, sans-serif;
  line-height: 1.4;
  color: #525263;
  -webkit-text-size-adjust: 100%;
  width: 100%;
  max-width: 1150px; }
  .ec-forgotRole:after {
    content: " ";
    display: table; }
  .ec-forgotRole:after {
    clear: both; }
  .ec-forgotRole textarea {
    /* for chrome fontsize bug */
    font-family: sans-serif; }
  .ec-forgotRole img {
    max-width: 100%; }
  .ec-forgotRole html {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box; }
  .ec-forgotRole *,
  .ec-forgotRole *::before,
  .ec-forgotRole *::after {
    -webkit-box-sizing: inherit;
    -moz-box-sizing: inherit;
    box-sizing: inherit; }
  .ec-forgotRole img {
    width: 100%; }
  .ec-forgotRole .ec-forgotRole__intro {
    font-size: 16px; }
  .ec-forgotRole .ec-forgotRole__form {
    margin-bottom: 16px; }

/**
メディアクエリ
SP フォーストで記述する。
Twitter Bootstrap デフォルト準拠
 */
/*
会員登録

新規会員登録 に関する Project コンポーネントを定義します。

ex [新規会員登録画面　会員登録](http://demo3.ec-cube.net/entry)

Markup:
include /assets/tmpl/elements/19.1.register.pug
+ec-registerRole

Styleguide 19.1

*/
.ec-registerRole {
  margin-right: auto;
  margin-left: auto;
  padding-left: 10px;
  padding-right: 10px;
  box-sizing: border-box;
  font-size: 16px;
  font-family: Roboto, "游ゴシック", YuGothic, "Yu Gothic", "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", Arial, "メイリオ", Meiryo, sans-serif;
  line-height: 1.4;
  color: #525263;
  -webkit-text-size-adjust: 100%;
  width: 100%;
  max-width: 1150px; }
  .ec-registerRole:after {
    content: " ";
    display: table; }
  .ec-registerRole:after {
    clear: both; }
  .ec-registerRole textarea {
    /* for chrome fontsize bug */
    font-family: sans-serif; }
  .ec-registerRole img {
    max-width: 100%; }
  .ec-registerRole html {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box; }
  .ec-registerRole *,
  .ec-registerRole *::before,
  .ec-registerRole *::after {
    -webkit-box-sizing: inherit;
    -moz-box-sizing: inherit;
    box-sizing: inherit; }
  .ec-registerRole img {
    width: 100%; }
  .ec-registerRole .ec-registerRole__actions {
    padding-top: 20px;
    text-align: center; }
    @media only screen and (min-width: 768px) {
      .ec-registerRole .ec-registerRole__actions {
        text-align: left; } }
  .ec-registerRole .ec-blockBtn--action {
    margin-bottom: 16px; }

.ec-registerCompleteRole {
  margin-right: auto;
  margin-left: auto;
  padding-left: 10px;
  padding-right: 10px;
  box-sizing: border-box;
  font-size: 16px;
  font-family: Roboto, "游ゴシック", YuGothic, "Yu Gothic", "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", Arial, "メイリオ", Meiryo, sans-serif;
  line-height: 1.4;
  color: #525263;
  -webkit-text-size-adjust: 100%;
  width: 100%;
  max-width: 1150px; }
  .ec-registerCompleteRole:after {
    content: " ";
    display: table; }
  .ec-registerCompleteRole:after {
    clear: both; }
  .ec-registerCompleteRole textarea {
    /* for chrome fontsize bug */
    font-family: sans-serif; }
  .ec-registerCompleteRole img {
    max-width: 100%; }
  .ec-registerCompleteRole html {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box; }
  .ec-registerCompleteRole *,
  .ec-registerCompleteRole *::before,
  .ec-registerCompleteRole *::after {
    -webkit-box-sizing: inherit;
    -moz-box-sizing: inherit;
    box-sizing: inherit; }
  .ec-registerCompleteRole img {
    width: 100%; }

/**
メディアクエリ
SP フォーストで記述する。
Twitter Bootstrap デフォルト準拠
 */
/*
お問い合わせ

お問い合わせ に関する Project コンポーネントを定義します。

ex [お問い合わせ](http://demo3.ec-cube.net/contact)

Markup:
include /assets/tmpl/elements/19.2.contact.pug
+ec-contactRole

Styleguide 19.2

*/
.ec-contactRole {
  margin-right: auto;
  margin-left: auto;
  padding-left: 10px;
  padding-right: 10px;
  box-sizing: border-box;
  font-size: 16px;
  font-family: Roboto, "游ゴシック", YuGothic, "Yu Gothic", "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", Arial, "メイリオ", Meiryo, sans-serif;
  line-height: 1.4;
  color: #525263;
  -webkit-text-size-adjust: 100%;
  width: 100%;
  max-width: 1150px; }
  .ec-contactRole:after {
    content: " ";
    display: table; }
  .ec-contactRole:after {
    clear: both; }
  .ec-contactRole textarea {
    /* for chrome fontsize bug */
    font-family: sans-serif; }
  .ec-contactRole img {
    max-width: 100%; }
  .ec-contactRole html {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box; }
  .ec-contactRole *,
  .ec-contactRole *::before,
  .ec-contactRole *::after {
    -webkit-box-sizing: inherit;
    -moz-box-sizing: inherit;
    box-sizing: inherit; }
  .ec-contactRole img {
    width: 100%; }
  .ec-contactRole .ec-contactRole__actions {
    padding-top: 20px; }

.ec-contactConfirmRole {
  margin-right: auto;
  margin-left: auto;
  padding-left: 10px;
  padding-right: 10px;
  box-sizing: border-box;
  font-size: 16px;
  font-family: Roboto, "游ゴシック", YuGothic, "Yu Gothic", "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", Arial, "メイリオ", Meiryo, sans-serif;
  line-height: 1.4;
  color: #525263;
  -webkit-text-size-adjust: 100%;
  width: 100%;
  max-width: 1150px; }
  .ec-contactConfirmRole:after {
    content: " ";
    display: table; }
  .ec-contactConfirmRole:after {
    clear: both; }
  .ec-contactConfirmRole textarea {
    /* for chrome fontsize bug */
    font-family: sans-serif; }
  .ec-contactConfirmRole img {
    max-width: 100%; }
  .ec-contactConfirmRole html {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box; }
  .ec-contactConfirmRole *,
  .ec-contactConfirmRole *::before,
  .ec-contactConfirmRole *::after {
    -webkit-box-sizing: inherit;
    -moz-box-sizing: inherit;
    box-sizing: inherit; }
  .ec-contactConfirmRole img {
    width: 100%; }
  .ec-contactConfirmRole .ec-contactConfirmRole__actions {
    padding-top: 20px; }
  .ec-contactConfirmRole .ec-blockBtn--action {
    margin-bottom: 16px; }

.ec-contactCompleteRole {
  margin-right: auto;
  margin-left: auto;
  padding-left: 10px;
  padding-right: 10px;
  box-sizing: border-box;
  font-size: 16px;
  font-family: Roboto, "游ゴシック", YuGothic, "Yu Gothic", "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", Arial, "メイリオ", Meiryo, sans-serif;
  line-height: 1.4;
  color: #525263;
  -webkit-text-size-adjust: 100%;
  width: 100%;
  max-width: 1150px; }
  .ec-contactCompleteRole:after {
    content: " ";
    display: table; }
  .ec-contactCompleteRole:after {
    clear: both; }
  .ec-contactCompleteRole textarea {
    /* for chrome fontsize bug */
    font-family: sans-serif; }
  .ec-contactCompleteRole img {
    max-width: 100%; }
  .ec-contactCompleteRole html {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box; }
  .ec-contactCompleteRole *,
  .ec-contactCompleteRole *::before,
  .ec-contactCompleteRole *::after {
    -webkit-box-sizing: inherit;
    -moz-box-sizing: inherit;
    box-sizing: inherit; }
  .ec-contactCompleteRole img {
    width: 100%; }

/**
メディアクエリ
SP フォーストで記述する。
Twitter Bootstrap デフォルト準拠
 */
/*
お客様情報の入力

ログインせずゲストとして商品を購入する際の、お客様情報の入力 に関する Project コンポーネントを定義します。

ex [カートSTEP2 お客様情報の入力(ゲスト購入)](http://demo3.ec-cube.net/shopping/nonmember)

Markup:
include /assets/tmpl/elements/19.3.customer.pug
+ec-customerRole
hoge

Styleguide 19.3

*/
.ec-customerRole {
  margin-right: auto;
  margin-left: auto;
  padding-left: 10px;
  padding-right: 10px;
  box-sizing: border-box;
  font-size: 16px;
  font-family: Roboto, "游ゴシック", YuGothic, "Yu Gothic", "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", Arial, "メイリオ", Meiryo, sans-serif;
  line-height: 1.4;
  color: #525263;
  -webkit-text-size-adjust: 100%;
  width: 100%;
  max-width: 1150px; }
  .ec-customerRole:after {
    content: " ";
    display: table; }
  .ec-customerRole:after {
    clear: both; }
  .ec-customerRole textarea {
    /* for chrome fontsize bug */
    font-family: sans-serif; }
  .ec-customerRole img {
    max-width: 100%; }
  .ec-customerRole html {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box; }
  .ec-customerRole *,
  .ec-customerRole *::before,
  .ec-customerRole *::after {
    -webkit-box-sizing: inherit;
    -moz-box-sizing: inherit;
    box-sizing: inherit; }
  .ec-customerRole img {
    width: 100%; }
  .ec-customerRole .ec-customerRole__actions {
    padding-top: 20px; }
  .ec-customerRole .ec-blockBtn--action {
    margin-bottom: 10px; }
    @media only screen and (min-width: 768px) {
      .ec-customerRole .ec-blockBtn--action {
        margin-bottom: 16px; } }

.ec-contactConfirmRole {
  margin-right: auto;
  margin-left: auto;
  padding-left: 10px;
  padding-right: 10px;
  box-sizing: border-box;
  font-size: 16px;
  font-family: Roboto, "游ゴシック", YuGothic, "Yu Gothic", "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", Arial, "メイリオ", Meiryo, sans-serif;
  line-height: 1.4;
  color: #525263;
  -webkit-text-size-adjust: 100%;
  width: 100%;
  max-width: 1150px; }
  .ec-contactConfirmRole:after {
    content: " ";
    display: table; }
  .ec-contactConfirmRole:after {
    clear: both; }
  .ec-contactConfirmRole textarea {
    /* for chrome fontsize bug */
    font-family: sans-serif; }
  .ec-contactConfirmRole img {
    max-width: 100%; }
  .ec-contactConfirmRole html {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box; }
  .ec-contactConfirmRole *,
  .ec-contactConfirmRole *::before,
  .ec-contactConfirmRole *::after {
    -webkit-box-sizing: inherit;
    -moz-box-sizing: inherit;
    box-sizing: inherit; }
  .ec-contactConfirmRole img {
    width: 100%; }
  .ec-contactConfirmRole .ec-contactConfirmRole__actions {
    padding-top: 20px; }
  .ec-contactConfirmRole .ec-blockBtn--action {
    margin-bottom: 16px; }

.ec-contactCompleteRole {
  margin-right: auto;
  margin-left: auto;
  padding-left: 10px;
  padding-right: 10px;
  box-sizing: border-box;
  font-size: 16px;
  font-family: Roboto, "游ゴシック", YuGothic, "Yu Gothic", "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", Arial, "メイリオ", Meiryo, sans-serif;
  line-height: 1.4;
  color: #525263;
  -webkit-text-size-adjust: 100%;
  width: 100%;
  max-width: 1150px; }
  .ec-contactCompleteRole:after {
    content: " ";
    display: table; }
  .ec-contactCompleteRole:after {
    clear: both; }
  .ec-contactCompleteRole textarea {
    /* for chrome fontsize bug */
    font-family: sans-serif; }
  .ec-contactCompleteRole img {
    max-width: 100%; }
  .ec-contactCompleteRole html {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box; }
  .ec-contactCompleteRole *,
  .ec-contactCompleteRole *::before,
  .ec-contactCompleteRole *::after {
    -webkit-box-sizing: inherit;
    -moz-box-sizing: inherit;
    box-sizing: inherit; }
  .ec-contactCompleteRole img {
    width: 100%; }

/**
メディアクエリ
SP フォーストで記述する。
Twitter Bootstrap デフォルト準拠
 */
@keyframes fadeIn {
  0% {
    opacity: 0;
    visibility: hidden; }
  100% {
    opacity: 1;
    visibility: visible; } }

@keyframes fadeOut {
  0% {
    opacity: 1;
    visibility: visible; }
  100% {
    opacity: 0;
    visibility: hidden; } }

/*
404ページ

404 エラー画面で使用するページコンポーネントです。

ex [404エラー画面](http://demo3.ec-cube.net/404)

Markup:
include /assets/tmpl/elements/20.1.404.pug
+ec-404Role

Styleguide 20.1

*/
.ec-404Role {
  font-size: 16px;
  font-family: Roboto, "游ゴシック", YuGothic, "Yu Gothic", "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", Arial, "メイリオ", Meiryo, sans-serif;
  line-height: 1.4;
  color: #525263;
  -webkit-text-size-adjust: 100%;
  width: 100%;
  height: 100vh;
  background-color: #f2f2f2;
  text-align: center; }
  .ec-404Role textarea {
    /* for chrome fontsize bug */
    font-family: sans-serif; }
  .ec-404Role img {
    max-width: 100%; }
  .ec-404Role html {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box; }
  .ec-404Role *,
  .ec-404Role *::before,
  .ec-404Role *::after {
    -webkit-box-sizing: inherit;
    -moz-box-sizing: inherit;
    box-sizing: inherit; }
  .ec-404Role img {
    width: 100%; }
  .ec-404Role .ec-404Role__icon svg {
    fill: #d4d4d5; }
  .ec-404Role .ec-404Role__title {
    font-weight: bold;
    font-size: 25px; }

/**
メディアクエリ
SP フォーストで記述する。
Twitter Bootstrap デフォルト準拠
 */
/*
退会手続き

退会手続きで使用するページコンポーネントです。

ex [退会手続き](http://demo3.ec-cube.net/mypage/withdraw)

Markup:
include /assets/tmpl/elements/21.1.withdraw.pug
+ec-withdrawRole

Styleguide 21.1

*/
.ec-withdrawRole {
  margin-right: auto;
  margin-left: auto;
  padding-left: 10px;
  padding-right: 10px;
  box-sizing: border-box;
  font-size: 16px;
  font-family: Roboto, "游ゴシック", YuGothic, "Yu Gothic", "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", Arial, "メイリオ", Meiryo, sans-serif;
  line-height: 1.4;
  color: #525263;
  -webkit-text-size-adjust: 100%;
  width: 100%;
  max-width: 1150px;
  text-align: center;
  padding: 0 16px; }
  .ec-withdrawRole:after {
    content: " ";
    display: table; }
  .ec-withdrawRole:after {
    clear: both; }
  .ec-withdrawRole textarea {
    /* for chrome fontsize bug */
    font-family: sans-serif; }
  .ec-withdrawRole img {
    max-width: 100%; }
  .ec-withdrawRole html {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box; }
  .ec-withdrawRole *,
  .ec-withdrawRole *::before,
  .ec-withdrawRole *::after {
    -webkit-box-sizing: inherit;
    -moz-box-sizing: inherit;
    box-sizing: inherit; }
  .ec-withdrawRole img {
    width: 100%; }
  .ec-withdrawRole .ec-withdrawRole__title {
    margin-bottom: 16px;
    font-weight: bold;
    font-size: 24px; }
  .ec-withdrawRole .ec-withdrawRole__description {
    margin-bottom: 32px;
    font-size: 16px; }

/*
退会手続き実行確認

退会手続き実行確認で使用するページコンポーネントです。

ex [退会手続き　退会手続きへボタン→押下](http://demo3.ec-cube.net/mypage/withdraw)

Markup:
include /assets/tmpl/elements/21.1.withdraw.pug
+ec-withdrawConfirm

Styleguide 21.1.2

*/
.ec-withdrawConfirmRole .ec-withdrawConfirmRole__cancel {
  margin-bottom: 20px; }

/**
メディアクエリ
SP フォーストで記述する。
Twitter Bootstrap デフォルト準拠
 */
/*
会員情報編集完了

会員情報編集完了で使用するページコンポーネントです。

ex [会員情報編集完了](http://demo3.ec-cube.net/mypage/change_complete)

Markup:
include /assets/tmpl/elements/22.1.editComplete.pug
+ec-userEditCompleteRole

Styleguide 22.1

*/
.ec-userEditCompleteRole {
  margin-right: auto;
  margin-left: auto;
  padding-left: 10px;
  padding-right: 10px;
  box-sizing: border-box;
  font-size: 16px;
  font-family: Roboto, "游ゴシック", YuGothic, "Yu Gothic", "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", Arial, "メイリオ", Meiryo, sans-serif;
  line-height: 1.4;
  color: #525263;
  -webkit-text-size-adjust: 100%;
  width: 100%;
  max-width: 1150px;
  text-align: center;
  padding: 0 16px; }
  .ec-userEditCompleteRole:after {
    content: " ";
    display: table; }
  .ec-userEditCompleteRole:after {
    clear: both; }
  .ec-userEditCompleteRole textarea {
    /* for chrome fontsize bug */
    font-family: sans-serif; }
  .ec-userEditCompleteRole img {
    max-width: 100%; }
  .ec-userEditCompleteRole html {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box; }
  .ec-userEditCompleteRole *,
  .ec-userEditCompleteRole *::before,
  .ec-userEditCompleteRole *::after {
    -webkit-box-sizing: inherit;
    -moz-box-sizing: inherit;
    box-sizing: inherit; }
  .ec-userEditCompleteRole img {
    width: 100%; }
  .ec-userEditCompleteRole .ec-userEditCompleteRole__title {
    margin-bottom: 16px;
    font-weight: bold;
    font-size: 24px; }
    @media only screen and (min-width: 768px) {
      .ec-userEditCompleteRole .ec-userEditCompleteRole__title {
        font-size: 32px; } }
  .ec-userEditCompleteRole .ec-userEditCompleteRole__description {
    margin-bottom: 32px;
    font-size: 16px; }
