@import "../mixins/media";
@import "../mixins/projects";
/*
フッター

全ページで使用されるフッターのプロジェクトコンポーネントです。

ex [商品詳細ページ　フッター](http://demo3.ec-cube.net/products/detail/33)

Markup:
include /assets/tmpl/elements/11.8.footer.pug
+ec-footerRole

Styleguide 11.3
*/
.ec-footerRole{
  background: #f8f8f8;
  border-top: 1px solid #e8e8e8;
  padding-top: 12px;
  padding-bottom: 24px;
  margin-top: 36px;

  @include media_desktop(){
    padding-top: 32px;
    margin-top: 80px;
  }
  & &__inner{
    @include container;
  }
}

/*
フッターナビ

フッタープロジェクトで使用するナビゲーション用のコンポーネントです。

ulタグを用いてリスト要素として宣言します。

ex [商品詳細ページ　フッターナビ(http://demo3.ec-cube.net/products/detail/33)

Markup:
include /assets/tmpl/elements/11.8.footer.pug
+ec-footerNav

sg-wrapper:
<div class="ec-footerRole">
  <div class="ec-footerRole__inner">
    <sg-wrapper-content/>
  </div>
</div>

Styleguide 11.8.1
*/
.ec-footerNavi{
  list-style: none;
  text-align: center;
  padding: 0;
  & &__link{
    display: inline-block;
    margin: 0 8px 8px;
    a{
      color: inherit;
      text-decoration: none;
    }
    &:hover {
      a {
        color: #686868;
        text-decoration: none;
      }

    }

  }
}

/*
フッタータイトル

フッタープロジェクトで使用するタイトル用のコンポーネントです。

ulタグを用いてリスト要素として宣言します。

ex [商品詳細ページ　フッタータイトル](http://demo3.ec-cube.net/products/detail/33)

Markup:
include /assets/tmpl/elements/11.8.footer.pug
+ec-footerTitle

sg-wrapper:
<div class="ec-footerRole">
  <div class="ec-footerRole__inner">
    <sg-wrapper-content/>
  </div>
</div>

Styleguide 11.8.2
*/
.ec-footerTitle{
  margin-top:20px;
  text-align: center;
  @include media_desktop {
    margin-top:80px;
  }
  & &__logo{
    font-weight: bold;
    @include reset_link();
    a{
      font-size: 16px;
      color: inherit;
      @include media_desktop {
        font-size: 2em;
      }
    }
    &:hover {
      a {
        color: #686868;
        text-decoration: none;
      }
    }
  }
  & &__copyright{
    font-size: 10px;
    @include media_desktop {
      font-size: .8em;
    }
  }
}

