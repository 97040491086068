@import "../mixins/media";
@import "../mixins/projects";
/*
ヘッダー

ヘッダー用のプロジェクトコンポーネントを提供します。

ex [商品詳細ページ　ヘッダー](http://demo3.ec-cube.net/products/detail/33)

Markup:
include /assets/tmpl/elements/11.2.header.pug
+ec-headerRole
p hoge

Styleguide 11.5
*/
.ec-drawerRole{
  display: block;
  background: #F6F6F6;
  position: fixed;
  top:0;
  width: 260px;
  height:100vh;
  right:0;
  z-index: -1;
  transition: z-index 0ms 1ms;
  @include media_desktop(){
    display: none;
  }
}

.ec-drawerRole.is_active{
  z-index: 1;
  transition: z-index 0ms 300ms;
  @include media_desktop(){
    display: none;
  }
}

/*
ドロワー内における検索ボックスの表示


Markup:
include /assets/tmpl/elements/11.2.header.pug
.ec-drawerRole
p hoge

Styleguide 11.5.1
*/
.ec-drawerRole{
  & .ec-headerSearch{
    padding: 16px 8px 26px;
    background: #EBEBEB;
    color: #636378;
    select{
      width: 100% !important;
    }
  }
}
.ec-drawerRole{
  .ec-headerNav{
    .ec-headerNav__item{
      text-align: left;
      display: block;
      padding-left: 10px;
      height: 60px;
      border-bottom: 1px solid #e8e8e8;
      line-height: 50px;
      font-size: 30px;
      @include media_desktop {
        padding:0 10px;
        height: 50px;
      }
    }

  }
}

.ec-drawerRole{
  .ec-itemNav{
    .ec-itemNav__item{
      display: block;
      text-align: left;
      border-bottom: 1px solid #e8e8e8;
      line-height: 50px;
      font-weight: bold;
      a {
        padding:0 10px;
        height: 50px;
      }
    }
    .ec-itemNavAccordion {
      display: none;
    }
  }
}
