@import "../mixins/projects";

/*
注文履歴詳細

マイページ 注文履歴詳細部に関する Project コンポーネントを定義します。

ex [マイページ　注文詳細](http://demo3.ec-cube.net/mypage)
(要ログイン → 詳細を見るボタン押下)

Markup:
include /assets/tmpl/elements/16.2.historyDetail.pug
+ec-historyDetailRole

Styleguide 16.2
*/


/*
注文履歴詳細 メール履歴

マイページ 注文履歴詳細部に関する Project コンポーネントを定義します。

ex [マイページ　メール履歴](http://demo3.ec-cube.net/mypage)
(要ログイン → 詳細を見るボタン押下)

Markup:
include /assets/tmpl/elements/16.2.historyDetail.pug
+ec-historyDetailMail

Styleguide 16.2.5
*/
.ec-orderMails{
  & &__item{
    padding-bottom: 10px;
    @include borderBottom();
  }
  & &__time{
    margin: 0;
  }
  & &__body{
    display: none;
  }
  & &__close{
    display: none;
  }
  & &__item.is-active{
    & &__body{
      display: block;
    }
    &__close{
      display: block;
    }
  }
}




/*
注文履歴詳細 メール履歴個別

マイページ 注文履歴詳細部に関する Project コンポーネントを定義します。

ex [マイページ　メール履歴個別](http://demo3.ec-cube.net/mypage)
(要ログイン → 詳細を見るボタン押下)

Markup:
include /assets/tmpl/elements/16.2.historyDetail.pug
+ec-historyDetailMailHistory

Styleguide 16.2.6
*/
.ec-orderMail{
  padding-bottom: 10px;
  @include borderBottom();
  & &__time{
    margin: 0;
  }
  & &__body{
    display: none;
  }
  & &__close{
    display: none;
  }
  &.is-active &__body{
    display: block;
  }
  &.is-active &__close{
    display: block;
  }
}



