@import "../mixins/media";
@import "../mixins/projects";
/*
ログイン

ログイン

sg-wrapper:
<div class="ec-role">
  <sg-wrapper-content/>
</div>

Styleguide 6.1
*/

/*
ログインフォーム

http://demo3.ec-cube.net/mypage

Markup:
include /assets/tmpl/elements/6.3.login.pug
+ec-login

Styleguide 6.1.1
*/
.ec-login{
  margin: 0 0 20px;
  padding: 20px 13%;
  height: auto;
  background: #F3F4F4;
  box-sizing: border-box;
  @include media_desktop {
    margin: 0 16px;
    height: 430px;
  }
  & &__icon{
    font-size: 90px;
    text-align: center;
  }
  & &__input {
    margin-bottom: 40px;
    .ec-checkbox {
      span {
        margin-left: 5px;
        font-weight:normal;
      }
    }
  }
  & &__actions {
    color: #fff;
    @include reset_link();
  }
  & &__link {
    margin-top: 5px;
    margin-left: 0;
    @include media_desktop {
      margin-left: 20px;
    }
  }
  & &__error {
    display: none;
    color: $clrRed;
  }
}
.ec-login.is_active {
  @include media_desktop {
    height: 500px;
  }
}
.is_active {
  .ec-login__error{
    display: block;
  }
}

/*
ゲスト購入

http://demo3.ec-cube.net/mypage

Markup:
include /assets/tmpl/elements/6.3.login.pug
+ec-guest
hoge

Styleguide 6.1.2
*/
.ec-guest{
  display: table;
  margin: 0;
  padding: 13%;
  height: auto;
  box-sizing: border-box;
  background: #F3F4F4;

  @include media_desktop {
    height: 430px;
    margin: 0 16px;
  }
  & &__inner{
    display: table-cell;
    vertical-align: middle;
    text-align: center;
    p {
      margin-bottom: 16px;
    }
  }
  & &__actions {
    display: block;
    vertical-align: middle;
    text-align: center;
    color: #fff;
    @include reset_link();
  }
  & &__icon{
    font-size: 70px;
    text-align: center;
  }
}
