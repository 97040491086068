@import "../mixins/variables";
@import "../mixins/forms";
@import "../mixins/media";
/*
フォーム部品(テキスト)

テキスト入力項目に関する要素を定義します。

sg-wrapper:
<div class="ec-role">
  <sg-wrapper-content/>


Styleguide 3.1
*/



/*
フォーム

`.ec-input` 要素は全ての入力項目に関する標準的なコンポーネントクラスです。


ex [会員情報編集画面　フォーム部分](http://demo3.ec-cube.net/mypage/change)

Markup:
p.ec-input
  input(type="number")
p.ec-input
  textarea(rows="6")

Styleguide 3.1.1
*/
.ec-input{
  @include forms-reset;
  @include form-controls;
  input{
    margin-bottom: 16px;
    height: 40px;
    font-family: Roboto, "游ゴシック", YuGothic, "Yu Gothic", "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", Arial, "メイリオ", Meiryo, sans-serif;
  }
  p {
    line-height: 1.4;
  }
  .errorMail {
    display: none;
  }
  .errorPassword {
    display: none;
  }
  .errorPassword2 {
    display: none;
  }
}
.error.ec-input {
  input,select{
    margin-bottom: 5px;
    border-color: #CF3F34;
    background: #FDF1F0;
  }
  @mixin  errortext {
    display: block;
    margin-bottom: 25px;
    font-size: 12px;
    font-weight: bold;
    color: $clrRed;
  }
  .errorMail {
    @include errortext;
  }
  .errorPassword {
    @include errortext;
  }
  .errorPassword2 {
    @include errortext;
  }
}

/*
フォーム（text２つ）

姓名など2つ入力させたい入力項目で使用します。

入力フォームを半分で用意したいときにも利用可能です。

ex [会員情報編集画面　フォーム部分](http://demo3.ec-cube.net/mypage/change)

Markup:
p.ec-halfInput
  input(type="text")
  input(type="text")
p.ec-halfInput
  input(type="text")

Styleguide 3.1.2
*/
.ec-halfInput{
  @extend .ec-input;
  input[type='text']{
    display: inline-block;
    width: 49%;
    margin-left: 0;
    @include media_desktop {
      margin-left: 15px;
      width: 45%;
    }
  }
}

/*
数量ボタン

数量を表示するための小さなコンポーネントです。

数値表示に最適化するため、数字は右端揃えで表示されます。

ex [商品詳細画面　数量ボタン部分](http://demo3.ec-cube.net/products/detail/27)

Markup:
.ec-numberInput
  span 数量
  input(type="number",value="0")

Styleguide 3.1.3
*/
.ec-numberInput{
  @extend .ec-input;
  input[type='number']{
    display: inline-block;
    width: auto;
    max-width: 100px;
    text-align: right;
  }
}
/*
郵便番号フォーム

数量を表示するための小さなコンポーネントです。

内部に input 要素を配置してコーディングします。

ex [会員情報編集画面　郵便番号部分](http://demo3.ec-cube.net/mypage/change)

Markup:
.ec-zipInput
  span 〒
  input(type="text")
  span -
  input(type="text")
.ec-zipInputHelp
  a(href="http://www.post.japanpost.jp/zipcode/" target="_blank")
    .ec-zipInputHelp__icon
      .ec-icon
        svg(viewbox='0 0 360 360')
          path(d='M303,116.1c0,9.563-1.377,18.506-4.126,26.828c-2.755,8.325-5.856,15.1-9.317,20.32   c-3.462,5.225-8.345,10.493-14.643,15.804c-6.301,5.313-11.404,9.163-15.306,11.555c-3.906,2.392-9.317,5.537-16.238,9.431   c-7.279,4.075-13.355,9.828-18.235,17.266c-4.883,7.438-7.32,13.373-7.32,17.798c0,3.013-1.065,5.889-3.195,8.632   c-2.13,2.748-4.616,4.116-7.453,4.116h-63.889c-2.663,0-4.925-1.636-6.788-4.913c-1.864-3.274-2.795-6.595-2.795-9.961v-11.953   c0-14.697,5.765-28.556,17.302-41.571c11.535-13.015,24.225-22.621,38.068-28.82c10.47-4.781,17.923-9.737,22.361-14.875   c4.435-5.134,6.654-11.862,6.654-20.188c0-7.438-4.125-13.987-12.378-19.656c-8.252-5.666-17.793-8.5-28.617-8.5   c-11.538,0-21.121,2.569-28.75,7.703c-6.214,4.428-15.707,14.609-28.484,30.547c-2.309,2.834-5.057,4.25-8.251,4.25   c-2.131,0-4.352-0.706-6.656-2.125L61.286,94.584c-2.309-1.769-3.686-3.984-4.127-6.641c-0.444-2.656,0.042-5.134,1.465-7.438   C87.016,33.403,128.191,9.85,182.143,9.85c14.196,0,28.482,2.748,42.859,8.234c14.375,5.491,27.327,12.841,38.865,22.047   c11.534,9.209,20.939,20.499,28.218,33.867C299.361,87.37,303,101.403,303,116.1z M218.879,275.475v63.751   c0,2.833-1.064,5.311-3.194,7.438c-2.13,2.124-4.618,3.187-7.454,3.187h-63.889c-2.841,0-5.324-1.063-7.454-3.187   c-2.13-2.127-3.194-4.604-3.194-7.438v-63.751c0-2.832,1.064-5.313,3.194-7.438c2.13-2.126,4.613-3.187,7.454-3.187h63.889   c2.836,0,5.324,1.061,7.454,3.187C217.815,270.162,218.879,272.643,218.879,275.475z')
    span 郵便番号検索
.ec-zipAuto
  a.ec-inlineBtn 郵便番号から自動入力

Styleguide 3.1.4
*/
.ec-zipInput{
  @extend .ec-input;
  display: inline-block;
  input{
    display: inline-block;
    width: auto;
    text-align: right;
    max-width: 4em;
    font-size: 16px;
    @include media_desktop {
      max-width: 5em;
    }
  }
  span{
    display: inline-block;
    padding: 0 5px 0 3px;
    margin-left:5px;
  }
}
.ec-zipInputHelp {
  display: inline-block;
  margin-left: 10px;
  margin-bottom: 16px;
  vertical-align: baseline;
  line-height: 0;
  .ec-zipInputHelp__icon {
    display: inline-block;
    margin-top: -10px;
    width:20px;
    height:20px;
    background: #525263;
    border-radius: 50%;
    font-size: 13px;
    position: relative;
    top: -6px;
  svg{
      fill: #fff;
      position: relative;
      left: 3px;
      top: 2px;
    }
  }
  span {
    margin-left: 8px;
    display: inline-block;
  }
}
.ec-zipAuto {
  margin-bottom: 16px;
  .ec-inlineBtn {
    font-weight: normal;
  }
}
/*
電話番号ボタン

数量を表示するための小さなコンポーネントです。

内部に input 要素を配置してコーディングします。

ex [会員情報編集画面　電話番号部分](http://demo3.ec-cube.net/mypage/change)

Markup:
.ec-telInput
  input(type="text")
  span -
  input(type="text")
  span -
  input(type="text")

Styleguide 3.1.5
*/
.ec-telInput{
  @extend .ec-input;
  input{
    display: inline-block;
    width: auto;
    font-size: 16px;
    text-align: right;
    max-width: 5em
  }
  span{
    display: inline-block;
    padding: 0 5px 0 3px;
    margin-left:5px;
  }
}

