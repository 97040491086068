@import "../mixins/media";
@import "../mixins/animation";
@import "../mixins/projects";
/*
ヘッダー

ヘッダー用のプロジェクトコンポーネントを提供します。

ex [商品詳細ページ　ヘッダー](http://demo3.ec-cube.net/products/detail/33)

Markup:
include /assets/tmpl/elements/11.2.header.pug
+ec-headerRole

Styleguide 11.6
*/
.ec-overlayRole{
  position: absolute;
  width: 100%;
  height: 100vh;
  top: 77px;
  left: 0;
  opacity: 0;
  background: transparent;
  transform: translateX(0);
  transition: all .3s;
  @include fadeOut();
}
.have_curtain .ec-overlayRole {
  opacity: 1;
  background: rgba(0,0,0, 0.5);
  transform: translateX(-260px);
  transition: all .3s;
  @include fadeIn();
}

