@import "../mixins/media";
/*
フォームラベル

フォームのラベルに関する要素を定義します。

Styleguide 3.3
*/

/*
ラベル

フォーム要素で利用するラベル要素です。

ex [お問い合わせページ　ラベル部分](http://demo3.ec-cube.net/contact)

Markup:
.ec-borderedDefs
  dl
    dt
      label.ec-label お名前
    dd
      .ec-input
        input(type="text")

Styleguide 3.3.1
*/
.ec-label{
  display: block;
  padding: 5px 0 ;
  font-weight: bold;
  margin-bottom: 0;
  @include media_desktop {
    padding: 12px;
    margin-bottom: 5px;
  }
}

/*
必須ラベル

必須文字を表示するラベル要素です。

ex [お問い合わせページ　必須ラベル部分](http://demo3.ec-cube.net/contact)

Markup:
.ec-borderedDefs
  dl
    dt
      label.ec-label お名前
        span.ec-required 必須
    dd
      .ec-input
        input(type="text")

Styleguide 3.3.2
*/

.ec-required{
  color: #DE5D50;
  margin-left: 1em;
  font-size: 12px;
  font-weight: normal;
}