@import "../mixins/media";
@import "../mixins/projects";
@import "../mixins/clearfix";

/*
ヘッダー:カテゴリリスト

ヘッダー用のプロジェクトコンポーネントを提供します。

ex [商品詳細ページ　ヘッダー](http://demo3.ec-cube.net/products/detail/33)

Markup:
include /assets/tmpl/elements/11.2.header.pug
+ec-headerRole
p hoge

Styleguide 11.4
*/
.ec-cateoryNaviRole{
  @include container;
  display: none;
  @include media_desktop(){
    display: block;
    width: 100%;
    @include reset_link;
  }

}

/*
カテゴリナビ

カテゴリ一覧として使用します。

Markup:
include /assets/tmpl/elements/11.4.categoryNavi.pug
+ec-itemNav

sg-wrapper:
<div class="ec-headerRole">
  <div class="ec-headerRole__itemnav">
    <sg-wrapper-content/>
  </div>
</div>

Styleguide 11.4.1
*/
.ec-itemNav {
  display: block;
  border: 0;
  margin: 0;
  padding: 0;
  width: 100%;
  text-align: center;
  list-style: none;
  position: relative;
  @include media_desktop {
    margin: 20px 0 30px;
    border-bottom: 1px solid #D9D9D9;
    border-top: 1px solid #D9D9D9;
  }
  & &__item {
    display: inline-block;
    font-weight: bold;
    @include reset_link;
    &:hover {
      background: #fafafa;
    }
    a {
      display: inline-block;
      margin-top: 0;
      padding: 16px;
      height: 55px;
    }
    &:hover > .ec-itemNavAccordion {
      overflow: visible;
      height: 50px;
      transition:all .2s ease-out;
      .ec-itemNavAccordion__item {
        height: 50px;
        transition:all .2s ease-out;
      }
    }
  }
  .ec-itemNavAccordion {
    z-index: 1000;
    position: absolute;
    padding: 0;
    height: 0;
    overflow: hidden;
    transition:all .2s ease-out;
    .ec-itemNavAccordion__item {
      border-bottom: 1px solid #ccc;
      list-style: none;
      height: 0;
      transition:all .2s ease-out;
      a {
        list-style: none;
        border-top: 1px solid #E8E8E8;
        border-bottom: 1px solid #E8E8E8;
        margin-top: 0;
        padding: 15px 10px;
        min-width: 200px;
        width: 100%;
        text-align: left;
        background: #fff;
        transition:all .5s ease-out;
        &:hover {
          background: #fafafa;
        }
      }
    }
  }
}
